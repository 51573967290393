// @import url('https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700&display=swap');
// variables
$black1: #000000;
$black2: #303030;
$black3: #262B38;
$black4: #4d4d4d;
$black5: #434A5B;
$black6: #60687C;
$black7: #8E9EC4;
$black8: #474C58;
$black9: #858B99;
$white1: #FFFFFF;
$white2: #FAFAFA;
$white3: #F5F5F5;
$white4: #EDEDED;
$white5: #E0E0E0;
$white6: #DEE2E6;
$success: #06CB9E;
$success-light: #CBF2D5;
$danger: #F75E3F;
$orange: #FF6F00;
$warning: #FFD200;
$info: #039BE5;
$primary: #2D323E;
$primary2: #3C4253;
$gray1: #ECECEC;
$gray2: #EDEDED;
$gray3: #C1C1C1;
$gray4: #9B9898;
$gray5:#858B9A;
$gray6:#393F4E;
$muted: #383E4D;
$body_bg_color: #969AA7;
// variables


html {
   position: relative;
   min-height:100%;
   // height: 100%;
   scroll-behavior: smooth;
}

body {
   font-size: 14px;
   color: #A9A9A9;
   font-weight: 600;
   font-family: 'Muli',
      sans-serif;
   font-size: 14px;
   position: fixed;
   overflow: auto;
   height: 100%;
   width: 100%;
   left: 0px;
   top: 0px;
   height: 100%;
   background-color: #969AA7;
   // background-color: $white2;
}

.overflow-visible {
      overflow: visible !important;
}

p {
   font-size: 14px;
}

.comp-chart {
   min-width: 80px;
   width: 140px;
}

.no-shadow {
   box-shadow: none !important;
}

select.form-control {
   // -moz-appearance: none !important;
   /* Firefox */
   // -webkit-appearance: none !important;
   /* Safari and Chrome */
   // appearance: none !important;
   position: relative;

   &::after {
      font-family: 'Font Awesome 5 Pro';
      content: '\f0d9';
      position: absolute;
   }
}

.text-info {
   color: #039BE5 !important;
}

.text-light-50 {
   color: #E0E0E0 !important;
}

.text-secondary-50 {
   color: #858B99 !important;
}

.border-info {
   border-color: #039BE5 !important;
}


.border-info-1 {
   border: 2px solid #039BE5 !important;
}
.border-info-2{
   border: 4px solid $gray5 !important;
}
.border-info-3{
   border-color:#178CCC !important;
}
.border-light-green {
   border: 2px solid #CBF2D5 !important;
}

.bg-light-green {
   background-color: #CBF2D5 !important;
}

.bg-grey {
   background-color: #2E323F !important;
}

.border-purple-3 {
   border: 1px solid #A13AE3;
}
.border-purple-3-2px {
   border: 2px solid #A13AE3;
}

.box-shadow {
   background: #333642;
   border: 1px solid #A13AE3;
   box-sizing: border-box;
   box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 10px;
   display: grid;
   width: 56px;
   height: 56px;
}
.box-shadow-1 {
   width: 46px;
   height: 46px;
}
.box-shadow-border-blue{
   border: 1px solid #178CCC !important;
}
.text-blue-50{
   color:#178CCC;
}
.text-purple-3{
   color: #A13AE3 !important;
}
.text-light-green{
   color: #99CC33 !important;
}
.text-light-blue{
   color: #0090E9 !important;
}
.text-light-danger{
   color: #EC5812!important;
}
.text-light-blue-50{
   color:#B5E1F6 !important;
}
.box-shadow-border-green{
   border: 1px solid #24A597 !important;
}
.half-border {
   position: relative;

   &:after {
      content: '';
      height: 80%;
      position: absolute;
      width: 2px;
      background: #f7f7f7;
      top: 10%;
      right: 0px;
   }
}
   
.f24{
   font-size:24px !important;
}
.borderBottom{
   border-bottom: 4px solid #178ccc;
   position: relative;
   }

   .borderLeftStyle {
border: 2px solid #178ccc;
transform: rotate(34deg);
position: absolute;
width: 32px;
bottom: 4px;
left: -28px;
}
.borderRightStyle {
   border: 2px solid #178ccc;
   transform: rotate(144deg);
   position: absolute;
   width: 32px;
   bottom: 19px;
   bottom: 5px;
   right: -28px;
}
.select-bg{
   border: 10px solid transparent;
  border-image: url(../images/select.png) 20 round;
  height: 40px;
}
.select-bgg{
   border: 12px solid transparent;
  border-image: url(../images/bb.svg) 10 round;
  border-radius: 0px 0px 22px 0px;
}
.select-sm-bg {
      border: 8px solid transparent;
      border-image: url(../images/select.png) 20 round;
      height: 34px;
}
.border-bottom-dashed{
   border-bottom: 1px dashed #434a5b !important;
}
.form-number::placeholder{
   text-align: end;
   color:#fff !important;
}
.form-number{
   font-size: 10px;
}
.badge-purple-25{
      color: #fff;
      background-color: #6362A2;
  }
  .badge-purple-75{
   color: #fff;
   background-color: #9985D1;
}
.badge-blue{
   color: #fff;
   background-color: #406BAA;
}
.badge-green{
   color: #fff;
   background-color: #309593;
}
.bage-round{
   font-size: 12px;
   height: 19px;
   min-width: 19px;
   border-radius: 12px;
   padding: 0px 5px;
   line-height: 19px;
   font-weight: bold !important;
}
.bg-gradient-dark {
   background: $primary;
   background: -moz-linear-gradient(left, $primary 0%, $black3 100%);
   background: -webkit-linear-gradient(left, $primary 0%, $black3 100%);
   background: linear-gradient(to right, $primary 0%, $black3 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$primary', endColorstr='$black3', GradientType=1);
}

.bg0 {
      background-color: #C8CCD9 !important;
}

.after4,
.before0 {
      background-color: #D7DBE6 !important;
}

.after3,
.before1 {
      background-color: #EEEFF2 !important;
}

.after2,
.before2 {
      background-color: #E7E9F0 !important;
}

.after1,
.before3 {
   background-color: #E1E4EC !important;
}

.after0,
.before4 {
   background-color: #F3F3F3 !important;
}

.search-drop {
   position: relative;

   .search-suggestion {
      position: absolute;
      background-color: #FFF;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      width: 100%;
      top: 41px;
      z-index: 99;
   }
}

.line-survey {
   width: 3px;
    height: 10px;
    border-radius: 1px;
    margin-right: 12px;
    display: block;
}

.line-survey-1 {
   width: 5px;
   height: 100%;
   border-radius: 1px;
   margin-right: 2px;
   display: block;
}

.bg-primary-50 {
   background-color: #E6E3E3;
}

.bg-info-50 {
   background-color: #DEE6F0;
}

.bg-success-50 {
   background-color: #D7E8EA;
}

.text-primary-50 {
   color: #775BA2;
}

.text-primary-2 {
   color: #5F5BA2 !important;
} 

.text-purple-2 {
   color: #9A77D1 !important;
}

.text-success-2 {
   color: #24A597 !important;
}

.text-info-50 {
   color: #3F73AD;
}

.text-success-50 {
   color: #1E8FAD;
}

.bg-primary-2 {
   background-color: #775BA2 !important;
}

.bg-info-2 {
   background-color: #3F73AD !important;
}

.bg-success-3 {
   background-color: #1E8FAD !important;
}

.border-purple {
   border: 1px solid #693EBC !important;
}

.border-purple-1 {
   border: 2px solid #A13AE3 !important;
}

.border-purple-2 {
   border: 2px solid #9985D1 !important;
}

.border-gray {
   border-bottom: 1px solid #98999C !important;
}

.body_bg_color {
   background-color: $body_bg_color !important;
}

.bg-muted {
   background-color: $muted !important;
}

.border-2 {
   border: 2px solid #434a5b !important;
}

.searchDropText {
      position: relative;

      input {
            padding: 18px 5px !important;
            font-size: .85rem !important;
      }

      .dropTextbox {
            position: absolute;
            background-color: #FFF;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            width: 100%;
            top: 40px;
            z-index: 99;
      }

      .dropTextbox>div:hover {
            background-color: #DEEBFF
      }
}

small,
.small {
   font-size: 12px !important;
   font-weight: 600 !important;
}

.badge-purple {
   color: #fff;
   background-color: #693EBC;
}

.badge-info{
   color:#fff;
   background-color:$info
}

h2 {
   font-size: 36px !important;
}

h3 {
      font-size: 30px !important;
   }

h5 {
   font-size: 24px !important;
}

.font-weight-normal {
   font-weight: 300 !important;
}


.rounded {
   border-radius: 0.5rem !important;
}

.table-sm th,
.table-sm td {
   padding: 0.4rem 0.8rem;
   position: relative;
   vertical-align: middle;
}

.table {
   thead th {
      border-top: none;
      border-bottom: none;
   }
}

.table2 .table {
      thead th {
            // border-top: none;
            border-bottom: none;
            border: 1px solid $gray2;
            background-color: $gray3
      }

      td:first-child,
      th:first-child {
            position: sticky !important;
            left: 0;
            // z-index:1;
      }

      th:nth-child(2),
      td:nth-child(2) {
         position: sticky !important;
         left: 200px !important;
         // z-index:1;
      }

      th:nth-child(1) {
            min-width: 200px
      }

      th:nth-child(2) {
            min-width: 75px
      }

      td {
            border: 1px solid $gray2;
      }

      td:nth-child(1) {
            background-color: $white6;
            min-width: 200px
      }

      td:nth-child(2) {
            background-color: $white6;
            min-width: 75px
      }
}

code {
   color: #2D323E;
   font-weight: 600;
}

.dropdown-menu {
   border: none;
   box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.20);

   .fa {
      position: absolute;
      right: 10px;
      top: -13px;
      font-size: 22px;
      color: #FFF;
   }
}

.login-bx {
   width: 430px;
}

.form-group label {
   font-size: 12px;
   margin-bottom: 5px;
   line-height: 1.3;
   font-weight: 600;
}

.form-check label {
   font-size: 12px;
   line-height: 1.3;
   font-weight: 900;
}

.btn-group {
   .bg-light {
      background-color: #E0E0E0 !important;
   }

   .text-secondary {
      color: #858B99 !important;
   }
}

.btn-light {
   background-color: #DEE2E6;
}

.form-check-inline {
   align-items: flex-start;

   .form-check-input {
      margin-top: 2px;
   }
}

.list-group-item {
   border-top: none;
   border-left: none;
   border-right: none;
}

.popover-body {
   padding-top: 0px;
}

.btn {
   background-position: center;
   transition: background 0.8s;
}

.btn-dark:hover {
   background: $black2 radial-gradient(circle, transparent 1%, $black3 1%) center/15000%;
}

.btn:focus {
   outline: none;
   box-shadow: none;
}

.icon-tile {
   height: 36px;
   width: 36px;
   font-size: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.border-right-1 {
   border-right: 2px solid#039BE5 !important;
}

.table-hover tbody tr:hover {
   background-color: $white3 !important;
}

.icon-danger {
   background-color: $primary !important;
   color: $black9 !important;
}

.icon-success {
   background-color: $primary !important;
   color: $black9 !important;
}

h1,
h2,
h3,
h4,
h5,
h6, 
h7 {
   font-weight: 600 !important;
}

h4,
.h4 {
   font-size: 36px !important;
}

.f8 {
   font-size: 8px !important;
}

.f10 {
   font-size: 10px !important;
}

.bootstrap-bg-danger {
      background-color: #F44336 !important;
}
.bg-dark-75{
   background-color: #21242D !important;
}
.bg-dark-25{
   background-color: #2B2F39 !important;
}
.btn:active {
   outline: none;
   box-shadow: none;
   background-size: 100%;
   transition: background 0s;
}

.login {
      min-width: 70%;
}


.wrapper {
   display: flex;
   flex-direction: row;
   height: 100%;
   position: relative;
}

.hippa-chart {
   min-width: 60px;
   max-width: 61px;

   img {
      width: 100%;
   }
}

.hippa-box {
   flex: none;
   color: $black1 !important;

   h2 {
      color: $black1 !important;
   }

   &:hover {
      text-decoration: none;
   }

   .progress {
      min-width: 50px;
      background-color: #E0E0E0;
   }
}

h6,
.h6 {
   font-size: 18px;
   font-weight: 400;
}

h7,
.h7 {
   margin-bottom:0.5rem;
   font-weight:500;
   line-height:1.2
}
h7,.h7{font-size: 1rem}

.navigation,
.nav-collapsed:hover {
   min-height: 100%;
   height: 100%;
   transition: 0.35s width;
   position: relative;
   z-index: 99999999;

   .nav-content {
      overflow: hidden;
      width: 250px;
      height: 100%;
      background-color: $black3;
      display: flex;
      flex-direction: column;

      .nav-header {
         p {
            opacity: 1;
            transition: 0.35s opacity;
         }
      }

      .nav-text {
         opacity: 1;
         transition: 0.35s opacity;
      }

      .nav-header {
         background-color: $primary2;
         padding: 15px 15px 40px 15px;
      }

      .user-caption {
         width: 40px;
         height: 40px;
         color: $white1;
         background-color: $orange;
         border-radius: 50%;
         text-align: center;
         font-size: 22px;
         line-height: 40px;
         margin: 0 auto;
      }

      .main-nav {
         padding: 0.5rem 0rem;
         flex: 0 1 auto;
         overflow-y: auto;
         overflow-x: hidden;

         a {
            color: $white1;
            cursor: pointer;
            padding: 0.7rem 1.5rem;
            font-size: 14px;
            // display: flex;
            align-items: center;

            &:hover {
               color: $white5;
               background-color: $black4;
            }
         }

         .dropdown-toggle::after {
            display: none;
            margin-left: auto;
            opacity: 1;
            transition: 0.35s opacity;
         }

         .dropdown-menu {
            position: relative !important;
            left: 0px !important;
            transform: translate3d(0px, 0px, 0px) !important;
            border: none !important;
            width: 100% !important;
            border-radius: 0px !important;
            background-color: transparent;
            top: 0px;
         }
      }
      .dropdown-item:hover,.dropdown-item:focus {
         background-color: $black4;
      }
   }

}

.minw-score {
   min-width: 173px;
}

.collapse-nav {
   text-align: right;

   .open-nav {
      color: $white1;
      font-size: 22px;
      cursor: pointer;
   }
}

.nav-collapsed {
   min-width: 64px;
   max-width: 64px;
   transition: 0.35s width;

   .nav-content {
      transition: 0.35s width;
      width: inherit;

      .nav-text {
         opacity: 0;
         transition: 0.35s opacity;
      }

      .main-nav .dropdown-toggle::after {
         opacity: 0;
         transition: 0.35s opacity;
      }

      .nav-header {
         p {
            opacity: 0;
            transition: 0.35s opacity;
         }
      }
   }

}

.dropleft {
   .dropdown-menu {
         margin-right: 0.6rem;

      .fa {
         transform: rotate(90deg);
         right: -10px;
         top: 2px;
      }

      .dropdown-item {
         height: 28px;
      }
      
      .dropdown-menu {
         padding: 0.1rem 0;
      }
   }
}

.nav-collapsed:hover {
   .nav-content {
      width: 250px;
      transition: 0.35s width;
      position: absolute;
   }
}

.top-header {
   // background-color: $white4;
   background: $primary;
   background: -moz-linear-gradient(left, $primary 0%, $primary2 100%);
   background: -webkit-linear-gradient(left, $primary 0%, $primary2 100%);
   background: linear-gradient(to right, $primary 0%, $primary2 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$primary', endColorstr='$primary2', GradientType=1);
   color: #fFF;
}

.main-content {
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   overflow-x: auto !important;
}

.bashboard-bg {
   background-color: #969aa7;
   padding: 10px;
}

.img-circle {
   width: 34px;
   height: 34px;
   overflow: hidden;
   border-radius: 50%;

   img {
      width: 100%;
   }
}

.status-gray {
   background-color: #DFE8E5;
   padding: 5px 10px;
   border-radius: 25px;
   display: flex;
   align-self: center;

   p {
      font-size: 12px;
      align-self: center;
   }

   &::before {
      content: ' ';
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: #6A2AB2;
      display: block;
      align-self: center;
      margin-right: 4px;
   }
}

.status-pass {
      background-color: #DFE8E5;
      padding: 5px 10px;
      border-radius: 25px;
      display: flex;
      align-self: center;
   
      p {
         font-size: 12px;
         align-self: center;
      }
   
      &::before {
         content: ' ';
         height: 12px;
         width: 12px;
         border-radius: 50%;
         background-color: #7BB9BD;
         display: block;
         align-self: center;
         margin-right: 4px;
      }
   }

   .status-fail {
      background-color: #DFE8E5;
      padding: 5px 10px;
      border-radius: 25px;
      display: flex;
      align-self: center;
   
      p {
         font-size: 12px;
         align-self: center;
      }
   
      &::before {
         content: ' ';
         height: 12px;
         width: 12px;
         border-radius: 50%;
         background-color: #EC8AA0;
         display: block;
         align-self: center;
         margin-right: 4px;
      }
   }

.custom-margin {
   margin-top: -3rem;
   margin-left: 1rem;
   margin-right: 1rem;
   margin-bottom: 1rem;
}

.title-section {
   background: $primary;
   background: -moz-linear-gradient(left, $primary 0%, $primary2 100%);
   background: -webkit-linear-gradient(left, $primary 0%, $primary2 100%);
   background: linear-gradient(to right, $primary 0%, $primary2 100%);
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$primary', endColorstr='$primary2', GradientType=1);
   padding: 1rem 1rem 4rem 1rem;
}

.title-section-new {
   background: $primary;
   // background: -moz-linear-gradient(left, $primary 0%, $primary2 100%);
   // background: -webkit-linear-gradient(left, $primary 0%, $primary2 100%);
   // background: linear-gradient(to right, $primary 0%, $primary2 100%);
   // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$primary', endColorstr='$primary2', GradientType=1);
   padding: 1rem 1rem 4rem 1rem;
}

.storage-input {
   width: 120px !important;
}

.form-group.styled-feild {
   background-color: $black5;
   color: $white1;
   padding: 5px;
   border-radius: 4px;
   text-align: left;
   
   label {
      margin-bottom: 0px;
   }

   .form-control {
      background-color: transparent;
      border: 0px;
      color: $white1;
      height: 28px;
      line-height: 18px;
      padding-left: 0px;
      font-size: 12px;
      // margin-top: -8px;
   }
}

.fix-chart {
      min-width: 60%;
      max-width: 60%;
      max-height: 30px;
}
   
.bg-dark-50 {
      background-color: #272C3A !important;
}

.square-btn {
      background-color: #2d323e;
      color: $white2;
      height: 100%;
      padding: 6px 5px;
      border-radius: 4px;
}
   
.input-group.dark-feild {
   background-color: $black5;
   color: $white1;
   border: 0px;

   .input-group-text {
      border: 0px;
      color: $white1;
   }

   .form-control {
      background-color: $black5;
      color: $white1;
      border: 0px;
   }
}

.box {
   background-color: $white1;
   border-radius: 8px;
   overflow: hidden;
   box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.4);
   position: relative;
}


.detail {
   height: 100%;
   display: flex;
   flex-direction: column;

   .detail-title {
      background-color: $primary;
      padding: 15px;
   }

   .risk-status {
      min-width: 57px;
      height: 57px;
      border-radius: 50%;
      overflow: hidden;
      font-size: 12px;
      line-height: 57px;
      text-align: center;
   }

   .nav-link {
      font-size: 12px;
      padding: 0.5rem 0.5rem;
   }

   .detail-content {
      padding: 0px;
      background-color: $white1;
      flex-grow: 1;
      overflow: hidden;
      position: relative;
      color: #6c757d;
      font-weight: 800;

      table {
         font-size: 12px;
      }

      .pop-slider {
         position: absolute;
         right: 0%;
         top: 0px;
         background-color: #FFFFFF;
         height: 100%;
         width: 100%;
         z-index: 99;
         display: flex;
         flex-direction: column;

         .card {
            margin: 15px 0px 0px 0px;
         }

         .card:hover .trash-card {
            opacity: 1;
            transition: 0.35s all;
         }

         .trash-card {
            position: absolute;
            right: 15px;
            transition: 0.35s all;
            opacity: 0;
         }

         .list-group-item {
            padding: 7px;
            font-size: 12px;
         }
      }
   }
}

.progress {
   height: 0.5rem;
}

.count {
   color: #FFF;
   border-left: 3px solid #DDD;
   padding-left: 15px;
   align-self: center;
}

.border-left-2 {
   border-left: 2px solid transparent;
}

.title-section {
   .progress {
      width: 100px;
   }
}

.nav-tabs {
   background-color: $white3;
   box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
   margin-bottom: 5px;
   text-align: center;

   .nav-link {
      color: $black3;
      border-width: 3px;
   }

   .nav-link:focus {
      border-color: transparent;
   }

   .nav-link:hover {
      border-color: transparent;
   }

   .nav-link.active {
      border-color: transparent transparent $black2;
      color: $black2;
      background-color: transparent;
   }

   .badge-secondary {
      color: $black2;
      border: 1px solid $black2;
      border-radius: 25px;
      background-color: transparent;
   }
}

.tags {
   list-style: none;
   margin: 0;
   overflow: hidden;
   padding: 0;
}

.tags li {
   float: left;
}

.tag {
	background: $black2 !important;
   border: 1px solid $black2 !important;
	border-radius: 3px 0 0 3px;
	color: #999;
	display: inline-block;
	height: 21px;
	line-height: 21px;
	padding: 0 13px 0 15px !important;
	position: relative;
	margin: 0 4px 4px 0;
	text-decoration: none;
	-webkit-transition: color 0.2s;
   font-size: 11px;
}

.tag::before {
   background: #fff;
   border-radius: 10px;
   box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
   content: '';
   height: 6px;
   left: 4px;
   position: absolute;
   width: 6px;
   top: 7px;
}

.tag::after {
	background: $black2;
	border-bottom: 10px solid transparent;
	border-left: 10px solid $black2;
	border-top: 10px solid transparent;
	content: '';
	position: absolute;
	right: 0;
	top: 0;
}

.tag:hover {
	background-color: $black1 !important;
	text-decoration: none;
	color: white;
}

.tag:hover::after {
	border-left-color: $black1 !important;
}

.tagDark {
	background: $black5 !important;
   border: 1px solid $black5 !important;
	border-radius: 3px 0 0 3px;
	color: #999;
	display: inline-block;
	height: 21px;
	line-height: 21px;
	padding: 0 13px 0 15px !important;
	position: relative;
	margin: 0 4px 4px 0;
	text-decoration: none;
	-webkit-transition: color 0.2s;
   font-size: 11px;
}

.tagDark::before {
   background: #fff;
   border-radius: 10px;
   box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
   content: '';
   height: 6px;
   left: 4px;
   position: absolute;
   width: 6px;
   top: 7px;
}

.tagDark::after {
	background: $black5;
	border-bottom: 10px solid transparent;
	border-left: 10px solid $black5;
	border-top: 10px solid transparent;
	content: '';
	position: absolute;
	right: 0;
	top: 0;
}

.tagDark:hover {
	background-color: $black1 !important;
	text-decoration: none;
	color: white;
}

.tagDark:hover::after {
	border-left-color: $black1 !important;
}

.sign-bubbles {
   position: relative;
   overflow: hidden;

   &:after {
      content: ' ';
      width: 500px;
      height: 500px;
      border-radius: 50%;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 0.1);
      position: absolute;
      bottom: -18%;
      left: -25%;
   }

   &:before {
      content: ' ';
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 0.1);
      position: absolute;
      right: -7%;
      top: -3%;
   }
}

.nav-pills {
   a {
      color: #60687C;
   }

   .nav-link.active {
      background-color: #F5F5F5;
      color: #60687C;
   }
}


.f8 {
   font-size: 8px !important;
}

.f9 {
   font-size: 9px !important;
}

.f10 {
   font-size: 10px !important;
}

.f11 {
   font-size: 11px !important;
}

.f12 {
   font-size: 12px !important;
}

.f13 {
   font-size: 13px !important;
}

.f14 {
   font-size: 14px !important;
}

.f15 {
   font-size: 15px !important;
}

.f16 {
   font-size: 16px !important;
}

.f18 {
   font-size: 18px !important;
}

.f20 {
   font-size: 20px !important;
}

.f22 {
   font-size: 22px !important;
}

.f25 {
   font-size: 25px !important;
}

.f30 {
   font-size: 30px !important;
   line-height: 30px;
}

.f32 {
   font-size: 32px !important;
}

.f35 {
   font-size: 35px !important;
}

.f28 {
   font-size: 28px !important;
}

.f38 {
   font-size: 38px !important;
}

.f42 {
   font-size: 42px !important;
}

.alert-success {
   color: #09D261;
   background-color: #ECF5EE;
   border-color: #ECF5EE;
}

.alert-secondary {
   color: #60687C;
   background-color: rgba(169, 169, 169, 0.16);
   border-color: rgba(169, 169, 169, 0.16);
}

.badge-secondary-50 {
   color: #fff;
   background-color: $black6;
}

// .text-info {
//    color: #1FCFE7 !important;
// }

.text-danger {
   color: $danger !important;
}

.text-blue {
   color: #039BE5;
}

.text-success {
   color: $success !important;
}

.text-purple {
   color: #693EBC;
}

.text-primary {
   color: #693EBC !important;
}

.text-gray3 {
   color: $gray3 !important;
}

.text-gray4 {
   color: $gray4 !important;
}

.textInfo {
   color: #039BE5 !important;
}

.text-white {
   color: $white1 !important;
}

.text-black {
   color: $black1 !important;
}

.text-black-50 {
   color: $black6 !important;
}

.bg-danger {
   background-color: $danger !important;
}

.bg-yellow-light {
   background-color: #FCF3E6 !important;
}

.bg-purple {
   background-color: #5336A3 !important;
}

.bg-purple-2 {
   background-color: #37246E !important;
}

.bg-purple {
   background-color: #5336A3 !important;
}

.bg-purple-3 {
   background-color: #A13AE3 !important;
}

.bg-purple-4 {
   background-color: #9985D1 !important;
}

.bg-success {
   background-color: $success !important;
}

.bg-success-2 {
   background-color: #08B089 !important;
}

.bg-primary {
   background-color: $primary !important;
   color: $white1;
}

.bg-orange {
   background-color: $orange !important;
   color: $white1;
}

.bg-active {
   background-color: $white3 !important;
}

.bg-light {
   background-color: $white3 !important;
}

.bg-light-50 {
   background-color: #FBFBFB !important;
}

.bg-secondary {
   background-color: $black8 !important;
}

.bg-secondary-50 {
   background-color: #DEE2E6 !important;
}

.bg-white1 {
   background-color: $white1 !important;
}

.bg-white3 {
   background-color: $white3 !important;
}

.bg-white4 {
   background-color: $white4 !important;
}

.bg-gray3 {
   background-color: $white6 !important;
}

.bg-gray1 {
   background-color: $gray1 !important;
}

.bg-gray5 {
   background-color: $gray5 !important;
}

.bg-dark2 {
   background-color: $black6 !important;
}

.bg-dark3 {
   background-color: $black3 !important;
}

.bg-info {
   background-color: #039BE5 !important;
}

.bgInfo {
   background-color: $info;
}

.table-xs {
   font-size: 10px;

   td,
   th {
      padding: 4px 6px;
   }
}

select.custom-dropdown {
   border: none;
   display: table;
   //width: auto;
   background-color: #E5E4E5 !important;
   padding: 2px 2px;
   height: 26px;
   font-size: 12px;
}

.rectangle-green {
   width: 15px;
   height: 12px;
   background: #09D261;
}
.rectangle-danger{
   width: 12px;
   height: 12px;
}
.text-green{
   color:#09D261 !important;
}
.text-danger-50{
   color: #F44336 !important;
}

select.customDropdown {
      border: none;
      display: table;
      //width: auto;
      min-width: 75px;
      background-color: #E5E4E5 !important;
      padding: 2px 2px;
      height: 26px;
      font-size: 12px;
      border-radius: 4px;
      font-weight: bold;
      color: #4d4848;
}

.custom-badge {
      border-radius: 4px !important;
}

.CircularProgressbar {
   .CircularProgressbar-text {
      // fill: #2F2A40 !important;
      font-size: 25px !important;
   }
}
   
.border {
   border: 1px solid $black5 !important;
}

.border-left {
   border-left: 1px solid $black5 !important;
}

.border-right {
   border-right: 1px solid $black5 !important;
}

.timeline-divider {
   height: 3px;
   width: 30px;
   background-color: $black5;
   margin: 10px 0px;
}

.border-bottom {
   border-bottom: 1px solid $black5 !important;
}

.dropdown-divider {
   height: 0;
   margin: 0.8rem 0;
   overflow: hidden;
   border-top: 1px solid $black5;
}

.border-success {
   border-color: $success !important;
}

.table th,
.table td {
   border-top: none;
}

button:focus {
   outline: none;
}

.brush-timeline {
   border-radius: 4px;

   .border-timeline {
      border-right: 6px solid #4B546A;
      border-left: 6px solid #4B546A;
   }
}
   
.btn-child {
      button {
         background-color: transparent !important;
         border: none !important;
         padding: 0px 0px 5px 0px !important;
         box-shadow: none !important;
         font-size: 10px !important;
         height: 24px;
      }

      div {
         div {
            right: 0px !important;
         }  
      }
}

.dropdown-menu {
   box-shadow: none;
}

.icon-holder {
   width: 35px;
   min-height: 100%;
   background-color: #242935;
}

.ml-icon {
   margin-left: -43px;
}

.risk-line {
   width: 3px;
   display: table;
   float: left;
   position: absolute;
   height: 60%;
   top: 20%;
   left: 3px;
}

.badge-outline-purple {
      background-color: #693EBC;
      border: 1px solid #693EBC;
      color: #fff;
      padding: 5px 14px;
}

.badge-purple-50 {
   color: #fff;
   background-color: #9985D1;
}

.table th,
.table td {
   vertical-align: middle;
}

.badge-outline-primary {
   background-color: transparent;
   border: 1px solid $black3;
   color: $primary;
}

.badge-outline-secondary {
   background-color: transparent;
   border: 1px solid $primary2;
   color: $primary2;
}

.badge-outline-success {
   background-color: transparent;
   border: 1px solid $success !important;
   color: $success !important;
}

.badge-outline-success-white {
      background-color: transparent;
      border: 1px solid $success !important;
      color: $white1 !important;
      padding: 7px 15px !important;
}
   
.badge-outline-success-selected {
      background-color: $success;
      border: 1px solid $success !important;
      color: #FFFFFF !important;
}

.badge-outline-danger {
      background-color: transparent;
      border: 1px solid $danger !important;
      color: $danger !important;
}

.badge-outline-danger-white {
      background-color: transparent;
      border: 1px solid $danger !important;
      color: $white1 !important;
      padding: 7px 15px !important;
}

.badge-outline-danger-selected {
      background-color: $danger;
      border: 1px solid $danger !important;
      color: #FFFFFF !important;
}

.badge-outline-warning {
   background-color: transparent;
   border: 1px solid $warning;
   color: $warning;
}

.badge-outline-info {
   background-color: transparent;
   border: 1px solid $info;
   color: $info;
}

.badge-outline-light {
   background-color: transparent;
   border: 1px solid $white5;
   color: $white5;
}

.badge-outline-dark {
   background-color: transparent;
   border: 1px solid $black3;
   color: $black3;
}

.badge-outline-gray {
      background-color: transparent;
      border: 1px solid $gray3;
      color: $black3;
}

.bx-watermark {
   position: absolute;
   opacity: 0.06;
   display: flex;
}

.watermark-tl {
   top: 0px;
   left: 0px;
}

.watermark-tr {
   top: 0px;
   right: 0px;
}

.watermark-lc {
   top: 0px;
   left: 0px;
   bottom: 0px;
   align-items: center;
}

.red-tab.nav-tabs {
   background-color: transparent;
   box-shadow: none;

   .nav-link.active {
      border-bottom-color: $danger;
   }
}

.nav-link.active {
   border-bottom-color: $black7 !important;
}

.bordered-tile {
   display: flex;
   padding: 10px;
   margin-bottom: 5px;
   background-color: $white3;
   border-left: 6px solid $black4;
}

.advanced-search {
   background-color: rgba(0, 0, 0, 0.7);
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0px;
   right: 0px;
   //z-index: 9999;
   z-index: 9;
   display: flex;
   justify-content: flex-end;

   .search-content {
      background-color: #FFF;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .header-search {
         background: $primary;
         background: -moz-linear-gradient(left, $primary 0%, $black3 100%);
         background: -webkit-linear-gradient(left, $primary 0%, $black3 100%);
         background: linear-gradient(to right, $primary 0%, $black3 100%);
         filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$primary', endColorstr='$black3', GradientType=1);
         padding: 1rem;
         color: $white1;
         display: flex;
      }
   }
}

.search-content-bg {
   background-color: #EDEDED !important;
}
   
b,
strong {
   font-weight: 700;
}

.text-highlight {
   font-size: 20px !important;
   color: #858B99 !important;
   font-weight: 700;
}

.badge {
   border-radius: 2rem;
   padding: 5px 8px;
   font-weight: 700;
   // color: #2F2A40;
}

.bg-1 {
      background-color: #f7f7f7 !important;
      color: #ababab;
}

.bg-2 {
      background-color: #f7f7f7 !important;
      color: #DDD;
}

.bg-3 {
      background-color: #f7f7f7 !important;
      color: #DDD;
}

.bg-4 {
      background-color: #f7f7f7 !important;
      color: #DDD;
}
   
.badge-square {
      display: inline-block;
      padding: 0.25em 0.4em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      // line-height: 1.25; //prakash
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pill-tabs {
   .nav-tabs {
      background-color: transparent;
      box-shadow: none;
      border-bottom: none;
   }

   .nav-tabs {
      .nav-link {
         padding: 3px 12px;
         font-size: 12px !important;
         border: 1px solid #039BE5;
         border-radius: 50px;
         color: #039BE5;
         margin-right: 10px;
      }

      .nav-link.active {
         background-color: #039BE5;
         color: #FFF;
      }
   }
}
   
.stepper {
   display: flex;
   width: 100%;
   margin: 0px 15px;
   justify-content: space-between;
   font-size: 12px;
   overflow: hidden;
   position: relative;

   >div {
      margin: 0px 15px;
      white-space: nowrap;
      position: relative;
      z-index: 9;
   }

   &:before {
      content: '';
      height: 1px;
      width: 100%;
      background-color: $black5;
      position: absolute;
      top: 49%;
   }
   
}

.accordion>.card {
   border-radius: 8px;

   .card-body {
      padding-top: 0px;
   }
}

.input-group {
   .form-control {
      background-color: $black5;
      border: none;
   }
}

.input-group-text {
   background-color: $black5;
   color: $white1;
   border: none;
}

.accordion>.card:not(:first-of-type) {
   border-top-left-radius: 8px;
   border-top-right-radius: 8px;
}

.accordion>.card:not(:last-of-type) {
   border-bottom: 8px;
   border-bottom-right-radius: 8px;
   border-bottom-left-radius: 8px;
}   


   
*::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   background-color: #F5F5F5;
}

*::-webkit-scrollbar {
   width: 5px;
   height: 5px;
   background-color: #F5F5F5;
   border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
   background-color: #303030;
   border: 0px solid #555555;
}

.dropdown-box {
   li {
      border-bottom: 1px solid #EDEDED;
   }

   li:hover {
      background-color: #f7e8d2;
   }
}

.form-control:focus {
   -webkit-box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
   box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
   border-color: #ced4da;
}   

.legend-circle {
   height: 7px;
   width: 7px;
   border-radius: 50%;
   background-color: $white6;
   align-self: center;
}


.legend-square {
   height: 10px;
   width: 16px;
   background-color: $white6;
   align-self: center;
   margin-right: 5px;
}

.legend {
   display: flex;
}

.short-lineheight {
   line-height: 14px;
   font-size: 7px;
}

.stock-progress {
   height: 5px;
   display: block;
   width: 100%;
   display: flex;
   border-radius: 8px;
   overflow: hidden;
   
   .stock-1 {
      background-color: #26B68F;
      width: 20%;
   }

   .stock-2 {
      background-color: #2ED1BE;
      width: 20%;
   }

   .stock-3 {
      background-color: #67E1D6;
      width: 20%;
   }

   .stock-4 {
      background-color: #00B5D4;
      width: 20%;
   }

   .stock-5 {
      background-color: #EDEDED;
      width: 100%;
   }

}

.cursorPointer {
      cursor: pointer !important;
}

.cursorAuto {
   cursor: auto !important;
}

.lineHeight5 {
      line-height: 5px;
}

.z999 {
   z-index: 999 !important;
}

//prakash for drawin last border in side bar for each table
      
.popover {
      min-width: 300px !important;
      min-height: 150px !important;
      font-size: 13px;
      overflow-y: auto !important;
      //overflow-y: unset !important; //prakash
      white-space: pre-line;
      font-family: 'Muli',
      sans-serif;
}
   
.list-group .list-group-item:last-child {
   border-bottom: none;
}


.riskcout {
   min-width: 90px !important;
}

.popover-inner {
      min-height: 150px;
}

.w-8 {
      width: 8% !important;
}

.w-10 {
      width: 10% !important;
}

.w-12 {
      width: 10% !important;
}

.w-13 {
      width: 13% !important;
}

.w-15 {
      width: 15% !important;
}

.w-16 {
   width: 16% !important;
}

.w-17 {
   width: 17% !important;
}

.w-18 {
      width: 18% !important;
}

.w-20 {
      width: 20% !important;
}

.w-25 {
      width: 25% !important;
}

.w-30 {
      width: 30% !important;
}

.w-33 {
      width: 33% !important;
}

.w-35 {
      width: 35% !important;
}

.w-40 {
      width: 40% !important;
}

.w-43 {
      width: 43% !important;
}

.w-45 {
      width: 45% !important;
}

.w-52 {
      width: 52% !important;
}

.w-53 {
      width: 53% !important;
}

.w-55 {
      width: 55% !important;
}

.w-57 {
      width: 57% !important;
}

.w-58 {
      width: 58% !important;
}

.w-60 {
      width: 60% !important;
}

.w-65 {
      width: 65% !important;
}

.w-67 {
      width: 67% !important;
}

.w-70 {
      width: 70% !important;
}

.w-75 {
      width: 75% !important;
}

.w-80 {
      width: 80% !important;
}

.w-82 {
      width: 82% !important;
}

.w-85 {
      width: 85% !important;
}

.w-87 {
      width: 87% !important;
}

.w-90 {
      width: 90% !important;
}

.w-92 {
      width: 92% !important;
}

.maxWidth90 {
      max-width: 90%
}

.p3 {
      padding: 3px !important
}

.p9 {
      padding: 9px !important
}

.displayNone {
      display: none !important
}

.displayBlock {
      display: block !important
}


.criticalbgColor {
      background-color: #26B68F;
}

.highbgColor {
      background-color: #2ED1BE;
}

.mediumbgColor {
      background-color: #67E1D6;
}

.lowbgColor {
      background-color: #00B5D4;
}

.criticalTextColor {
      color: #26B68F;
}

.highTextColor {
      color: #2ED1BE;
}

.mediumTextColor {
      color: #67E1D6;
}

.lowTextColor {
      color: #00B5D4;
}

.text-decoration-none {
      text-decoration: none;
      color: #1FCFE7;
}

.text-decoration-none:hover {
      color: #039BE5;
}

.maxWidth700 {
      max-width: 700px !important;
}

.maxHeight300 {
   max-height: 300px !important;
}
   
.timeline-space {
   display: flex;
   margin-left: -6px;   
   margin-top: 35px; //prakash

   span {
      height: 11px;
      width: 11px;
      border: 1px solid #D6D6D6;
      border-radius: 50%;
      display: block;
      background-color: #EDEDED;
      position: relative;
      z-index: 9;
   }

   div {
      width: 30px;
      height: 1px;
      background-color: #D6D6D6;
      // align-self: center;
      //align-self: center; // parkash
      // margin-left: -4px;
      margin-top: 5px; //prakash
   }
}

.small-divder {
   width: 30px;
   height: 3px;
   background-color: #363C4B;
}
   
.borderLeft7 {
      border-left: 7px solid #878B96;
}

.borderCollapseSpace {
      border-collapse: separate;
      border-spacing: 0 10px;      
}

.borderCollapseSpace tr {
      background-color: #F5F5F5 !important;      
}

.borderCollapseSpace td {
      border-top: none !important;
      padding: 10px !important
}

select.filter-dropdown {
      border: 1px solid #ced4da;
      display: table;
      width: auto;
      background-color: #fff;
      padding: 2px 2px;
      height: 26px;
      font-size: 12px;
}

.cardBgColor1 {
      background-color: #765AA4 !important;
      //opacity: 0.4;
      color: #fff;
      border: none !important;
      
      //background-image: linear-gradient(#765AA4);
      background-image: linear-gradient(to bottom, #a18cd1 0, #fbc2eb 200%)
}

.cardBgColor2 {
      background-color: #5E5BA2 !important;
      color: #fff;
      border: none !important;
      background-image: linear-gradient(to bottom, #5E5BA2 0, #a6c0fe 200%);
}

.cardBgColor3 {
      background-color: #3F73AD !important;
      color: #fff;
      border: none !important;
      background-image: linear-gradient(to bottom, #3F73AD 0, #8ec5fc 200%) //background-image: linear-gradient(120deg,#3F73AD 0,#8ec5fc 100%)      
}

.cardBgColor4 {
      background-color: #208FAD !important;
      color: #fff;
      border: none !important;
      background-image: linear-gradient(to bottom, #208FAD 0, #79BBCD 200%);
      //background-image: linear-gradient(120deg,#208FAD 0,#79BBCD 100%);
}

.cardBgColor5 {
      background-color: #25A598 !important;
      color: #fff;
      border: none !important;
      background-image: linear-gradient(to bottom, #25A598 0, #A7DBD5 200%);
}
   
.colorBlack {

   .css-yk16xz-control,
   .css-1pahdxg-control {
      // background-color: #434A5B; //prakash
      background-color: $black5;
      border-color: transparent;
      min-height: 28px;
      max-height: 28px;
      border-width: 0px;
      box-shadow: none;
   }

   .css-1pahdxg-control:hover {
      border-color: transparent !important;
      box-shadow: none;
   }

   .css-g1d714-ValueContainer {
      padding: 0px;
      height: 28px;
   }

   .css-1uccc91-singleValue,
   .css-b8ldur-Input,
   .css-1hb7zxy-IndicatorsContainer {
      color: #FFF;
      height: 28px;
      top: 18px;
   }

   .css-b8ldur-Input {
      height: 28px;
   }

   .css-1okebmr-indicatorSeparator {
      display: none;
   }

   .css-yk16xz-control:hover {
      border-color: transparent;
   }
   
   .css-2613qy-menu {
      font-size: 12px;
   }
}
      
.cardBodyManul {
   padding: 10px !important;
}

.cardFooterManualCss {
   border-top: none !important;
   background-color: initial;
   padding: 10px !important;
   //background-color: inherit !important;
   //background-image: linear-gradient(to top,#fbc2eb 0,#a18cd1 100%);
}

.faIcon {
   opacity: .2;
   color: #000;
   position: absolute;
   top: -17px;
   right: -4px;
   font-size: 53px;
   height: auto;
}

.colorBlack {
   color: $black1 !important;
}

.borderRadius0 {
   border-radius: 0px !important;
   box-shadow: none;
}

.breakWord {
   word-wrap: break-word !important;
}

// .positionAbsolute {
//       position: absolute !important;
// }

.minHeight300 {
   min-height: 300px
}

.DayPicker {
   font-size: 12px !important;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
   background-color: #f0f8ff !important;
   color: #4a90e2;
}

.Selectable .DayPicker-Day {
   border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
   border-top-left-radius: 50% !important;
   border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
   border-top-right-radius: 50% !important;
   border-bottom-right-radius: 50% !important;
}

button.link {
   background-color: transparent;
   border: 0;
   //appearance: none;
   color: #4a90e2;
}

.link {
   color: #1FCFE7
}

.link:hover {
   color: #4a90e2;
}

.removeDropdownSearchBar .css-1jllj6i-control {
   display: none !important;
}

.marginLeftMinus5 {
   margin-left: -5px;
}

.marginRightMinus5 {
   margin-right: -5px;
}

.calendarSection {
   background-color: #E5E4E5;
   color: #000;
   padding: 3px 5px 4px 5px;
   border: none;
   font-size: 13px;
   font-weight: bold;
   border-radius: 4px;
}

.tagDropSection {
   border: none;
   background-color: #FBFAFB;
   width: 50px;
   color: #039BE5;
}

.groupBySection {
   font-size: 12px;
   font-weight: bold;
}

.resetBtn {
   font-size: 10px;
   color: #007bff;
   background-color: transparent;
   border: 0;
}

// .select {
//       margin: 0px !important;
// }

.select ul {
   padding: 0px !important;
   margin: 0px !important;
}

.select ul li.option {
   background-color: #DEDEDE;
   box-shadow: 0px 1px 0 #DEDEDE, 0px -1px 0 #DEDEDE;
   -webkit-box-shadow: 0px 1px 0 #DEDEDE, 0px -1px 0 #DEDEDE;
   -moz-box-shadow: 0px 1px 0 #DEDEDE, 0px -1px 0 #DEDEDE;
   padding: 0px !important;
   margin: 0px !important;
}

.select ul li.option:hover {
   background-color: #B8B8B8;
   padding: 0px !important;
   margin: 0px !important;
}

.select ul li.option {
   z-index: 1;
   padding: 5px;
   list-style: none;
}

.select ul.closed li.option {
   display: none;
   padding: 0px;
   margin: 0px;
}

.select ul.closed li:first-child {
   display: block;
}

.select ul li {
   cursor: default;
}        

.blackMuted {
      color: #4d4848 !important
}

.customDayPicker {
      border: 2px solid #E5E5E5 !important;
      border-radius: 5px;
      right: 10px;
}

.customDayPicker .DayPicker-wrapper {
      border: none !important;
}

.DayPicker-wrapper {
   border: 2px solid #E5E5E5 !important;
   border-radius: 5px;
}

.RangeExample {
   position: absolute;
   background-color: #fff;
   padding-top: 1px;
   z-index: 99;
}

.assetfreq {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   width: 120px;
   display: inline-block;
}

//Trend fitler section  start 
.custom-dropdown {
   font-size: 11px !important;
}


//content dropdown section
.custom-dropdown .css-11unzgr {
   max-height: 200px !important;
   //min-width:160px;
}

.custom-dropdown .css-1v99tuv {
   font-size: 14px !important;
   padding: 0.375rem 0rem;
}
   
//checkbox section
.css-1vr111p-option input[type="checkbox"],
.css-1qprcsu-option input[type="checkbox"] {
      vertical-align: middle !important;
}

.filterLabel {
   font-size: 12px !important;
   color: #515151;
}

.dropApplyBtn {
   font-size: 12px;
   color: #fff;
   font-weight: bold;
   border: 0;
   background-color: #007bff;
   border-radius: 4px;
   padding: 2px 8px 5px 8px;
   cursor: pointer;
}

.dropCancelBtn {
   font-size: 12px;
   color: #fff;
   font-weight: bold;
   border: 0;
   background-color: #A8A7A8;
   border-radius: 4px;
   padding: 2px 18px 5px 18px;
   cursor: pointer;
}

.includeDropDiv {
   z-index: 9999;
   margin-top: 1%;
   background-color: transparent;
}

select.includeDropdown {
   border: none;
   display: table;
   padding: 0;
   padding-top: 2px;
   height: 20px;
   font-size: 10px;
   color: #4d4848;
   margin: 3px 0 0 3px;
}
 
.right-0 {
   right: 0px !important;
}     

//trend filter section end



//comparison 1Aug2020 Prakash

// .bg-comparison-0 { background-color: #4A397C !important; }
// .bg-comparison-1 { background-color: #6E6096  !important; }
// .bg-comparison-2 { background-color: #998FB5  !important; }
// .bg-comparison-3 { background-color: #08B089  !important; }
// .bg-comparison-4 { background-color: #39BFA0  !important; }
// .bg-comparison-5 { background-color: #AAE0D3  !important; }
// .bg-comparison-6 { background-color: #70798F  !important; }
// .bg-comparison-7 { background-color: #9FA4B0  !important; }
// .bg-comparison-8 { background-color: #BBBFC7  !important; }
// .bg-comparison-9 { background-color: #49E1FC  !important; }
// .bg-comparison-10 { background-color: #91EDFD  !important; }
// .bg-comparison-11 { background-color: #C8F6FE  !important; }

// .bg-comparison-text-0 { color: #4A397C !important; }
// .bg-comparison-text-1 { color: #6E6096  !important; }
// .bg-comparison-text-2 { color: #998FB5  !important; }
// .bg-comparison-text-3 { color: #08B089  !important; }
// .bg-comparison-text-4 { color: #39BFA0  !important; }
// .bg-comparison-text-5 { color: #AAE0D3  !important; }
// .bg-comparison-text-6 { color: #70798F  !important; }
// .bg-comparison-text-7 { color: #9FA4B0  !important; }
// .bg-comparison-text-8 { color: #BBBFC7  !important; }
// .bg-comparison-text-9 { color: #49E1FC  !important; }
// .bg-comparison-text-10 { color: #91EDFD  !important; }
// .bg-comparison-text-11 { color: #C8F6FE  !important; }


.bg-comparison-0 {
   background-color: #039BE5 !important;
}

.bg-comparison-1 {
   background-color: #8E7CC2 !important;
}

.bg-comparison-2 {
   background-color: #08B089 !important;
}

.bg-comparison-3 {
   background-color: #6ACFB8 !important;
}

// .bg-comparison-4 { background-color: #70798F  !important; }
// .bg-comparison-5 { background-color: #BBBFC7  !important; }
.bg-comparison-4 {
   background-color: #0092F3 !important;
}

.bg-comparison-5 {
   background-color: #49E1FC !important;
}

.bg-comparison-6 {
   background-color: #6E6197 !important;
}

.bg-comparison-7 {
   background-color: #998FB5 !important;
}

.bg-comparison-8 {
   background-color: #39BFA1 !important;
}

.bg-comparison-9 {
   background-color: #AAE0D3 !important;
}

.bg-comparison-10 {
   background-color: #717A8F !important;
}

.bg-comparison-11 {
   background-color: #BBBFC7 !important;
}

.bg-comparison-12 {
   background-color: #4AE1FC !important;
}

.bg-comparison-text-0 {
   color: #039BE5 !important;
}

.bg-comparison-text-1 {
   color: #8E7CC2 !important;
}

.bg-comparison-text-2 {
   color: #08B089 !important;
}

.bg-comparison-text-3 {
   color: #6ACFB8 !important;
}

// .bg-comparison-text-4 { color: #70798F  !important; }
// .bg-comparison-text-5 { color: #BBBFC7  !important; }
.bg-comparison-text-4 {
   color: #0092F3 !important;
}

.bg-comparison-text-5 {
   color: #49E1FC !important;
}


.cardDropSection {
      width: 100%;
      min-height: 300px;
      // background-color: #434A5B; //prakash
      background-color: $black5; 
      position: absolute;
      margin-top: 34px;
      border-radius: 4px;
}

.customDropdown {
      border: none;
      display: table;
      //width: auto;
      min-width: 100%;
      //background-color: #444 !important;
      background-color: #ffffff !important;
      padding: 4px 5px;
      height: 26px;
      font-size: 12px;
      border-bottom: 1px solid #444444 !important;
      border-radius: 0px;
      font-weight: bold;
      border-radius: 4px;
      color: #000;
}

.customTextbox {
      font-size: 12px;
      height: 26px;
      border-radius: 4px;
      max-width: 100%;
      border: none;
}

.text-wrap {
      word-break: break-all;
      text-align: left;
}
   
.commonDropdown {
      min-height: 800px;
}

.commonDropdown ul {
      width: 100%;
      max-height: 200px;
      overflow-x: auto;
      word-break: break-word;
      list-style-type: none;
      margin: 0;
      padding: 0;
      top: 7px;
      //right:0px;
      //width: 200px;
      //background-color:#444;      
      // background-color: #434A5B !important; //prakash
      background-color: $black5 !important;
      //font-weight:bold;
      position: absolute;
      font-size: 11px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      //border-radius: 0px;
      color: #FFF;
}

.fitContent {
      width: fit-content !important;
}

.top30 {
      top: 30px !important;
}


.top57 {
   top: 57px !important;
}

.top45Per {
      top: 45% !important;
}

.topAuto {
      top: auto !important;
}

.commonDropdown li:hover,
.comparisonRemoveDrop li:hover {
      //background-color: #373737;
      background-color: #313744 !important;
      //color:#f90;
      color: #FFF;
      font-weight: bold
}

.commonDropdown li a {
      color: #FFF;
      text-decoration: none;
}

.commonDropdown li,
.comparisonRemoveDrop li {
      padding: 8px 17px 4px 12px;      
}

.commonDropdown li:last-child,
.comparisonRemoveDrop li:last-child {
      border-bottom: none;
}

.commonDropdown .checkBox {
      padding-left: 5px;
      vertical-align: middle;
}

.inlineFlex {
      display: flex;
      flex-wrap: wrap;
}

.comparisonTextboxDiv {
      display: flex;
      vertical-align: top;
      margin-left: 5px;
      flex-direction: column;
      position: relative;
}

.comparisonTextbox {
      border: 0 none;
      outline: 0 none;
      padding: 0;
      margin: 0;
      font-size: 13px;
      font-weight: bold;
      padding: 3px 0px 4px;
      min-width: 510px;
}
   
.addRow {
   color: #fff;
   border: 2px solid gray;
   border-radius: 5px;
   padding: 5px 8px;
   margin-right: 5px;
   vertical-align: middle
}

.removeRow {
   color: #fff;
   border: 2px solid gray;
   border-radius: 5px;
   padding: 3px 5px;
   margin-right: 5px;
   vertical-align: middle
}

.minWidth300 {
   min-width: 300px !important;
}

.side-title {
   min-width: 15%;
   max-width: 15%;
}

.compare-components-first {
   max-width: 23.666666666666667%;
   min-width: 23.666666666666667%;
   // margin: 0px 2.5% 0px 1%;

   background-color: #262B38;
   padding: 0px 1.5% 0px .5%;
   margin: 0px 1% 0px .5%;
}

.compare-components {
   max-width: 23.666666666666667%;
   min-width: 23.666666666666667%;
   margin: 0px 2.5%;
}

.title-count {
   min-width: 104px;
   text-align: right;
   color: #8d8b8b;
}

.vertical-bar {
   height: 108px;
   width: 12px;
   //border-radius: 8px 8px 0px 0px;
   background-color: #EDEDED;
   margin: 0 auto;
   overflow: hidden;
   display: flex;
   align-items: flex-end;
   flex-wrap: wrap;
   flex-direction: column;

   .v-progress {
      width: 100%;
      // border-radius: 8px 8px 0px 0px;
      background-color: #06CB9E;
   }
}

.bar-chart-label {
   border: 1px solid $white6;
   border-radius: 8px;
   padding: 10px;
   margin-top: 15px;

   .label {
         display: table;
         background-color: #FFF;
         margin: -20px 0 8px 0;
         padding: 0 15px;
   }

   .progress { 
         height: 18px;
         border-radius: 0px;
   }
}

.bar-label {
   border-radius: 8px;

   .progress {
         height: 18px;
         border-radius: 0px;
   }
}  
   

.tagFilter {
   position: absolute;
   z-index: 9;
   //top: 130%;
   top: 70%;
}
   
.tagDropBg {
   background-color: #303543 !important;
}
   
.assetTagDropdown {
   width: 150px;
}

.assetTagDropdown .css-48ayfv {
   background: transparent;
   color: #FFF;
}

.assetTagDropdown .css-1r4vtzz {
   background: transparent;
   color: #FFF;
}

.assetTagDropdown .css-1pcexqc-container {
   background-color: #FFF;
   color: #000;
   border: 1px solid gray;
   margin: 2px;
   position: absolute;
   width: 100%;
   padding-left: 4px;
   border-radius: 5px;
}

.assetTagDropdown .css-yk16xz-control {
   background-color: #303543 !important;
}

.assetTagDropdown .css-1pahdxg-control {
   background-color: #303543 !important;
   border-color: transparent;
}

.assetTagDropdown .css-1pahdxg-control:hover {
   background-color: #303543 !important;
   border-color: transparent;
}
   
.comparisonRemoveDrop {
   min-width: 75px;
   list-style-type: none;
   margin: 0;
   padding: 0;
   // background-color: #434A5B !important; //prakash
   background-color: $black5 !important;
   position: absolute;
   font-size: 11px;
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
   z-index: 1;
   color: #FFF;
   right: 3%;
}

.comparisonRemoveDrop li {
   cursor: pointer;
}

.moveTextbox {
   width: 100%;
}

.moveBtn {
   margin-top: 3px;
   font-size: 10px
}

.borderless td,
.borderless th {
   border: none;
}   

.counts_threads {
   border: 2px solid #039BE5 !important;
} 

.pagination {
   display: inline-block;
}
 
.pagination a {
   color: white;
   float: left;
   padding: 1px 6px;
   text-decoration: none;
   font-size: 14px;
   // padding: 2px 7px;//prakash
   // font-size: 12px; //prakash
}
 
 .pagination a.active {
   background-color: #039BE5;
   color: white;
   border-radius: 5px;
}
 
.pagination a:hover:not(.active) {
   background-color: #ddd;
   border-radius: 5px;
   color: #000;
}

.divider {
   border-image: linear-gradient(to top, rgb(60, 66, 82), rgb(45, 50, 62));
   border-image-slice: 1;
   border-left: 2px solid;
   height: 600px;
   position: absolute;
   left: 50%;
   margin-left: -1px;
   display: flex;
   align-self: center !important;
}


.threats-line {
   width: 3px;
   display: table;
   float: left;
   position: absolute;
   height: 100%;
   top: 0;
   left: 0;
}

.threats-content,
.threats-content th,
.threats-content td {
   vertical-align: baseline !important;
   padding: 5px;
   border-top: 0px !important;
}

.border-gray th,
.border-gray td {
   border-bottom: 1px solid #98999C !important;
   border-top: 0 !important;
}

@media (max-width: 1920px) {
      
      // .serviceContainerTable .table {
      //       tr {
      //             display: table-row !important;
      //       }
      //       th:first-child, td:first-child, th:nth-child(9), td:nth-child(9)  {
      //             width: 50px !important;
      //             padding:0;
      //             display: table-cell !important;
      //       }
      //       th:nth-child(2), td:nth-child(2), th:nth-child(3), td:nth-child(3), th:nth-child(4), td:nth-child(4) {
      //             display: table-cell !important;
      //             width: 100px !important;
      //       }
      //       th:nth-child(5), td:nth-child(5) {
      //             display: table-cell !important;
      //             width: 100px !important;
      //       }
      //       th:nth-child(6), td:nth-child(6), th:nth-child(7), td:nth-child(7), th:nth-child(8), td:nth-child(8)  {
      //             display: table-cell !important;
      //             width: 250px !important;
      //             max-width: 250px !important;
      //             overflow: hidden !important;
      //             text-overflow: ellipsis !important;
      //             white-space: nowrap !important;
      //       }
      // }

      .checkTable .table {
         tr {
               display: table-row !important;
         }

         th:first-child,
         td:first-child {
               width: 5px !important;
               padding: 0;
               display: table-cell !important;
         }

      }

      .assetTable .table {
            tr {
                  display: table-row !important;
            }

            th:first-child,
            td:first-child,
            th:nth-child(2),
            td:nth-child(2) {
                  width: 50px !important;
                  display: table-cell !important;
            }

            th:nth-child(3),
            td:nth-child(3),
            th:nth-child(4),
            td:nth-child(4) {
                  display: table-cell !important;
                  width: 350px !important;
                  max-width: 350px !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }
            
            th:nth-child(5),
            td:nth-child(5) {
                  display: table-cell !important;
                  width: 100px !important;
                  max-width: 100px !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }
      }


      .serviceSizingTable .table {
            tr {
                  display: table-row !important;
            }

            th:first-child,
            td:first-child {
                  width: 2% !important;
                  display: table-cell !important;
            }

            th:nth-child(2),
            td:nth-child(2),
            th:nth-child(3),
            td:nth-child(3) {
                  display: table-cell !important;
                  width: 10% !important;
                  max-width: 10% !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }

            th:nth-child(4),
            td:nth-child(4) {
                  display: table-cell !important;
                  width: 8% !important;
                  max-width: 8% !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }

            th:nth-child(5),
            td:nth-child(5) {
                  display: table-cell !important;
                  width: 70% !important;
                  max-width: 70% !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }
      }
}

@media (min-width: 1025px) {
      .governanceMainTable .table {
            tr {
                  display: table-row !important;
            }
      
            th:first-child,
            td:first-child,
            th:nth-child(2),
            td:nth-child(2),
            th:nth-child(7),
            td:nth-child(7),
            th:nth-child(9),
            td:nth-child(9) {
                  width: 50px !important;
                  display: table-cell !important;
            }
      
            
            
            th:nth-child(6),
            td:nth-child(6) {
                  display: table-cell !important;
                  width: 200px !important;
                  max-width: 200px !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }
            
            th:nth-child(4),
            td:nth-child(4) {
                  display: table-cell !important;
                  width: 150px !important;
                  max-width: 150px !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }
            
            th:nth-child(3),
            td:nth-child(3),
            th:nth-child(5),
            td:nth-child(5),                        
            th:nth-child(8),
            td:nth-child(8),
            th:nth-child(10),
            td:nth-child(10) {
                  display: table-cell !important;
                  width: 100px !important;
                  max-width: 100px !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }
      } 

      .previewSizing .table {
            tr {
                  display: table-row !important;
            }

            th:first-child,
            td:first-child {
                  width: 150px !important;
                  display: table-cell !important;
            }
      }

      .serviceAmiTable .table {
            tr {
                  display: table-row !important;
            }

            th:first-child,
            td:first-child {
                  width: 150px !important;
                  display: table-cell !important;
            }

            th:nth-child(2),
            td:nth-child(2),
            th:nth-child(9),
            td:nth-child(9),
            th:nth-child(10),
            td:nth-child(10) {
                  display: table-cell !important;
                  width: 100px !important;
            }

            th:nth-child(3),
            td:nth-child(3),
            th:nth-child(4),
            td:nth-child(4) {
                  display: table-cell !important;
                  width: 200px !important;
            }

            th:nth-child(5),
            td:nth-child(5),
            th:nth-child(6),
            td:nth-child(6),
            th:nth-child(7),
            td:nth-child(7),
            th:nth-child(8),
            td:nth-child(8) {
                  display: table-cell !important;
                  width: 100px !important;
            }
      }

      .serviceLogTable .table {
            tr {
                  display: table-row !important;
            }

            th:first-child,
            td:first-child {
                  width: 100px !important;
                  display: table-cell !important;
            }

            th,
            td {
                  display: table-cell !important;
                  width: 150px !important;
            }
           
            tr:last-child {
                  border-bottom: none;
            }
      }

      .serviceSizingTable .table {
            tr {
                  display: table-row !important;
            }

            th:first-child,
            td:first-child {
                  width: 2% !important;
                  display: table-cell !important;
            }

            th:nth-child(2),
            td:nth-child(2),
            th:nth-child(3),
            td:nth-child(3) {
                  display: table-cell !important;
                  width: 10% !important;
                  max-width: 10% !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }

            th:nth-child(4),
            td:nth-child(4) {
                  display: table-cell !important;
                  width: 8% !important;
                  max-width: 8% !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }

            th:nth-child(5),
            td:nth-child(5) {
                  display: table-cell !important;
                  width: 70% !important;
                  max-width: 70% !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }
      }      

      .governanceDashboardTable .table {
            tr {
                  display: table-row !important;
            }
      
            th:first-child,
            td:first-child,            
            th:nth-child(4),
            td:nth-child(4),
            th:nth-child(5),
            td:nth-child(5),
            th:nth-child(7),
            td:nth-child(7) {
                  display: table-cell !important;
                  width: 180px !important;
            }
                 
            th:nth-child(2),
            td:nth-child(2),
            th:nth-child(6),
            td:nth-child(6) {
                  display: table-cell !important;
                  width: 250px !important;
                  max-width: 300px !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }
            
            th:nth-child(3),
            td:nth-child(3) {
                  display: table-cell !important;
                  width: 400px !important;
                  max-width: 500px !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }

            th:nth-child(7),
            td:nth-child(7),
            th:nth-child(9),
            td:nth-child(9) {
                  display: table-cell !important;
                  width: 8% !important;
            }
      }
}

@media (max-width: 1025px) {
   .count {
      h4 {
         font-size: 20px !important;
      }
   }

   .form-group.styled-feild {
      .form-control {
         padding: 0px;
      }
   }

   .navigation,
   .nav-collapsed:hover {
      .nav-content {
         .nav-header {
            padding: 15px 5px 40px 5px;
         }

         width: 64px !important;
      }

      .main-nav {
         .dropdown-toggle::after {
            display: none !important;
         }

         a {
            padding: 15px 5px !important;
            text-align: center;
            display: flex;
            flex-direction: column;
            word-wrap: break-word;

            i {
               display: block;
               margin: 0px !important;
            }

            .nav-text {
               display: block;
               font-size: 8px;
            }
         }

         .dropdown-menu {
            min-width: 100%;
         }
      }
   }

   .nav-collapsed:hover {
      .nav-content {
         width: 64px;
         transition: 0.35s width;
         position: absolute;
      }
   }

   .collapse-nav {
      display: none;
   }

   .detail {
      .risk-status {
         max-width: 57px;
         max-height: 57px;
         min-width: 57px;
         min-height: 57px;
         line-height: 57px;
      }
   }
   
   .assetTable .table {
      tr {
            display: table-row !important;
      }

      th:first-child,
      td:first-child,
      th:nth-child(2),
      td:nth-child(2) {
            width: 50px !important;
            display: table-cell !important;
      }

      th:nth-child(3),
      td:nth-child(3),
      th:nth-child(4),
      td:nth-child(4) {
            display: table-cell !important;
            width: 200px !important;
            max-width: 200px !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
      }

      th:nth-child(5),
      td:nth-child(5) {
            display: table-cell !important;
            width: 100px !important;
            max-width: 100px !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
      }
   }

   .serviceSizingTable .table {
      tr {
            display: table-row !important;
      }

      th:first-child,
      td:first-child {
            width: 2% !important;
            display: table-cell !important;
      }

      th:nth-child(2),
      td:nth-child(2),
      th:nth-child(3), 
      td:nth-child(3) {
            display: table-cell !important;
            width: 10% !important;
            max-width: 10% !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
      }

      th:nth-child(4),
      td:nth-child(4) {
            display: table-cell !important;
            width: 8% !important;
            max-width: 8% !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
      }

      th:nth-child(5),
      td:nth-child(5) {
            display: table-cell !important;
            width: 70% !important;
            max-width: 70% !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
      }
   }
}

@media (max-width: 768px) {
      .count {
         h4 {
            font-size: 20px !important;
         }
      }
      
      .form-group.styled-feild {
         .form-control {
            padding: 0px;
         }
      }
   
      .navigation,
      .nav-collapsed:hover {
         .nav-content {
            .nav-header {
               padding: 15px 5px 40px 5px;
            }
   
            width: 64px;
         }
   
         .main-nav {
            .dropdown-toggle::after {
               display: none !important;
            }
   
            a {
               padding: 15px 5px !important;
               text-align: center;
               display: flex;
               flex-direction: column;
               word-wrap: break-word;
   
               i {
                  display: block;
                  margin: 0px !important;
               }
   
               .nav-text {
                  display: block;
                  font-size: 8px;
               }
            }
   
            .dropdown-menu {
               min-width: 100%;
            }
         }
      }
   
      .nav-collapsed:hover {
         .nav-content {
            width: 64px;
            transition: 0.35s width;
            position: absolute;
         }
      }
   
      .collapse-nav {
         display: none;
      }
   
      .detail {
         .risk-status {
            max-width: 57px;
            max-height: 57px;
            min-width: 57px;
            min-height: 57px;
            line-height: 57px;
         }
      }

      .assetTable .table {
            tr {
                  display: table-row !important;
            }

            th:first-child,
            td:first-child,
            th:nth-child(2),
            td:nth-child(2) {
                  width: 50px !important;
                  display: table-cell !important;
            }

            th:nth-child(3),
            td:nth-child(3),
            th:nth-child(4),
            td:nth-child(4) {
                  display: table-cell !important;
                  width: 100px !important;
                  max-width: 100px !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }

            th:nth-child(5),
            td:nth-child(5) {
                  display: table-cell !important;
                  width: 70px !important;
                  max-width: 70px !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                  white-space: nowrap !important;
            }
      }
}

@media (max-width: 580px) {
      .login {
         width: 100%;
      }
   
      .main-content {
         width: 100%;
      }
   
      .navigation {
         .nav-content {
            width: 0px;
            transition: 0.35s width;
            position: absolute;
         }
      }
}

@media (max-width: 1280px) {
      
      .navigation .nav-content,
      .nav-collapsed:hover .nav-content {
            width: 200px;
      }
      
      .nav-collapsed {
           .nav-content {
               width: 64px;
            }
      }
      
      .form-group.styled-feild {
            padding: 5px;
      }
}