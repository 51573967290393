// variables
$black1: #000000;
$black2: #303030;
$black3: #262B38;
$black4: #4d4d4d;
$black5: #434A5B;
$black6: #60687C;
$black7: #8E9EC4;
$black8: #474C58;
$black9: #858B99;
$white1: #FFFFFF;
$white2: #FAFAFA;
$white3: #F5F5F5;
$white4: #EDEDED;
$white5: #E0E0E0;
$white6: #DEE2E6;
$success: #06CB9E;
$success-light: #CBF2D5;
$danger: #F75E3F;
$orange: #FF6F00;
$orange50: #FAAF75;
$warning: #FFD200;
$warning2: #e0ba0e;
$info: #039BE5;
$hvoerInfo: #DEEBFF;
// $info: #1FCFE7;
// $info : #17a2b8;
$blue1: #17a2b8;
$blue2: #007bff;
$blue3: #6eb2bd;
$blue4: #CBDCFF;
$primary: #2D323E;
$primary2: #3C4253;
$gray: #f3f3f3 ;
$gray1: #ECECEC;
$gray2: #EDEDED;
$gray3: #C1C1C1;
$gray4: #9B9898;
$gray5: #858B9A;
$gray6: #393F4E;
$gray7: #2C3333;
$terminal: #CDEE69;
$purple: #693EBC;
$background: #969aa7;
$dark: #2D323E;
$dark-50: #272C3A;
$muted: #383E4D;
$dark-theme-green: #24A597;
$dark-theme-blue: #3F73AD;
$dark-theme-purple: #775BA2;
$dark-theme-orange: #B96422;
$dark-theme-red: #F44336;
$dark-theme-purple2: #5F5BA2;
$purplishRed: #DC2B55; //#B71F2F;
$dark2: #1f232e;
$dark3: #262B38;
$dark4: #2C323F;
$dark5: #202c3f;
$border-for-dark3: #444955;
$primary-color: #A9A9A9;
$case-color: #6c757d;
$critical: #5F5BA2;
$high: #9A77D1;
$medium: #3F73AD;
$low: #24A597;
$others: #E36877;

$alerts: #3F73AD;
$anomalies: #775BA2;
$errors: #24A597;
$issues: #FF4560;


$purple3: #854097; //cadmiumViolet
$purple4: #36295C; //darkPurple

$blue1: #588CEF; //blueElemental
$blue2: #659AD9; //blueBay
$blue3: #c7dbf0; //blueBayLight

$pink1: #FFAB91; //pinkPersimmon //ex in site graphbg
$pink2: #F18A6A; //juicyPassionfruit //line graph in above bg

$slatePing: #B05B6B;
$matteCarmine: #9F666F;

$froly: #E36877;
$frolyDark: #df5667;
$frolyLight: #f6d2d6;

$tanagerTurquoise: #99b8b8;


// variables

//from style sass start

.bg-background{
      background-color: $background
}
.bg-orange50 {
      background-color: $orange50
}

.badge-muted {
      color: #fff;
      background-color: $muted;
}

.badge-orange {
      color: #fff;
      background-color: $orange;
}

.badge-warning{
      color: #fff;
      background-color: $warning2
}

.rounded_25rem {
      border-radius: 0.25rem !important;
}

.text-primary-color { color: $primary-color !important; }
.text-case-color { color: $case-color !important; }
.text-black2 { color: $black2 !important; }
.text-black3 { color: $black3 !important; }
.text-black4 { color: $black4 !important; }
.text-black5 { color: $black5 !important; }
.text-black6 { color: $black6 !important; }
.text-black7 { color: $black7 !important; }
.text-black8 { color: $black8 !important; }
.text-black9 { color: $black9 !important; }
.text-white2 { color: $white2 !important; }
.text-white3 { color: $white3 !important; }
.text-white4 { color: $white4 !important; }
.text-white5 { color: $white5 !important; }
.text-white6 { color: $white6 !important; }
.text-success-light { color: $success-light !important; }
.text-orange { color: $orange !important; }
.text-orange50 { color: $orange50 !important; }
.text-warning { color: $warning !important; }


.text-primary1 { color: $primary !important; }
.text-primary2 { color: $primary2 !important; }
.text-gray1 { color: $gray1 !important; }
.text-gray2 { color: $gray2 !important; }
.text-gray5 { color: $gray5 !important; }
.text-gray6 { color: $gray6 !important; }
.text-gray7 { color: $gray7 !important; }
.text-dark3 { color: $black7 !important; }
.text-dark4 { color: $dark4 !important; }
.text-terminal { color: $terminal !important }
.text-purplishRed { color: $purplishRed !important}

.text-dark-theme-green {color: $dark-theme-green !important;}
.text-dark-theme-blue {color: $dark-theme-blue !important;}
.text-dark-theme-purple {color: $dark-theme-purple !important;}
.text-dark-theme-orange {color: $dark-theme-orange !important;}
.text-dark-theme-red {color: $dark-theme-red !important;}
.text-dark-theme-purple2 {color: $dark-theme-purple2 !important;}

.text-alert { color: $alerts !important}
.text-anomalies { color: $anomalies !important}
.text-errors { color: $errors !important}
.text-issues { color: $issues !important}

.text-purple3 { color: $purple3 !important; }
.text-purple4 { color: $purple4 !important; }
.text-blue1 { color: $blue1 !important; }
.text-blue2 { color: $blue2 !important; }
.text-blue3 { color: $blue3 !important; }
.text-pink1 { color: $pink1 !important; }
.text-pink2 { color: $pink2 !important; }

.textboxBorder { border: 1px solid#ced4da !important;}

.success-border { border: 1px solid $success !important; }
.danger-border { border: 1px solid $danger !important; }
// .border-danger { border: 1px solid $danger !important; }
.info-border { border: 1px solid $info !important; }
.border-orange { border: 1px solid $orange !important; }
.border-orange50 { border: 1px solid $orange50 !important; }
.border-secondary { border: 1px solid #858B99 !important; }

.border-blue3 { border: 1px solid $blue3 !important; }
.border-blue4 { border: 1px solid $blue4 !important; }
.warning-border { border: 1px solid $warning !important; }

.border-blue { border: 1px solid #039BE5  !important; }
.text-filterBlue { color: #039BE5 !important; }
.text-underline { text-decoration:underline !important; }

.border-top {
      border-top: 1px solid $black5 !important;
}

.bg-black  { background-color: $black1 !important; }
.bg-black2 { background-color: $black2 !important; }
.bg-black3 { background-color: $black3 !important; }
.bg-black4 { background-color: $black4 !important; }
.bg-black5 { background-color: $black5 !important; }
.bg-black6 { background-color: $black6 !important; }
.bg-black7 { background-color: $black7 !important; }
.bg-black8 { background-color: $black8 !important; }
.bg-black9 { background-color: $black9 !important; }
.bg-white2 { background-color: $white2 !important; }
.bg-white5 { background-color: $white5 !important; }
.bg-white6 { background-color: $white6 !important; }
.bg-warning { background-color: $warning !important; color: $white1; }
.bg-gray2 { background-color: $gray2 !important;}
.bg-gray4 { background-color: $gray4 !important;}
.bg-gray6 { background-color: $gray6 !important; }
.bg-gray7 { background-color: $gray7 !important; }
.bg-blue3 { background-color: $blue3 !important; }
.bg-blue4 { background-color: $blue4 !important; }
.bg-purplishRed { background-color: $purplishRed !important; }
.bg-terminal { background-color: $terminal !important; }
.bg-light-blue { background-color: #91EDFD !important; }
.disabledBg { background-color: #FBFAFB !important; }
.disabledDarkBg { background-color: $gray5 !important; }
.bg-filterBlue { background-color: #039BE5 !important; }
.bg-metrics-graph { background-color: #DEE2E6 !important; }
.bg-dark2 { background-color: $dark2 !important; }
.bg-dark3 { background-color: $dark3 !important; }
.bg-dark4 { background-color: $dark4 !important; }
.bg-low { background-color: $purple !important; }

.bg-dark-theme-green { background-color: $dark-theme-green !important; }
.bg-dark-theme-blue { background-color: $dark-theme-blue !important; }
.bg-dark-theme-purple { background-color: $dark-theme-purple !important; }
.bg-dark-theme-orange { background-color: $dark-theme-orange !important; }
.bg-dark-theme-red { background-color: $dark-theme-red !important; }
.bg-dark-theme-purple2 { background-color: $dark-theme-purple2 !important; }

.bg-alerts { background-color: $alerts !important}
.bg-anomalies { background-color: $anomalies !important}
.bg-errors { background-color: $errors !important}
.bg-issues { background-color: $issues !important}

.bg-purple3 { background-color: $purple3 !important; }
.bg-purple4 { background-color: $purple4 !important; }
.bg-blue1 { background-color: $blue1 !important; }
.bg-blue2 { background-color: $blue2 !important; }
.bg-blue3 { background-color: $blue3 !important; }
.bg-pink1 { background-color: $pink1 !important; }
.bg-pink2 { background-color: $pink2 !important; }

.border-dark2 {border: 1px solid $gray5;}

.border-left-danger{
      border-left: 1px solid $danger !important
}
.border-left-success{
      border-left: 1px solid $success !important
}
.border-2-left-danger{
      border-left: 2px solid $danger !important
}
.border-2-left-success{
      border-left: 2px solid $success !important
}

.aiopsMetrics {
      .riskLine {
            width: 3px;
            display: table;
            float: left;
            // position: absolute;
            height: 80%;
            align-self: center
            // top: 20%;
            // left: 3px;
      }
}

.badge-outline-border-active, .badge-outline-border-completed {
//    background-color: $success;
   border: 1px solid $success !important;
   color: $white1 !important;
}

.badge-outline-border-warning, .badge-outline-border-inactive {
//    background-color: $warning;
   border: 1px solid $warning !important;
   color: $white1 !important;
}

.badge-outline-active, .badge-outline-completed {
   background-color: $success;
   border: 1px solid $success !important;
   color: $white1 !important;
}

.badge-outline-warning, .badge-outline-inactive {
   background-color: $warning;
   border: 1px solid $warning !important;
   color: $white1 !important;
}

.badge-outline-pending {
   background-color: transparent;
   border: 1px solid $info !important;
   color: $info !important;
}

.badge-outline-danger, .badge-outline-failed {
   background-color: $danger;
   border: 1px solid $danger !important;
   color: $white1 !important;
}

.badge-outline-gray4 {
   background-color: transparent;
   border: 1px solid $gray4;
   color: $white1;
}


.badge-critical {
   color: #fff;
   background-color: #26B68F;
}

.badge-high {
   color: #fff;
   background-color: #2ED1BE;
}

.badge-medium {
   color: #fff;
   background-color: #67E1D6;
}

.badge-low {
   color: #fff;
   background-color: #00B5D4;
}

.badge-outline-critical {
   background-color: transparent;
   border: 1px solid $danger;
   color: $black2;
}

.badge-outline-high {
   background-color: transparent;
   border: 1px solid $orange;
   color: $black2;
}
   
.badge-outline-medium {
   background-color: transparent;
   border: 1px solid $purple;
   color: $black2;
}
      
.badge-outline-low {
   background-color: transparent;
   border: 1px solid $success;
   color: $black2;
}

.badge-outline-info {
   background-color: transparent;
   border: 1px solid $info;
   color: $info;
}

.badge-outline-orange {
      background-color: transparent;
      border: 1px solid $purple;
      color: $purple;
}

// .table tr:last-child {
//       border-bottom: 1px solid #dee2e6
// }

.w-fit-content {
      width: fit-content !important;
}

.wpx-80 {
      width: 80px !important;
}

.wpx-100 {
      width: 100px !important;
}

.wpx-110 {
      width: 110px !important;
}

.wpx-150 {
      width: 150px !important;
}

.wpx-250 {
      width: 250px !important;
}

.w-4 {
      width: 4% !important;
}
   
.w-96 {
      width: 96% !important;
}


.w-2 {
      width: 2% !important;
}

.w-3 {
      width: 3% !important;
}

.w-5 {
      width: 5% !important;
}
.w-12 {
      width: 12% !important;
}
.w-17 {
      width: 17% !important;
}
.w-18 {
      width: 18% !important;
}
   
.w-95 {
      width: 95% !important;
}

.w-48 {
      width: 48% !important;
}
.w-84 {
      width: 83% !important;
}
.w-83 {
      width: 83% !important;
}
   
.w-88 {
      width: 88% !important;
}
   
.w-82 {
      width: 82% !important;
}
   
.w-98 {
      width: 98% !important;
}
   
.w-99 {
      width: 99% !important;
}

.p-2p {
      padding: 2px;
}
.pt-2p {
      padding-top: 2px !important;
}
.pr-2p {
      padding-right: 2px !important;
}
.pb-2p {
      padding-bottom: 2px !important;
}
.pl-2p {
      padding-left: 2px !important;
}

.p-5p {
      padding: 5px !important;
}
.pt-5p {
      padding-top: 5px !important;
}
.pr-5p {
      padding-right: 5px !important;
}
.pb-5p {
      padding-bottom: 5px !important;
}
.pl-5p {
      padding-left: 5px !important;
}


.p-30p {
      padding: 30px !important;
}
.pt-30p {
      padding-top: 30px !important;
}
.pr-30p {
      padding-right: 30px !important;
}
.pb-30p {
      padding-bottom: 30px !important;
}
.pl-30p {
      padding-left: 30px !important;
}

.m-2p {
      margin: 2px !important;
}
.mt-2p {
      margin-top: 2px !important;
}
.mr-2p {
      margin-right: 2px !important;
}
.mb-2p {
      margin-bottom: 2px !important;
}
.ml-2p {
      margin-left: 2px !important;
}

.m-3p {
      margin: 3px !important;
}
.mt-3p {
      margin-top: 3px !important;
}
.mr-3p {
      margin-right: 3px !important;
}
.mb-3p {
      margin-bottom: 3px !important;
}
.ml-3p {
      margin-left: 3px !important;
}

.m-5p {
      margin: 5px !important;
}
.mt-5p {
      margin-top: 5px !important;
}
.mr-5p {
      margin-right: 5px !important;
}
.mb-5p {
      margin-bottom: 5px !important;
}
.ml-5p {
      margin-left: 5px !important;
}

.m-6p {
      margin: 6px;
}
.mt-6p {
      margin-top: 6px;
}
.mr-6p {
      margin-right: 6px;
}
.mb-6p {
      margin-bottom: 6px;
}
.ml-6p {
      margin-left: 6px;
}

.positionAbsolute {
      position: absolute !important;
}

.position-unset {
      position: unset !important;
}
.flexWrap-unset {
      flex-wrap: unset !important;
}

.hideRMSCPlaceHolder .css-48ayfv, .hideRMSCPlaceHolder .css-1v99tuv {
      display: none
}

.hideRMSCPlaceHolder>div {
      height:0px;
      margin-top: 5px;
}

//comparison 1Aug2020 Prakash

.progress-bg-0 { background-color: #4A397C !important; }
.progress-bg-1 { background-color: #6E6096  !important; }
.progress-bg-2 { background-color: #998FB5  !important; }
.progress-bg-3 { background-color: #08B089  !important; }
.progress-bg-4 { background-color: #39BFA0  !important; }
.progress-bg-5 { background-color: #AAE0D3  !important; }
.progress-bg-6 { background-color: #70798F  !important; }
.progress-bg-7 { background-color: #9FA4B0  !important; }
.progress-bg-8 { background-color: #BBBFC7  !important; }
.progress-bg-9 { background-color: #49E1FC  !important; }
.progress-bg-10 { background-color: #91EDFD  !important; }
.progress-bg-11 { background-color: #C8F6FE  !important; }

.minHeight100 {
      min-height: 100%;
}

.minHeight30px { 
      min-height: 40px !important;
}

.minHeight50px {
      min-height: 50px !important
}
.minHeight75px {
      min-height: 75px !important
}
.minHeight90px {
      min-height: 90px !important
}
.minHeight100px {
      min-height: 100px !important
}


.minMaxWidth100 {
   min-width: 100px !important;
   max-width: 100px !important;
}

.minMax270Width {
   min-width: 270px !important;
   max-width: 270px !important;
}

.minMaxWidth150 {
   min-width: 100px !important;
   max-width: 150px !important;
}

.minMaxWidth200 {
   min-width: 100px !important;
   max-width: 200px !important;
}

.minMaxWidth250 {
   min-width: 100px !important;
   max-width: 250px !important;
}

.min200MaxWidth250 {
   min-width: 200px !important;
   max-width: 250px !important;
}

.minMaxWidth300 {
   min-width: 300px !important;
   max-width: 300px !important;
}

.minMaxWidth350 {
   min-width: 350px !important;
   max-width: 350px !important;
}

.min100Max250Width {
   min-width: 100px !important;
   max-width: 250px !important;
}

.minWidth400 {
      min-width: 400px !important;
}

.minWidth100 {
      min-width: 100px !important;
}
.minWidth120 {
      min-width: 120px !important;
}

.width120 {
      width: 120px !important;
}

.minWidth150 {
      min-width: 150px !important;
}

.minWidth220 {
      min-width: 220px !important;
}
.maxWidth300 {
      max-width: 300px !important;
}

.minMaxHeight820 {
      min-height: 820px !important;
      max-height: 820px !important;
}

.minMaxHeight770 {
      min-height: 770px !important;
      max-height: 770px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
      -moz-appearance:textfield; /* Firefox */
}

.resizeTable {
      th {
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;  
      }
      th:first-child,
      td:first-child {
            width: 50px !important;
      }
}

.remediationResizeTable {
th {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;  
}
th:first-child,
td:first-child {
      width: 50px !important;
}

th:nth-child(2),
td:nth-child(2),
th:nth-child(5),
td:nth-child(5) {
      display: table-cell !important;
      width: 100px !important;
      max-width: 150px !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
}
}

.risk-time-line {
      width: 3px;
      display: table;
      float: left;
      position: absolute
}

.aiopsAssetTable {      
      tr {
            display: table-row !important;
            border-bottom: 1px solid #dee2e6 !important;
            width: 100% !important;
      }
            
      th, td{
            border: 0 !important;
            display: table-cell !important;
            // width: 100px !important;
            max-width: 300px !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;            
      }

      // th:first-child,
      // td:first-child {
      //       display: table-cell !important;
      //       width: 50px !important;
      //       font-size: 10px !important;
      // }
}

.aiopsAlertTable {
      tr {
            border-bottom: 1px solid #dee2e6 !important;
            width: 100% !important;
      }
      th, td {
            border: 0 !important
      }
}

.topologyNodeAlertsTable {

      // tr {
      //       border-bottom: 1px solid #dee2e6 !important;
      //       width: 100% !important;
      // }
                  
      // th, td {
      //       border: 0 !important
      // }

      th:first-child,
      td:first-child {
            width: 40px !important;
            padding-top: 5px;
            padding-bottom: 3px;
      }

      th:nth-child(2),
      td:nth-child(2) {
            width: 40px !important;
            padding-top: 5px;
            padding-bottom: 3px;
      }

}

.topologyNodeAssetsTable {
      th:first-child,
      td:first-child {
            width: 40px !important;
            padding-top: 5px;
            padding-bottom: 3px;
      }
}

.aiopsListCaseDetailTable {
      tr {
            border-bottom: 1px solid #dee2e6 !important;
            width: 100% !important;
      }
                  
      th, td {
            border: 0 !important
      }

      // th:first-child,
      // td:first-child {
      //       width: 40px !important;
      //       padding-top: 5px;
      //       padding-bottom: 3px;
      // }

      // th:nth-child(3),
      // td:nth-child(3) {
      //       width: 100px !important;
      //       padding-top: 5px;
      //       padding-bottom: 3px;
      // }
}
.aiopsRemdiationList {
      tr {
            border-bottom: 1px solid #dee2e6 !important;
            width: 100% !important;
      }
                  
      th, td {
            border: 0 !important
      }

      // th:last-child,
      // td:last-child {
      //       overflow: hidden !important;
      // }
}

.dlpControlDetails {
      tr {
            border-bottom: 1px solid #dee2e6 !important;
            width: 100% !important;
      }
      th, td {
            border: 0 !important
      }
      th:first-child,
      td:first-child,
      th:nth-child(2),
      td:nth-child(2) {
            display: table-cell !important;
            white-space: nowrap !important;
      }
      th:nth-child(3), td:nth-child(3) {
            display: table-cell !important;
            width: 50% !important;
      }
}

.basicTable {
      tr {
            border-bottom: 1px solid #dee2e6 !important;
            width: 100% !important;
      }
      th, td {
            border: 0 !important
      }
}

.divider {
      border-image: linear-gradient(to top, #3c4252, #2d323e);
      border-image-slice: 1;
      border-left: 2px solid;
      height: 600px;
      position: absolute;
      left: 50%;
      margin-left: -1px;
      display: flex;
      align-self: center !important;
}
    
.threats-line {
      width: 3px;
      display: table;
      float: left;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
}
    
.threats-content, .threats-content th, .threats-content td {
      vertical-align: baseline !important;
      padding: 5px;
      border-top: 0px !important;
}
   
.border-gray th, .border-gray td {
      border-bottom: 1px solid #98999C !important;
      border-top: 0 !important;
}

// .badge-square {
//       line-height: 1.25 !important;
// }

.border-color-dark-theme-green { border-color: $dark-theme-green !important; }
.border-color-dark-theme-blue { border-color: $dark-theme-blue !important; }
.border-color-dark-theme-purple { border-color: $dark-theme-purple !important; }
.border-color-dark-theme-orange { border-color: $dark-theme-orange !important; }
.border-color-dark-theme-red { border-color: $dark-theme-red !important; }
.border-color-info { border-color: $info !important; }

//from style sass end
.border-color-dark-theme-green { border-color: $dark-theme-green !important; }
.border-color-dark-theme-blue { border-color: $dark-theme-blue !important; }
.border-color-dark-theme-purple { border-color: $dark-theme-purple !important; }
.border-color-dark-theme-orange { border-color: $dark-theme-orange !important; }
.border-color-dark-theme-red { border-color: $dark-theme-red !important; }

.border-info { border: 1px solid $info !important; }
.border-for-dark3 { border: 1px solid $border-for-dark3 !important; }
.border-for-dark4 { border: 1px solid $dark4 !important; }
.border-dark { border: 1px solid $dark !important; }
.border-dark-50 { border: 1px solid $dark-50 !important; }
.border-muted { border: 1px solid $muted !important; }
.border-dark-theme-green { border: 1px solid $dark-theme-green !important; }
.border-dark-theme-blue { border: 1px solid $dark-theme-blue !important; }
.border-dark-theme-purple { border: 1px solid $dark-theme-purple !important; }
.border-dark-theme-orange { border: 1px solid $dark-theme-orange !important; }
.border-dark-theme-red { border: 1px solid $dark-theme-red !important; }
.border-black1 { border: 1px solid $black1 !important; }
.border-black2 { border: 1px solid $black2 !important; }
.border-black3 { border: 1px solid $black3 !important; }
.border-black4 { border: 1px solid $black4 !important; }
.border-black5 { border: 1px solid $black5 !important; }
.border-black6 { border: 1px solid $black6 !important; }
.border-black7 { border: 1px solid $black7 !important; }
.border-black8 { border: 1px solid $black8 !important; }
.border-black9 { border: 1px solid $black9 !important; }
.border-white1 { border: 1px solid $white1 !important; }
.border-white2 { border: 1px solid $white2 !important; }
.border-white3 { border: 1px solid $white3 !important; }
.border-white4 { border: 1px solid $white4 !important; }
.border-white5 { border: 1px solid $white5 !important; }
.border-gray1 { border: 1px solid $gray1 !important; }
.border-gray2 { border: 1px solid $gray2 !important; }
.border-gray3 { border: 1px solid $gray3 !important; }
.border-gray4 { border: 1px solid $gray4 !important; }
.border-gray5 { border: 1px solid $gray5 !important; }
.border-gray6 { border: 1px solid $gray6 !important; }
.border-gray7 { border: 1px solid $gray7 !important; }
.border-purplishRed { border: 1px solid $purplishRed !important; }
.border-purple3 { border: 1px solid $purple3 !important; }
.border-purple4 { border: 1px solid $purple4 !important; }
.border-blue1 { border: 1px solid $blue1 !important; }
.border-blue2 { border: 1px solid $blue2 !important; }
.border-blue3 { border: 1px solid $blue3 !important; }
.border-pink1 { border: 1px solid $pink1 !important; }
.border-pink2 { border: 1px solid $pink2 !important; }

.border-left-info { border-left: 1px solid $info !important; }
.border-left-blue3 { border-left: 1px solid $blue3 !important; }
.border-left-blue4 { border-left: 1px solid $blue4 !important; }
.border-left-for-dark3 { border-left: 1px solid $border-for-dark3 !important; }
.border-left-for-dark4 { border-left: 1px solid $dark4 !important; }
.border-left-dark { border-left: 1px solid $dark !important; }
.border-left-dark-50 { border-left: 1px solid $dark-50 !important; }
.border-left-muted { border-left: 1px solid $muted !important; }
.border-left-dark-theme-green { border-left: 1px solid $dark-theme-green !important; }
.border-left-dark-theme-blue { border-left: 1px solid $dark-theme-blue !important; }
.border-left-dark-theme-purple { border-left: 1px solid $dark-theme-purple !important; }
.border-left-dark-theme-orange { border-left: 1px solid $dark-theme-orange !important; }
.border-left-dark-theme-red { border-left: 1px solid $dark-theme-red !important; }
.border-left-black1 { border-left: 1px solid $black1 !important; }
.border-left-black2 { border-left: 1px solid $black2 !important; }
.border-left-black3 { border-left: 1px solid $black3 !important; }
.border-left-black4 { border-left: 1px solid $black4 !important; }
.border-left-black5 { border-left: 1px solid $black5 !important; }
.border-left-black6 { border-left: 1px solid $black6 !important; }
.border-left-black7 { border-left: 1px solid $black7 !important; }
.border-left-black8 { border-left: 1px solid $black8 !important; }
.border-left-black9 { border-left: 1px solid $black9 !important; }
.border-left-white1 { border-left: 1px solid $white1 !important; }
.border-left-white2 { border-left: 1px solid $white2 !important; }
.border-left-white3 { border-left: 1px solid $white3 !important; }
.border-left-white4 { border-left: 1px solid $white4 !important; }
.border-left-white5 { border-left: 1px solid $white5 !important; }
.border-left-gray1 { border-left: 1px solid $gray1 !important; }
.border-left-gray2 { border-left: 1px solid $gray2 !important; }
.border-left-gray3 { border-left: 1px solid $gray3 !important; }
.border-left-gray4 { border-left: 1px solid $gray4 !important; }
.border-left-gray5 { border-left: 1px solid $gray5 !important; }
.border-left-gray6 { border-left: 1px solid $gray6 !important; }
.border-left-gray7 { border-left: 1px solid $gray7 !important; }
.border-left-purplishRed { border-left: 1px solid $purplishRed !important; }
.border-left-purple3 { border-left: 1px solid $purple3 !important; }
.border-left-purple4 { border-left: 1px solid $purple4 !important; }
.border-left-blue1 { border-left: 1px solid $blue1 !important; }
.border-left-blue2 { border-left: 1px solid $blue2 !important; }
.border-left-blue3 { border-left: 1px solid $blue3 !important; }
.border-left-pink1 { border-left: 1px solid $pink1 !important; }
.border-left-pink2 { border-left: 1px solid $pink2 !important; }

.border-right-info { border-right: 1px solid $info !important; }
.border-right-blue3 { border-right: 1px solid $blue3 !important; }
.border-right-blue4 { border-right: 1px solid $blue4 !important; }
.border-right-for-dark3 { border-right: 1px solid $border-for-dark3 !important; }
.border-right-for-dark4 { border-right: 1px solid $dark4 !important; }
.border-right-dark { border-right: 1px solid $dark !important; }
.border-right-dark-50 { border-right: 1px solid $dark-50 !important; }
.border-right-muted { border-right: 1px solid $muted !important; }
.border-right-dark-theme-green { border-right: 1px solid $dark-theme-green !important; }
.border-right-dark-theme-blue { border-right: 1px solid $dark-theme-blue !important; }
.border-right-dark-theme-purple { border-right: 1px solid $dark-theme-purple !important; }
.border-right-dark-theme-orange { border-right: 1px solid $dark-theme-orange !important; }
.border-right-dark-theme-red { border-right: 1px solid $dark-theme-red !important; }
.border-right-black1 { border-right: 1px solid $black1 !important; }
.border-right-black2 { border-right: 1px solid $black2 !important; }
.border-right-black3 { border-right: 1px solid $black3 !important; }
.border-right-black4 { border-right: 1px solid $black4 !important; }
.border-right-black5 { border-right: 1px solid $black5 !important; }
.border-right-black6 { border-right: 1px solid $black6 !important; }
.border-right-black7 { border-right: 1px solid $black7 !important; }
.border-right-black8 { border-right: 1px solid $black8 !important; }
.border-right-black9 { border-right: 1px solid $black9 !important; }
.border-right-white1 { border-right: 1px solid $white1 !important; }
.border-right-white2 { border-right: 1px solid $white2 !important; }
.border-right-white3 { border-right: 1px solid $white3 !important; }
.border-right-white4 { border-right: 1px solid $white4 !important; }
.border-right-white5 { border-right: 1px solid $white5 !important; }
.border-right-gray1 { border-right: 1px solid $gray1 !important; }
.border-right-gray2 { border-right: 1px solid $gray2 !important; }
.border-right-gray3 { border-right: 1px solid $gray3 !important; }
.border-right-gray4 { border-right: 1px solid $gray4 !important; }
.border-right-gray5 { border-right: 1px solid $gray5 !important; }
.border-right-gray6 { border-right: 1px solid $gray6 !important; }
.border-right-gray7 { border-right: 1px solid $gray7 !important; }
.border-right-purplishRed { border-right: 1px solid $purplishRed !important; }
.border-right-purple3 { border-right: 1px solid $purple3 !important; }
.border-right-purple4 { border-right: 1px solid $purple4 !important; }
.border-right-blue1 { border-right: 1px solid $blue1 !important; }
.border-right-blue2 { border-right: 1px solid $blue2 !important; }
.border-right-blue3 { border-right: 1px solid $blue3 !important; }
.border-right-pink1 { border-right: 1px solid $pink1 !important; }
.border-right-pink2 { border-right: 1px solid $pink2 !important; }

.border-top-info { border-top: 1px solid $info !important; }
.border-top-blue3 { border-top: 1px solid $blue3 !important; }
.border-top-blue4 { border-top: 1px solid $blue4 !important; }
.border-top-for-dark3 { border-top: 1px solid $border-for-dark3 !important; }
.border-top-for-dark4 { border-top: 1px solid $dark4 !important; }
.border-top-dark { border-top: 1px solid $dark !important; }
.border-top-dark-50 { border-top: 1px solid $dark-50 !important; }
.border-top-muted { border-top: 1px solid $muted !important; }
.border-top-dark-theme-green { border-top: 1px solid $dark-theme-green !important; }
.border-top-dark-theme-blue { border-top: 1px solid $dark-theme-blue !important; }
.border-top-dark-theme-purple { border-top: 1px solid $dark-theme-purple !important; }
.border-top-dark-theme-orange { border-top: 1px solid $dark-theme-orange !important; }
.border-top-dark-theme-red { border-top: 1px solid $dark-theme-red !important; }
.border-top-black1 { border-top: 1px solid $black1 !important; }
.border-top-black2 { border-top: 1px solid $black2 !important; }
.border-top-black3 { border-top: 1px solid $black3 !important; }
.border-top-black4 { border-top: 1px solid $black4 !important; }
.border-top-black5 { border-top: 1px solid $black5 !important; }
.border-top-black6 { border-top: 1px solid $black6 !important; }
.border-top-black7 { border-top: 1px solid $black7 !important; }
.border-top-black8 { border-top: 1px solid $black8 !important; }
.border-top-black9 { border-top: 1px solid $black9 !important; }
.border-top-white1 { border-top: 1px solid $white1 !important; }
.border-top-white2 { border-top: 1px solid $white2 !important; }
.border-top-white3 { border-top: 1px solid $white3 !important; }
.border-top-white4 { border-top: 1px solid $white4 !important; }
.border-top-white5 { border-top: 1px solid $white5 !important; }
.border-top-gray1 { border-top: 1px solid $gray1 !important; }
.border-top-gray2 { border-top: 1px solid $gray2 !important; }
.border-top-gray3 { border-top: 1px solid $gray3 !important; }
.border-top-gray4 { border-top: 1px solid $gray4 !important; }
.border-top-gray5 { border-top: 1px solid $gray5 !important; }
.border-top-gray6 { border-top: 1px solid $gray6 !important; }
.border-top-gray7 { border-top: 1px solid $gray7 !important; }
.border-top-purplishRed { border-top: 1px solid $purplishRed !important; }
.border-top-purple3 { border-top: 1px solid $purple3 !important; }
.border-top-purple4 { border-top: 1px solid $purple4 !important; }
.border-top-blue1 { border-top: 1px solid $blue1 !important; }
.border-top-blue2 { border-top: 1px solid $blue2 !important; }
.border-top-blue3 { border-top: 1px solid $blue3 !important; }
.border-top-pink1 { border-top: 1px solid $pink1 !important; }
.border-top-pink2 { border-top: 1px solid $pink2 !important; }

.border-bottom-info { border-bottom: 1px solid $info !important; }
.border-bottom-blue3 { border-bottom: 1px solid $blue3 !important; }
.border-bottom-blue4 { border-bottom: 1px solid $blue4 !important; }
.border-bottom-for-dark3 { border-bottom: 1px solid $border-for-dark3 !important; }
.border-bottom-for-dark4 { border-bottom: 1px solid $dark4 !important; }
.border-bottom-dark { border-bottom: 1px solid $dark !important; }
.border-bottom-dark-50 { border-bottom: 1px solid $dark-50 !important; }
.border-bottom-muted { border-bottom: 1px solid $muted !important; }
.border-bottom-dark-theme-green { border-bottom: 1px solid $dark-theme-green !important; }
.border-bottom-dark-theme-blue { border-bottom: 1px solid $dark-theme-blue !important; }
.border-bottom-dark-theme-purple { border-bottom: 1px solid $dark-theme-purple !important; }
.border-bottom-dark-theme-orange { border-bottom: 1px solid $dark-theme-orange !important; }
.border-bottom-dark-theme-red { border-bottom: 1px solid $dark-theme-red !important; }
.border-bottom-black1 { border-bottom: 1px solid $black1 !important; }
.border-bottom-black2 { border-bottom: 1px solid $black2 !important; }
.border-bottom-black3 { border-bottom: 1px solid $black3 !important; }
.border-bottom-black4 { border-bottom: 1px solid $black4 !important; }
.border-bottom-black5 { border-bottom: 1px solid $black5 !important; }
.border-bottom-black6 { border-bottom: 1px solid $black6 !important; }
.border-bottom-black7 { border-bottom: 1px solid $black7 !important; }
.border-bottom-black8 { border-bottom: 1px solid $black8 !important; }
.border-bottom-black9 { border-bottom: 1px solid $black9 !important; }
.border-bottom-white1 { border-bottom: 1px solid $white1 !important; }
.border-bottom-white2 { border-bottom: 1px solid $white2 !important; }
.border-bottom-white3 { border-bottom: 1px solid $white3 !important; }
.border-bottom-white4 { border-bottom: 1px solid $white4 !important; }
.border-bottom-white5 { border-bottom: 1px solid $white5 !important; }
.border-bottom-gray1 { border-bottom: 1px solid $gray1 !important; }
.border-bottom-gray2 { border-bottom: 1px solid $gray2 !important; }
.border-bottom-gray3 { border-bottom: 1px solid $gray3 !important; }
.border-bottom-gray4 { border-bottom: 1px solid $gray4 !important; }
.border-bottom-gray5 { border-bottom: 1px solid $gray5 !important; }
.border-bottom-gray6 { border-bottom: 1px solid $gray6 !important; }
.border-bottom-gray7 { border-bottom: 1px solid $gray7 !important; }
.border-bottom-purplishRed { border-bottom: 1px solid $purplishRed !important; }
.border-bottom-purple3 { border-bottom: 1px solid $purple3 !important; }
.border-bottom-purple4 { border-bottom: 1px solid $purple4 !important; }
.border-bottom-blue1 { border-bottom: 1px solid $blue1 !important; }
.border-bottom-blue2 { border-bottom: 1px solid $blue2 !important; }
.border-bottom-blue3 { border-bottom: 1px solid $blue3 !important; }
.border-bottom-pink1 { border-bottom: 1px solid $pink1 !important; }
.border-bottom-pink2 { border-bottom: 1px solid $pink2 !important; }


.fontFamily {
      font-family: 'Muli', sans-serif !important;
}
.overflow-x-auto {
      overflow-x: auto !important;
}
.overflow-x-hidden {
      overflow-x: hidden !important;
}
.overflow-unset {
      overflow: unset !important;
}

.apexcharts-text, .apexcharts-text tspan {
      font-family: 'Muli',
      sans-serif !important;
}



//assetBoard
.bgGray {
      background-color: #e9ecef !important
}

.multiArea .apexcharts-legend {
      margin-top: 5px
}

.multiArea .apexcharts-canvas.apexcharts-zoomable .hovering-zoom {
      cursor: pointer !important
}

// .multiArea .apexcharts-xaxis {
//       transform: translate(0%,-1%) !important;
// }


.zIndex1 {
      z-index: 1 !important;
}
.z994 {
      z-index: 993 !important;
}
.z994 {
      z-index: 994 !important;
}
.z995 {
      z-index: 995 !important;
}
.z996 {
      z-index: 996 !important;
}
.z997 {
      z-index: 997 !important;
}
.z998 {
      z-index: 998 !important;
}
.z1000 {
      z-index: 1000 !important;
}
.z99999 {
      z-index: 99999 !important;
}

.marginTop100 {
      margin-top: 105px;
}

.assetDropdown {
      color: #000;      
      .css-48ayfv {
         background: transparent;
         color: #FFF;
      }
      .css-1r4vtzz {
            background: transparent;
            color: #FFF;
      }
      .css-1pcexqc-container {
            max-width: 100%;
            background-color: #FFF !important;
            border: 1px solid gray;
            position: absolute;
            min-width: 100%;
      }

      div>div {
            margin-top: 0px !important
      }
      
      .css-1jllj6i-control,
      .css-6y1x9t-control,
      .css-1qprcsu-option {
            max-width: 100% !important;
            min-width: 90% !important;
            width: auto !important;
            margin-left: 5px !important;
            margin-right: 5px !important;
            //margin-bottom:10px;      
      }
      
      .css-11unzgr {
            max-height: 200px !important;
            padding-left: 4px;
      }
}

.custom-dropdown {
      font-size: 11px !important;
      z-index: 9999;
      position: relative;
      width: 100%;
      max-width: 100% !important;

      button {
            padding: 0px;
            font-size: 12px;
            box-shadow: none;
            width: 100%
      }
      .css-1gpjby2 {
            display: none !important;
      }

      //content dropdown section
      .css-11unzgr {
            max-height: 200px !important;
            //min-width:160px;
      }
      
      .css-1v99tuv {
            font-size: 14px !important;
            padding: 0.375rem 0rem;
      }

      .css-1jllj6i-control,
      .css-6y1x9t-control,
      .css-1qprcsu-option {
            max-width: 100% !important;
            min-width: 100px !important;
            width: auto !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
            //margin-bottom:10px;      
      }

      .css-1pcexqc-container {
            max-width: 100%;
      }

      .css-1vr111p-option {
            padding: 0px 0px 5px 0px !important;
            max-width: 100% !important;   
            min-width: 100px !important;
            width: auto !important;
      }

      .css-1vr111p-option:hover {
            background-color: transparent !important;
      }

      .css-1qprcsu-option {
            background-color: transparent !important;
            padding: 0px 0px 5px 0px !important;
      }
}
   
.custom-dropdown>div>div {
      position: relative !important;
      min-width: 100% !important;
}
   
// .custom-dropdown .css-1jllj6i-control, .css-6y1x9t-control, .css-1qprcsu-option{
//    max-width: 100% !important;
//    min-width: 100px !important;
//    width: auto !important;
//    margin-left: 0px !important;
//    margin-right: 0px !important;
// }

.custom-dropdown-dark-theme {
      font-size: 11px !important;
      z-index: 9999;
      position: relative;
      width: 100%;
      max-width: 100% !important;
      color: #000 !important;

      //content dropdown section
      .css-11unzgr {
            max-height: 200px !important;
      }
      
      .css-1v99tuv {
            font-size: 14px !important;
            padding: 0.375rem 0rem;
            background-color: #2D323E;
            color: $white4;
      } 

      button {
            padding: 0px;
            font-size: 12px;
            box-shadow: none;
            width: 100%
      }
      .css-1gpjby2 {
         display: none !important;
      }

      .css-1jllj6i-control,
      .css-6y1x9t-control,
      .css-1qprcsu-option {
            max-width: 100% !important;
            min-width: 100px !important;
            width: auto !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
      }

      .css-1pcexqc-container {
            max-width: 100%;
      }

      .css-1vr111p-option {
            padding: 0px 0px 5px 5px !important;
            max-width: 100% !important;   
            min-width: 100px !important;
            width: auto !important;
      }

      .css-1vr111p-option:hover {
            background-color: transparent !important;
      }

      .css-1qprcsu-option {
            background-color: transparent !important;
            padding: 0px 0px 5px 5px !important;
      }
}
   
.custom-dropdown-dark-theme>div>div {
      position: relative !important;
      min-width: 100% !important;
}

  
.right-5 {
      right: 5px !important;
}  
.right-10 {
      right: 10px !important;
} 

.legend-circle {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: #4A397C;
}

.legend-circle-critical {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: $critical;
}

.legend-circle-high {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: $high;
}

.legend-circle-medium {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: $medium;
}

.legend-circle-low {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: $low;
}

.legend-circle-gray4 {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: $gray4;
}

.stock-legend {
	margin-top: 0px
}

.stock-legend>li {
	display: inline-block;
	margin-right: 10px;
	margin-top: 0px
}

.table-div {
	width: 100%;
	display: block;
	overflow: hidden;
	border-radius: 8px;
}

.table-div>div {
	float: left;
	height: 8px;
}

.table-div>div:first-child {
	background-color: #4A397C;
}

.table-div>div:nth-child(2) {
	background-color: #6E6096;
}

.table-div>div:nth-child(3) {
	background-color: #08B089;
}

.table-div>div:nth-child(4) {
	background-color: #10ddad;
}

.table-div>div:nth-child(5) {
	background-color: #2F2A40;
}

.createdBar-div {
	width: 100%;
	display: block;
	overflow: hidden;
	border-radius: 8px;
}

.createdBar-div>div {
	float: left;
	height: 8px;
}

.createdBar-div>div:first-child {
	background-color: #08B089;
}

.createdBar-div>div:nth-child(2) {
      background-color: #4A397C;
}

.createdBar-div>div:nth-child(3) {
      background-color: #6E6096;
}

.createdBar-div>div:nth-child(4) {
	background-color: #10ddad;
}

.createdBar-div>div:nth-child(5) {
	background-color: #2F2A40;
}


.aiopsStatusDiv {
	width: 100%;
	display: block;
	overflow: hidden;
	border-radius: 8px;
}

.aiopsStatusDiv>div {
	float: left;
	height: 8px;
}

.aiopsStatus-success {
      background-color: $success !important;
}
.aiopsStatus-danger {
      background-color: $danger !important;
}
.aiopsStatus-warning {
      background-color: $warning !important;
}


.innerMultiDrop {
      font-size: 11px !important;
}

//content dropdown section
.innerMultiDrop .css-11unzgr {
      max-height: 200px !important;
}

.innerMultiDrop {
      z-index: 9;
      position: relative;
      width: 100%;
      max-width: 100% !important;

      button {
            width: 100%;
            border: none;
            display: table;
            background-color: #E5E4E5 !important;
            padding: 5px;
            height: 26px;
            font-size: 12px;
            font-weight: bold;
            border-radius: 3px;
            border-bottom: 1px solid #000;
            display: flex !important;
      }
}

.assetDetailsSection .borderRadius0 {
      border: none !important;
}

.assetDetailsSection .pagination {
      font-size: 9px !important;
}
   
// .innerMultiDrop .css-1gpjby2{
//       //display: none !important;
// }

.innerMultiDrop>div>div {
      position: relative !important;
      min-width: 100% !important;
}

.innerMultiDrop .css-1jllj6i-control,
.innerMultiDrop .css-6y1x9t-control,
.innerMultiDrop .css-1qprcsu-option {
      max-width: 100% !important;
      min-width: 90% !important;
      width: auto !important;
      margin: 5px !important;
      //margin-bottom:10px;      
}

.innerMultiDrop .css-1pcexqc-container {
      max-width: 100%;
      background-color: #E5E4E5 !important;
      position: absolute;
      min-width: 200px;
}

.innerMultiDrop .css-1vr111p-option input[type="checkbox"],
.innerMultiDrop .css-1qprcsu-option input[type="checkbox"] {
      vertical-align: middle !important;
}

.innerMultiDrop .css-1vr111p-option {
      padding: 5px !important;
      max-width: 100% !important;   
      min-width: 200px !important;
      width: auto !important;
}

.innerMultiDrop .css-1vr111p-option:hover {
      background-color: #E5E4E5 !important;
}
      
.innerMultiDrop .css-1qprcsu-option {
      background-color: #E5E4E5 !important;
      padding: 5px !important;
}

.innerMultiDrop .css-1jllj6i-control, 
.innerMultiDrop .css-6y1x9t-control {
      margin-left: 5px !important;
      margin-right: 5px !important;
      border-color:hsl(0,0%,80%);
      box-shadow: 0 0 0 1px #E5E4E5;
}
.innerMultiDrop .css-6y1x9t-control:hover {
      border-color: hsl(0,0%,80%);
      box-shadow: 0 0 0 1px #E5E4E5;
}

.regionPopOver .popover {
      width: auto;
      max-width: 50% !important;
      z-index: 99999 !important;
}

.governanceSizingPopover .popover {
      min-width: 25% !important;;
      max-width: 50% !important;
      min-height: 200px !important;
      overflow-y: hidden !important;
      z-index: 99999 !important;
}

.chDAccRegionPopOver .popover {
      background-color: $primary;
      width: auto;
      max-width: 50% !important;
      z-index: 99999 !important;
      max-height: 100% !important;
      // transform: translate3d(69px, 160px, 0px) !important;
}

.scorePopOver .popover {
      width: auto;
      max-width: 20% !important;
      z-index: 99999 !important;
      background-color: $dark2 !important;
      color: $white1;    
}

.bg-transparent {
      background-color: transparent;
}

.borderMainSection {
      border-bottom: 10px solid #969aa7;
}

.borderSubSection {
      border-bottom: 5px solid #969aa7;
}

.form-control-small {
    height: calc(1.0em + 0.75rem + 2px);
    padding: 0.25rem 0.5rem;
    //padding-right:0;padding-left:0;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem
}

.closeIssue .modal-title {
      font-size: 18px !important;
}

.modalPopup button {
      width: 100%;
      border: none;
      display: table;
      background-color: #FFF !important;
      border-radius: 3px;
      border: 1px solid #ced4da;
      height: calc(1.0em + 0.75rem + 2px);
      padding: 0.25rem 0.5rem;
      color: #676D73;
      font-size: 0.875rem;
      display: flex !important;
}

.modalPopup .css-1pcexqc-container {
      max-width: 100%;
      background-color: #FFF !important;
      position: absolute;
      min-width: 100%;
      border: 1px solid #ced4da;
      margin-top: -8px;
}

.modalPopup .css-1qprcsu-option {
      max-width: 100% !important;
      min-width: 200px !important;
      width: auto !important;
      margin-left: 0px !important;
      background-color: #FFF !important;
}

.modalPopup .css-1qprcsu-option:nth-child(1),
.modalPopup .css-1vr111p-option:nth-child(1) {
      font-size: 13px;
      font-weight: bold;
}

.modalPopup .css-1vr111p-option:hover {
      background-color: transparent !important;
      border: none !important
}

.modalPopup svg {
      width: 32px;
      height: 32px;
      margin-right: -18px !important;
}

.borderGray {
      border: 1px solid #ced4da;
}

// .assetHeatMap .react-calendar-heatmap {
//       width: 70%;
//       height: 200px;
// }

.assetHeatMap .react-Calendar-month-name {
      font-size: 16px;
      color: #434A5B;
}

.assetHeatMap .w-25 .react-calendar-heatmap-month-labels,
.assetHeatMap .w-30 .react-calendar-heatmap-month-labels {
      transform: translate(40%, 0%);
}

.assetHeatMap .w-55 .react-calendar-heatmap-month-labels {
      transform: translate(25%, 0%);
}

.assetHeatMap .w-70 .react-calendar-heatmap-month-labels {
      transform: translate(15%, 0%);
}

.assetHeatMap .react-calendar-heatmap text {
      fill: #434A5B !important;
}

.modal-header .close {
      color: #000;
}

.modal-header .close:hover {
      color: #000;
}

.text-fill-empty {
      fill: #D4D4D4;
      background-color: #D4D4D4;
}

.text-fill-color-1 {
      fill: #8074A3;
      background-color: #8074A3;
}

.text-fill-color-2 {
      fill: #C6BDE0;
      background-color: #C6BDE0;
}

.text-fill-color-3 {
      fill: #08B089;
      background-color: #08B089;
}

.text-fill-color-4 {
      fill: #D2F0E9;
      background-color: #D2F0E9;
}

.assetHeatMapLegend {
      // position:absolute;
      // bottom:20px;
      // right: 5px;
      // background: #D3D3D3;
      // border-radius: 10px;
      // display: block;
      // padding: 10px;
      // color: #6E6E6E;
      // font-family:sans-serif;
      // text-align:center;
      // width:160px;
      // border:1px solid black;
      width: fit-content;
      margin: auto;
}

.assetHeatMapLegend ul {
      padding: 0;
      margin: 0;
      display: inline-flex;
}

.assetHeatMapLegend li {
      font-family: sans-serif;
      font-size: 0.8em;
      list-style-type: none;
      margin-top: 15px;
      vertical-align: middle;
      padding-left: 10px;
}

.assetHeatMapBox {
    position: relative;
    //border-radius: 50%;
    width: 15px;
    height: 15px; 
    float: left;
    margin-right: 5px;
}

.videoUrl .modal-content {
      width: fit-content;
}

.is-valid .valid-feedback {
      display: block
}

.userDropOptions {
      color: #212529;
      text-decoration: none !important;
}

.userDropOptions:hover {
      color: #212529;
      text-decoration: none !important;
}

.accountTypepadding {
      padding-top: 0.25%
}

.accountInstruction li { 
      margin: 10px 0px;
      color: #3C4253
}

.infoPopup {
      max-width: 80%;
      max-height: 70%
}

.accountList .img-fluid {
      max-width: 10%;
}

.passBtn {
      background-color: #06CB9E !important;
      padding: 0.2rem 1.2rem;
      border-radius: 0.2rem;
      font-size: 1.0rem;
      line-height: 1.5;
      font-weight: 600;
      background-position: center;
      color:#FFF
      /* display: inline-block; */
      /* color: #212529; */
      /* text-align: center; */
      /* vertical-align: middle; */
      // line-height: 1.5;
}

.failBtn {
      background-color: $dark-theme-red  !important;
      padding: 0.2rem 1.2rem;
      border-radius: 0.2rem;
      font-size: 1.0rem;
      line-height: 1.5;
      font-weight: 600;
      background-position: center;
      color:#FFF
}

//asset score slider start
.input-range {
      width: 85% !important;
      margin: 0.875rem 0.5rem 0 0.7rem;
}
.input-range__label {
      font-size: 0.7rem !important;
      color: $white6 !important;
}
.input-range__label--min, .input-range__label--max {
      // display :none !important;
}

.input-range__slider {
      // background: #039BE5 !important;
      // border: 1px solid #039BE5 !important;
}

.input-range__track--active {      
      // background: #039BE5 !important;
}

.input-range__label {
      font-family: 'Muli',
      sans-serif !important;
}

//asset score slider end

.optGroup {
      font-weight: bold
}

.textCtrl option.optGroupChild {
      padding-left: 25px;
}

.providerDonut .apexcharts-legend-text {
      color: #fff !important;
}

// .providerDonut .apexcharts-legend {
//       min-width:100px;
//       max-width:100px;
// }

.width75 {
      width: 75px !important;
}

.heihgtFitContent {
      height: fit-content !important;
}

.minheightAccCD {
      min-height: 170px !important;
}

// .minmaxheightServiceCD {
//       max-height: 345px !important;
//       overflow: hidden;
// }

.chDServiceWise .css-1r4vtzz, 
.chDServiceWise .css-48ayfv {
      background-color: #ececec !important;
      border: #ececec !important;
      padding: 4px 4px 0px 5px;
}

.chDServiceWise .css-1pcexqc-container {
      background-color: #ececec !important;
      border: #ececec !important;
}

.chDServiceWise .css-1jllj6i-control, 
.chDServiceWise .css-6y1x9t-control {
      margin: 0px;
      border-color:hsl(0,0%,80%);
      box-shadow: 0 0 0 1px #fff;
}
.chDServiceWise .css-6y1x9t-control:hover {
      border-color: hsl(0,0%,80%);
      box-shadow: 0 0 0 1px #fff;
}
/* Handle */
// ::-webkit-scrollbar-thumb {
//       background: #A8A7A7;
// }



.apexcharts-tooltip {
      position: absolute !important;
      z-index: 999999999999999999 !important;
}

.ComDetailSmallBarChart .apexcharts-tooltip {
      left: 0 !important;
}

.advancedNplMultiSelect {
      .css-1r4vtzz, 
      .css-48ayfv {
            background-color: #fff !important;
            border: 1px solid #ced4da;
            //padding: 4px 4px 0px 5px;
            width: 100% !important;
      }

      .css-1pcexqc-container {
            background-color: #fff !important;
            border: #fff !important;
      }

      .css-1jllj6i-control, 
      .css-6y1x9t-control {
            margin: 0px;
            border-color:hsl(0,0%,80%);
            box-shadow: 0 0 0 1px #fff;
      }
      .css-6y1x9t-control:hover {
            border-color: hsl(0,0%,80%);
            box-shadow: 0 0 0 1px #fff;
      }
}


//filter section multideopdown start
.filterMultiSelectDropdown {
      color: #000;
      font-size: 13px !important;
      z-index: 1;
      position: relative;
      width: 100%;
      max-width: 100% !important;

      .css-1v99tuv {
            font-size: 13px !important;
            padding: 0.375rem 0rem;
      }

      button {
            padding: 0px;
            font-size: 12px;
            box-shadow: none;
            width: 100%
      }
      
      .css-1gpjby2 {
            display: none !important;
      }

      .css-48ayfv {
            background: transparent;
            color: #FFF;
      }
      
      .css-1r4vtzz {
            background: transparent;
            color: #FFF;
      }
      
      .css-1pcexqc-container {
            //max-width: 100%;
            background-color: #FFF !important;
            border: 1px solid gray;
            position: absolute;
            min-width: 100%;
            //margin-top: -8px !important;
      }

      .css-11unzgr {
            max-height: 200px !important;
            padding-left: 4px;
      }
      
      //checkbox section
      .css-1vr111p-option input[type="checkbox"],
      .css-1qprcsu-option input[type="checkbox"] {
            vertical-align: middle !important;
      }
      
      .css-1qprcsu-option,
      .css-6y1x9t-control,
      .css-1vr111p-option {
            padding: 0px 0px 5px 0px !important;
            width: auto !important;
            margin-left: 0px !important;
            margin-right: 5px !important;
      }

      .css-1jllj6i-control {
            max-width: 100% !important;
            min-width: 90% !important;
            width: auto !important;
            margin-left: 5px !important;
            margin-right: 5px !important;
      }
      
      .css-1jllj6i-control, 
      .css-6y1x9t-control {
            margin-left: 5px !important;
            margin-right: 5px !important;
            border-color:hsl(0,0%,80%);
            box-shadow: 0 0 0 1px #fff;
      }

      .css-6y1x9t-control:hover {
            border-color: hsl(0,0%,80%);
            box-shadow: 0 0 0 1px #fff;
      }
}

.filterMultiSelectDropdown>div>div {
      position: relative !important;
      min-width: 100% !important;
      margin-top:0px !important;
}





//filter section multideopdown end

.operationalBoxSection .card-body{
      min-height: 200px
}

.colorBlack .css-yk16xz-control, .colorBlack .css-1pahdxg-control {
      cursor: pointer !important;
}

.reactSelectDrop {
      .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
            display: none;
      }
      .css-4ljt47-MenuList {
            padding-bottom: 0px !important;
            padding-top: 0px !important;
      }
      .css-4ljt47-MenuList>div {
            background-color: #fff;
            color: #000;
            padding: 3px 12px;
      }
      .css-4ljt47-MenuList>div:hover {
            background-color: $hvoerInfo;
            color: #000;
            padding: 3px 12px;
      }
}

.reactSelectBasicClass {
      .css-1pahdxg-control, .css-1pahdxg-control:hover {
            border-color: hsl(0,0%,80%) !important;
            box-shadow: none;
      }      
}

.serviceLogs {
      .minWidthSelect {
            .css-yk16xz-control {
                  min-width: 200px;
            }
      }
}

.reactSelectSmallDrop {

      .css-26l3qy-menu {
            top: 18px;
            //margin-top:0px;
      }
      
      .css-yk16xz-control,
      .css-1pahdxg-control {
            background-color: #E5E4E5;
            border-color: transparent;
            min-height: 28px;
            max-height: 28px;
            border-width: 0px;
            box-shadow: none;
      }

      .css-1pahdxg-control:hover {
            border-color: transparent !important;
            box-shadow: none;
      }

      .css-g1d714-ValueContainer {
            padding: 0px 5px;
            height: 28px;
      }

      .css-1uccc91-singleValue,
      .css-b8ldur-Input,
      .css-1hb7zxy-IndicatorsContainer {
            color: #495057;
            height: 28px;
            top: 20px;
      }

      .css-b8ldur-Input {
            height: 28px;
      }

      .css-1okebmr-indicatorSeparator {
            display: none;
      }

      .css-yk16xz-control:hover {
            border-color: transparent;
      }
      
      .css-2613qy-menu {
            font-size: 12px;
      }

      .css-4ljt47-MenuList>div:hover {
            background-color: #E5E4E5 !important;
            color: #495057;
            padding: 3px 12px;
      }

      .css-4ljt47-MenuList>div  {
            background-color: #fff;
            color: #495057;
            padding: 3px 12px;
      }

      .css-4ljt47-MenuLis  {
            padding-bottom: 0px !important;
            padding-top: 0px !important;
      }
}
.fillStar {
      color: orange;
}
    
/* bootstrap-demo is a custom classname to increases the specificity of our styles. It can be anything. 
* The idea is that it is easy to extend/override builtin styles with very little effort.
*/
.bootstrap-demo .dropdown-trigger {
border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
font-size: 12px;
color: #555;
}

.bootstrap-demo .toggle {
font: normal normal normal 12px/1 FontAwesome;
color: #555;
}

// .bootstrap-demo .toggle.collapsed::after {
// content: "\f067";
// }

// .bootstrap-demo .toggle.expanded::after {
// content: "\f068";
// }

.bootstrap-demo .root {
      padding: 0px;
      margin: 0px;
}

.serviceFilterDropdown {
      // height: 60px;
      // margin-top: -30px !important;
      padding-top: 20px;
      margin-left: -6px;
}

.serviceFilterDropdown {
      min-width: 200px !important;
      height: 60px !important;
}

// .serviceFilterDropdown .dropdown .dropdown-content {
//       margin-top: 20px;
// }

// .serviceTreeDropdown .tag-item .search:after {
//       content: "All";
// }

.filterLightLabel {
      font-size: 12px !important;
      color: #fff;
}

.serviceTreeDropdownDark .tag-item .search  {
      color: #515151 !important;
      margin-top: -10px !important;
      padding-top: 0px !important;
      height: 30px !important;
}

.serviceTreeDropdownDark .dropdown-content {
      margin-top: 15px;
      min-width: 250px !important;
      max-height: 300px !important;
      overflow: scroll;
      z-index: 99999 !important;
}

.filterDarkLabel {
      font-size: 12px !important;
      color: #515151;
}

.hideDTSPlaceHolder .dropdown-content {
      margin-top: -5px !important;
}

.serviceTreeDropdown {
      .tag-item .search {
            border: none !important;
            color: #fff !important;
            font-size: 13px;
            cursor: pointer;
            min-width: 200px !important;
            height: 60px;
            margin-top: -35px !important;
            padding-top: 20px;
            margin-left: -6px;
            position: absolute;
            font-family: 'Muli', sans-serif !important;
      }
      .dropdown-content {
            margin-top: 15px;
            min-width: 190px !important;
            max-height: 300px !important;
            overflow: scroll;
            z-index: 99999 !important;
      }

      .tag-list li {
            display: none
      }
      
      .tag-list li:last-child {
            display: inline-block
      }
      
      .react-dropdown-tree-select .dropdown .dropdown-trigger {
            border: none
      }
      
      .react-dropdown-tree-select .dropdown .dropdown-trigger {
            padding: 0px !important;
            display: block !important;
      }
      .react-dropdown-tree-select .dropdown .dropdown-trigger:after {
          content: none !important;
      }
      
      input {
            // background-color: #434A5B !important;
            background-color: transparent !important;
            color: #fff !important;
      }

      .dropdown {
            // background-color: #434A5B !important;      
            background-color: transparent !important;
      }
      
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #FFF;
            opacity: 1; /* Firefox */
      }
}

.STDborderBottom {
      border-bottom: 1px dotted $gray3 !important;
}



.serviceTreeDropdownDark input {
      color: #515151 !important;
}



.serviceTreeDropdownDark ::placeholder {
      color: #515151 !important;
      font-size: 14px !important;
}
    
.textddd {
      height: 100px !important;
      max-height: 100px !important
}

.haderSelectedTab .active {
      border-color: #16191f;
      color: #16191f;
      background-color: transparent;
}

.serviceSizingInputRange .input-range__label {
      color: $black2 !important;
}

.borderMiddle {
      border-right: 1px solid red ;
      border-image: linear-gradient(to bottom, transparent 15%, #dee2e6  20%, #dee2e6  85%, transparent 80%);
      border-image-slice: 1;
}

.borderMiddleblack5 {
      border-right: 2px solid $black5 ;
      border-image: linear-gradient(to bottom, transparent 15%, $black5  20%, $black5  85%, transparent 80%);
      border-image-slice: 1;
}

.borderMiddleBlue {
      border-right: 2px solid #039BE5 ;
      border-image: linear-gradient(to bottom, transparent 15%, #039BE5  20%, #039BE5  65%, transparent 80%);
      border-image-slice: 1;
}

.borderMiddleBlue-sm {
      border-right: 2px solid #039BE5 ;
      border-image: linear-gradient(to bottom, transparent 15%, #039BE5  20%, #039BE5  65%, transparent 80%);
      border-image-slice: 1;
}

.borderMiddleBlue-lg {
      border-right: 2px solid #039BE5 ;
      border-image: linear-gradient(to bottom, transparent 0%, #039BE5 20%, #039BE5 80%, transparent 95%);
      border-image-slice: 1;
}

.borderBottomMiddleBlue {
      border-bottom: 2px solid #039BE5 ;
      border-image: linear-gradient(to left, transparent 15%, #039BE5  20%, #039BE5  80%, transparent 85%);
      border-image-slice: 1;
}

.borderBottomTopBlue {
      border-top: 2px solid #039BE5 ;
      border-image: linear-gradient(to left, transparent 15%, #039BE5  20%, #039BE5  85%, transparent 80%);
      border-image-slice: 1;
}


input[type="checkbox"]:disabled + label:before {
      border-color: #eee;
      color: #ccc;
      background: linear-gradient(to top, #e6e6e6 0px, #fff 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
}

.previewScreen .modal-title {
      font-size: 18px !important;
}

.previewScreen .modal-dialog {
      max-width: 80% !important
}

.governanceServiceMultiSelect {
      color: #000;
      font-size: 13px !important;
      z-index: 1;
      position: relative;
      //width: 100%;
      max-width: 100% !important;

      button {
            padding: 4px 9px;
            font-size: 12px;
            box-shadow: none;
            // width: 100%
      }
      
      .css-1v99tuv {
            font-size: 13px !important;
            padding: 0.375rem 0rem;
      }
      
      .iv>div {
         position: relative !important;
         min-width: 100% !important;
         margin-top: 0px !important;
      }
      
      .css-1r4vtzz,
      .css-48ayfv {
            border: 1px solid hsl(0,0%,80%) !important;
            border-radius: 5px;
            min-width: 300px !important;
            max-width: 100% !important;      
      }

      .css-1pcexqc-container {
            min-width: 300px !important;
            max-width: 100% !important;
            background-color: #FFF !important;
            // position: absolute;
            border: 1px solid hsl(0,0%,80%) !important;
      }
      
      .css-11unzgr {
            max-height: 200px !important;
            padding-left: 4px;
      }
      
      //checkbox section
      .css-1vr111p-option input[type="checkbox"],
      .css-1qprcsu-option input[type="checkbox"] {
            vertical-align: middle !important;
      }
      
      .css-1jllj6i-control {
            max-width: 100% !important;
            min-width: 90% !important;
            width: auto !important;
            margin-left: 5px !important;
            margin-right: 5px !important;
      }
      
      .css-1jllj6i-control, 
      .css-6y1x9t-control {
            margin-left: 5px !important;
            margin-right: 5px !important;
            border-color:hsl(0,0%,80%);
            box-shadow: 0 0 0 1px #fff;
      }
      .css-6y1x9t-control:hover {
            border-color: hsl(0,0%,80%);
            box-shadow: 0 0 0 1px #fff;
      }
}


.maxWidth .css-1r4vtzz,
.maxWidth .css-48ayfv {
      border: 1px solid hsl(0,0%,80%) !important;
      border-radius: 5px;
      min-width: 100% !important;
      max-width: 100% !important;      
}

.biggerDropdown .css-1r4vtzz,
.biggerDropdown .css-48ayfv {
      min-width: 400px !important;
      max-width: 100% !important;      
}

.disabledBg .css-1r4vtzz, 
.disabledBg .css-yk16xz-control {
      background-color: #E0E0E0 !important;
}

.disabledDarkBg {
      .css-1r4vtzz, 
      .css-yk16xz-control {
            background-color: $gray5 !important;
      }
      .css-1gpjby2 {
            display: none !important;
      }
}


.maxWidth .css-1pcexqc-container {
      min-width: 100% !important;
      max-width: 100% !important;    
}

.pageTopnav {
      margin-bottom: 1rem;
      border-bottom: 2px solid #F4F0FA !important;
      width: 100%;
      .nav-link {
            margin: 0 !important;
            color:#6c757d !important;
            // padding: 1rem !important;
            padding: 0.5rem 1rem !important;
            min-width: 75px;            
      }

      .nav-link:hover {
            background-color: #F4F0FA !important
      }

      .active {
            color: #6200EE !important;
            background-color: #F4F0FA !important;
            border-bottom: 2px solid #6200EE !important;
      }
}

.fileDistribution {
      border: 2px solid #F4F0FA;
      width: 80%;
      min-height:100px;
}

.mt1_25rem {
      margin-top: -1.25rem !important
}

.mt2rem {
      margin-top: 2rem !important
}

.smallLabel {
      font-size: 12px !important;
      padding: 0.375rem 0rem;
      // font-family: 'Muli', sans-serif !important;
      color: #515151 !important;
}

.dropdownMenu {
      // position: absolute;
      top: 100%;
      left: 0;
      z-index: 99999;
      float: left;
      min-width: 100%;
      padding: 0.5rem 0 0.25rem 0;
      margin: 0.125rem 0 0;
      font-size: 12px;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem;

      .dropdownItem {
            display: block;
            width: 100%;
            padding: 0.25rem 1.5rem;
            clear: both;
            font-weight: 400;
            color: #212529;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
      }

      .customControl {
            // position: relative;
            display: block;
            min-height: 0.5rem;
            padding-left: 0.5rem;
      }

      .custom-control-label::before, 
      .custom-control-label::after {
            top: 0.2rem;
            width: 1rem;
            height: 1rem;
            border-radius: 3px;
            box-sizing: border-box;
            border-color: initial;
            border: 1px solid rgba(0, 0, 0, 0.30);
      }
}

.insideDropdown {
      .css-1v99tuv { 
            display: none !important;
      }
}

.modalDelete {
      button {
            background-color: #4CAF50;
            color: white;
            padding: 14px 20px;
            margin: 8px 0;
            border: none;
            cursor: pointer;
            width: 100%;
            opacity: 0.9;
      }
          
      button:hover {
            opacity:1;
      }
          
          /* Float cancel and delete buttons and add an equal width */
      .cancelbtn, .deletebtn {
            float: left;
            width: 50%;
      }
          
          /* Add a color to the cancel button */
      .cancelbtn {
            background-color: #ccc;
            color: black;
      }
          
          /* Add a color to the delete button */
      .deletebtn {
            background-color: $dark-theme-red;
      }
          
          /* The Modal (background) */
      .modal {
            display: block;
            position: fixed; /* Stay in place */
            z-index: 1; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: transparent;
            padding-top: 50px;
            margin: auto;
      }
          
          /* Modal Content/Box */
      .modal-content {
            background-color: #fefefe;
            margin: 10% auto 10% auto; /* 5% from the top, 15% from the bottom and centered */
            border: 1px solid #888;
            width: 50%; /* Could be more or less, depending on screen size */
            height:250px;
      }
           
          /* The Modal Close Button (x) */
      .close {
            position: absolute;
            right: 35px;
            top: 15px;
            font-size: 40px;
            font-weight: bold;
            color: #f1f1f1;
      }
          
      .close:hover,
      .close:focus {
            color: $dark-theme-red;
            cursor: pointer;
      }
          
          /* Clear floats */
      .clearfix::after {
            content: "";
            clear: both;
            display: table;
      }
          
          /* Change styles for cancel button and delete button on extra small screens */
      @media screen and (max-width: 300px) {
            .cancelbtn, .deletebtn {
                  width: 50%;
            }
      }
}

.popover {
      z-index: 999999999999 !important;
}

.showSizingFilter { 
      display: block !important;
}

#d3-graph-tooltip-template {
      .tooltip{
            // background-color: $black5 !important;
            // border: 1px solid;
            border: 1px solid #000;
            background: rgba(0, 0, 0, 0.8);
            border-radius: 5px;
            color: #fff !important;
            padding: 5px;
      }
}

// #graph-id-graph-wrapper #graph-id-graph-container-zoomable {
//       transform: translate(0,0) !important;
// }

//topology 
.tooltip-circle-critical {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: $critical;
}

.tooltip-circle-high {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: $high;
}

.tooltip-circle-medium {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: $medium;
}

.tooltip-circle-low {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	margin-top: 5px;
	background-color: $low;
}


// .multiSelectTopologyWidth {
//       color: #000;
//       font-size: 13px !important;
//       z-index: 1;
//       position: relative;
//       //width: 100%;
//       max-width: 100% !important;
   
//       button {
//          padding: 4px 9px;
//          font-size: 12px;
//          box-shadow: none;
//          // width: 100%
//       }

//       .css-1v99tuv {
//             font-size: 13px !important;
//             padding: 0.375rem 0rem;
//             min-width: 155px !important;
//       }
//       div>div {
//          position: relative !important;
//          min-width: 100% !important;
//          margin-top: 0px !important;
//       }
//       .css-1r4vtzz, 
//       .css-48ayfv {
//             border: 1px solid hsl(0,0%,80%) !important;
//             border-radius: 5px;
//             min-width: 200px !important;
//             max-width: 100% !important;      
//       }
      
//       .css-1pcexqc-container {
//             min-width: 200px !important;
//             max-width: 100% !important;
//             background-color: #FFF !important;
//             position: absolute !important;
//             border: 1px solid hsl(0,0%,80%) !important;
//       }
      
//       .css-11unzgr {
//             max-height: 200px !important;
//             padding-left: 4px;
//       }
//       //checkbox section
//       .css-1vr111p-option input[type="checkbox"],
//       .css-1qprcsu-option input[type="checkbox"] {
//             vertical-align: middle !important;
//       }
//       .css-1jllj6i-control {
//             max-width: 100% !important;
//             min-width: 90% !important;
//             width: auto !important;
//             margin-left: 5px !important;
//             margin-right: 5px !important;
//             border-color:hsl(0,0%,80%);
//             box-shadow: 0 0 0 1px #fff
//       }
//       .css-6y1x9t-control {
//             margin-left: 5px !important;
//             margin-right: 5px !important;
//             border-color:hsl(0,0%,80%);
//             box-shadow: 0 0 0 1px #fff;
//       }
//       .css-6y1x9t-control:hover {
//             border-color: hsl(0,0%,80%);
//             box-shadow: 0 0 0 1px #fff;
//       }
// }

//topology


.multi-select-dark-theme {
      color: $muted;
      font-size: 13px !important;
      z-index: 1;
      position: relative;
      //width: 100%;
      max-width: 100% !important;
   
      button {
         padding: 4px 9px;
         font-size: 12px;
         box-shadow: none;
         background-color: $muted;
         color: $white4
         // width: 100%
      }

      .css-1v99tuv {
            font-size: 13px !important;
            padding: 0.375rem 0rem;
            min-width: 155px !important;
      }
      div>div {
         position: relative !important;
         min-width: 100% !important;
         margin-top: 0px !important;
      }
      .css-1r4vtzz, 
      .css-48ayfv {
            border: 1px solid $muted !important;
            border-radius: 5px;
            min-width: 200px !important;
            max-width: 100% !important;      
      }
      
      .css-1pcexqc-container {
            min-width: 200px !important;
            max-width: 100% !important;
            background-color: $gray3 !important;
            position: absolute !important;
            border: 1px solid $muted !important;
      }
      
      .css-11unzgr {
            max-height: 200px !important;
            padding-left: 4px;
      }
      //checkbox section
      .css-1vr111p-option input[type="checkbox"],
      .css-1qprcsu-option input[type="checkbox"] {
            vertical-align: middle !important;
      }
      .css-1jllj6i-control {
            max-width: 100% !important;
            min-width: 90% !important;
            width: auto !important;
            margin-left: 5px !important;
            margin-right: 5px !important;
            border-color: $muted;
            box-shadow: 0 0 0 1px $gray3
      }
      .css-6y1x9t-control {
            margin-left: 5px !important;
            margin-right: 5px !important;
            border-color: $muted;
            box-shadow: 0 0 0 1px $gray3;
      }
      .css-6y1x9t-control:hover {
            border-color: $muted;
            box-shadow: 0 0 0 1px $gray3;
      }
}


.serviceAvailWidth1 {
      width: 250px;
      margin-left: 15px
}
.serviceAvailWidth2 {
      width: 170px;
      margin-left: 15px
}



//common notification alert start

.notifications-component {
      z-index: 99999999 !important;
}

.notification-container--top-center {
      // max-width: 100% !important;
      min-width: 600px !important;
      left: calc(50% - 300px) !important;
      top: 10px !important;
      align-self: center ;
      justify-content: center !important;;
}

.notification__message {
      max-width: 100% !important;
      padding: 5px 0px !important; 
      color: #06CB9E !important;
      text-align: center;
}

.notification__close {
      top: 15px !important;
      background-color: #06CB9E !important;
}

.notification__item--danger {
      .notification__message {
            color: #FFF !important;
      }
      .notification__close {
            background-color: #F75E3F !important;
      }
}

.notification__item--success {
      background-color: #e0f5e5 !important;
      // border-left: 8px solid #06CB9E !important;
      border-left: none !important;
      font-weight: bold;
      border-radius: 5px !important;
}

//common notification alert end


.sizingTextboxPlaceHolder ::placeholder {
      font-size: 12px !important;
}

.bs-tooltip-auto {
      z-index: 99999999 !important
}

#wrapper {
      position: relative;
      // background: #000524;
      // border: 1px solid #000;
      box-shadow: 0 22px 35px -16px rgba(0, 0, 0, 0.71);
      max-width: 850px;
      margin: 0 auto;
}
    
#chart-bar {
      position: relative;
      margin-top: -38px;
}

    
#chart {
      max-width: 650px;
      margin: 35px auto;
}


.aiopsTopFiltersection {
      // .svg_select_points {
            // display: none !important;
            // stroke: #5f5c5c;
            // height: 15px;
            // y: 1;
            // cx: 2;
            // rx: 2;

            // stroke: #efefef;
            // height: 16px;
            // y: 0;
            // rx: 2;
            // ry: 12;
      // }

      .aiopsMetricsDatepicker {
            .form-control {
                  // height: calc(1.5em + 0.5rem + 2px);
                  padding: 3px 6px;
                  font-size: 12px;
                  // line-height: 1;
                  // border-radius: 5px;
                  border: 1px solid #95989E;
                  background-color: #fff !important;
                  border-radius: 0 !important;
                  height: calc(.75em + 0.75rem + 2px)
            }
      }
      
      .apexcharts-bar-area, .apexcharts-xaxis>line {
            stroke: #95989E !important;
      }
      
      .apexcharts-xaxis-label {
            font-size: 10px !important;
            // stroke: #00A3CB !important;
            // color: #00A3CB !important;
            fill: #95989E !important;
            font-weight: normal !important;
      }
}

.topologyRightSection {
   
      .comparisonTextboxDiv {
            display: flex;
            vertical-align: top;
            margin-left: 5px;
            flex-direction: column;
            position: relative;
      }

      .comparisonTextbox {
            border: 0 none;
            outline: 0 none;
            padding: 0;
            margin: 0;
            font-size: 13px;
            font-weight: bold;
            padding: 3px 0px 4px;
            min-width: 100%;
      }
}

.btn-small {
      padding: 0.1rem 0.75rem !important;
}

.recommendedPopup {
      background-color: #ECECEC !important;
      font-size: 14px !important;
      .popover {
            font-size: 14px !important;
            min-width: 300px !important
      }
      .popover-header {
            font-size: 16px !important;
            background-color: #91EDFD !important;
      }
      .popover-body {
            padding: 0px;
      }
}

.previousVersionPopup {
      background-color: #ECECEC !important;
      font-size: 14px !important;
      .popover {
            font-size: 14px !important;
            min-width: 150px !important;            
            min-height: 100px !important;
            max-height: 100% !important;
      }
      .popover-inner {
            min-height: 100px !important;
      }
      .popover-header {
            font-size: 16px !important;
            background-color: #91EDFD !important;
      }
      .popover-body {
            padding: 0px;
            min-height: 50px !important;
      }
}

// .democlass  {
      // width: 8;
      // height: 16 !important;
      // x: -4;
      // y: 0;
// }

.readOnlyTerminal {
      body {
            background-color: #272727;
            padding: 10px;
            color: #FFF;
      }
          
      .fakeButtons {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            border: 1px solid #000;
            position: relative;
            top: 6px;
            left: 6px;
            background-color: #ff3b47;
            border-color: #9d252b;
            display: inline-block;
      }
          
      .fakeMinimize {
            left: 11px;
            background-color: #ffc100;
            border-color: #9d802c;
      }
          
      .fakeZoom {
            left: 16px;
            background-color: #00d742;
            border-color: #049931;
      }
          
      .fakeMenu {
            // width: 550px;
            box-sizing: border-box;
            height: 25px;
            background-color: #bbb;
            margin: 0 auto;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
      }
          
      .fakeScreen {
            background-color: #151515;
            box-sizing: border-box;
            // width: 550px;
            margin: 0 auto;
            padding: 20px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            max-height: 400px;
            overflow-x: scroll
      }
          
      p {
            position: relative;
            // left: 50%;
            // margin-left: -8.5em;
            text-align: left;
            // font-size: 1.25em;
            font-size: 14px;
            // font-family: monospace;
            font-family: 'Muli', sans-serif;
            white-space: normal;
            overflow: hidden;
            // width: 100;
            // color: #fff !important;
      }
          
      span {
            color: #fff;
            font-weight: bold;
      }
          
      .line1 {
            color: #9CD9F0;
            -webkit-animation: type .5s 1s steps(20, end) forwards;
            -moz-animation: type .5s 1s steps(20, end) forwards;
            -o-animation: type .5s 1s steps(20, end) forwards;
            animation: type .5s 1s steps(20, end) forwards;
      }
          
      .cursor1 {
            -webkit-animation: blink 1s 2s 2 forwards;
            -moz-animation: blink 1s 2s 2 forwards;
            -o-animation: blink 1s 2s 2 forwards;
            animation: blink 1s 2s 2 forwards;
      }
          
      .line2 {
            color: #FFF;
            -webkit-animation: type .5s 4.25s steps(20, end) forwards;
            -moz-animation: type .5s 4.25s steps(20, end) forwards;
            -o-animation: type .5s 4.25s steps(20, end) forwards;
            animation: type .5s 4.25s steps(20, end) forwards;
      }
          
      .cursor2 {
            -webkit-animation: blink 1s 5.25s 2 forwards;
            -moz-animation: blink 1s 5.25s 2 forwards;
            -o-animation: blink 1s 5.25s 2 forwards;
            animation: blink 1s 5.25s 2 forwards;
      }
          
      .line3 {
            color: #E09690;
            -webkit-animation: type .5s 7.5s steps(20, end) forwards;
            -moz-animation: type .5s 7.5s steps(20, end) forwards;
            -o-animation: type .5s 7.5s steps(20, end) forwards;
            animation: type .5s 7.5s steps(20, end) forwards;
      }
          
      .cursor3 {
            -webkit-animation: blink 1s 8.5s 2 forwards;
            -moz-animation: blink 1s 8.5s 2 forwards;
            -o-animation: blink 1s 8.5s 2 forwards;
            animation: blink 1s 8.5s 2 forwards;
      }
          
      .line4 {
            color: #fff;
            -webkit-animation: type .5s 10.75s steps(20, end) forwards;
            -moz-animation: type .5s 10.75s steps(20, end) forwards;
            -o-animation: type .5s 10.75s steps(20, end) forwards;
            animation: type .5s 10.75s steps(20, end) forwards;
      }
          
      .cursor4 {
            -webkit-animation: blink 1s 11.5s infinite;
            -moz-animation: blink 1s 8.5s infinite;
            -o-animation: blink 1s 8.5s infinite;
            animation: blink 1s 8.5s infinite;
      }
          
      @-webkit-keyframes blink {
            0% {
              opacity: 0;
            }
            40% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            90% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
      }
          
      @-moz-keyframes blink {
            0% {
              opacity: 0;
            }
            40% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            90% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
      }
          
      @-o-keyframes blink {
            0% {
              opacity: 0;
            }
            40% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            90% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
      }
          
      @keyframes blink {
            0% {
              opacity: 0;
            }
            40% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            90% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
      }
}

.static-value{
	position:absolute;
	left:10px;
	font-weight:bold;
	font-size: 16px;
	// color:#444;
	top:7px;
}

.metricsDetailCurrentTooltipAlignment {
      .apexcharts-tooltip {
            top: 40px !important;
      }
      .apexcharts-tooltip {
            box-shadow: none !important;
      }
}

.metricsDetailCurrentTooltip {
      font-size: 12px !important;
      .apexcharts-tooltip-y-group {
            padding: 0 !important
      }
}

// .uncontrolledTooltip  {
//       .tooltip-inner {
//             background-color: #FFF !important;
//             color: red;
//       }

//       .tooltip .arrow, .tooltip-auto[x-placement^="top"] .arrow {
//             bottom: 0;
//       }
          
//       .tooltip .arrow::before, .tooltip-auto[x-placement^="top"] .arrow::before {
//             top: 0;
//             border-width: 0.4rem 0.4rem 0;
//             border-top-color: #fff;
//       }
// }

.remediationRightSectionModel {
      // @media (min-width: 1200px) {
      //       .modal-dialog {
      //             max-width: 800px;
      //             margin: 1.75rem auto;
      //       }
      // }
      .modal-content {
            width: 800px !important; 
      }
}

// .anomalyMetricsListGraph .apexcharts-marker {
      // cy: 10 !important;
      // x1: 0;
      // y1: 0;
      // x2: 0;
      // y2: 10;
      // stroke: #F75E3F !important;
      // // curve: butt !important;
      // r: 3 !important;
// }

.vh-border-left {
      height: 100vh !important;
      border-left:1px solid $gray4 !important
}
.vh-border-right {
      height: 100% !important;
      border-right:1px solid $gray4 !important
}
.vh-border-0 {
      height: 100% !important;
      border: none !important
}
.svg_select_points {
      stroke: #c7c7c7 !important;
      fill: #c7c7c7 !important;
}

   

.aiopsBadgeWidth {
      min-width: 56px !important;
}

.aiopsTableBadgeWidth {
      min-width: 20px !important;
}

.dropdown-divider-sm {
      height: 0;
      margin: 0.6rem 0;
      overflow: hidden;
      border-top: 1px solid #dee2e6;
}

.hiddenEllipses {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
}

.remediationListWidth {
   min-width: 250px !important;
   word-break: break-all;
}

.automationActionBox {
      min-width: 280px !important;
      word-break: break-all;
      position: relative;
      padding: 0.75rem 1.25rem;
      margin-bottom: 1rem;
      // border: 1px solid transparent;
      border-radius: 0.25rem
}

// div.vis-network div.vis-navigation div.vis-button.vis-up, 
// div.vis-network div.vis-navigation div.vis-button.vis-down, 
// div.vis-network div.vis-navigation div.vis-button.vis-left, 
// div.vis-network div.vis-navigation div.vis-button.vis-right, 
// div.vis-network div.vis-navigation div.vis-button.vis-zoomIn, 
// div.vis-network div.vis-navigation div.vis-button.vis-zoomOut, 
// div.vis-network div.vis-navigation div.vis-button.vis-zoomExtends {
//   background-image: none !important;
// }

// div.vis-network div.vis-navigation div.vis-button:hover {
//   box-shadow: none !important;
// }

// .vis-button:after {
//   font-size: 2em;
//   color: gray;
// }

// .vis-button.vis-up:after {
//   content: "▲";
// }

// .vis-button.vis-down:after {
//   content: "▼";
// }

// .vis-button.vis-left:after {
//   content: "◀";
// }

// .vis-button.vis-right:after {
//   content: "▶";
// }

// .vis-button.vis-zoomIn:after {
//   content: "+";
//   font-weight: bold;
// //   content: "\25AE";  /* this is your text. You can also use UTF-8 character codes as I do here */
// //       font-family: FontAwesome;
// //       left:-5px;
// //       position:absolute;
// //       top:0;
// }

// .vis-button.vis-zoomOut:after {
//   content: "−";
//   font-weight: bold;
// }

// .vis-button.vis-zoomExtends:after {
//   content: "⤧";
// }

.vis-network {
      overflow: visible !important;
}
.vis-edit-mode {
      display: none !important
}

.vis-tooltip {
    position: absolute;
    z-index: 99999;
    font-size: 12px !important;
    border: 1px solid #000;
    background: rgba(0, 0, 0, 0.8);
    color: #FFF;
}

.vis-navigation {      
      display: flex;
      justify-content: right;
      margin-top: -30px;
      position: relative;
      // top: 0;
      .vis-up, .vis-down, .vis-right, .vis-left {
            display: none !important
      }      

      .vis-button.vis-zoomIn {
            background-image: url("../images/plus.png");
            // bottom: 10px;
            // right: 15px;
            width: 30px;
            height: 30px;
            margin-right:5px;
            cursor: pointer;
            cursor: pointer;
      }

      .vis-button.vis-zoomOut {
            background-image: url("../images/minus.png");
            // bottom: 10px;
            // right: 55px;
            // right: 15px;
            width: 30px;
            height: 30px;
            margin-right:5px;
            cursor: pointer;
      }

      .vis-button.vis-zoomExtends {
            background-image: url("../images/zoom_reset.png");
            // bottom: 10px;
            // right: 55px;
            // right: 15px;
            width: 30px;
            height: 30px;
            cursor: pointer;
      }
}

.vis-network {overflow:visible;}
.vis-tooltip {
      position: absolute;
}
.topologyFullpageGraph {
      .vis-navigation {      
            display: list-item;
            // justify-content: right;
            // margin-top: -10px;            
            position: absolute;
            top: 40%;
            transform: translateY(-40%);

            .vis-button.vis-zoomIn {
                  background-image: url("../images/plus.png");
                  // bottom: 10px;
                  // right: 15px;
                  width: 30px;
                  height: 30px;
                  margin-bottom:5px;
                  // background-color: #2D323E;
                  // border-radius: 10px;
            }

            .vis-button.vis-zoomOut {
                  background-image: url("../images/minus.png");
                  // bottom: 10px;
                  // right: 55px;
                  // right: 15px;
                  width: 30px;
                  height: 30px;
                  margin-bottom:5px;
                  // background-color: #2D323E;
                  // border-radius: 10px;
            }

            .vis-button.vis-zoomExtends {
                  background-image: url("../images/zoom_reset.png");
                  // bottom: 10px;
                  // right: 55px;
                  // right: 15px;
                  width: 30px;
                  height: 30px;
                  // background-color: #2D323E;
                  // border-radius: 10px;
            }
      }
}

.tableProgressBar {
      .progress {
            height: 1rem !important;            
      }
}

#tooltip, #tooltipLabel {
      position: absolute;
      display: none;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 3px;
      box-shadow: none;
      color: #FFF !important;
      padding: 6px;
      min-width: 100;
      max-width: 300px ;
      z-index: 999999999;

      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
}

.d3tooltip {
      position: absolute;
      min-width: 60px;
      // max-width: 150px;
      pointer-events: none;
      // border: 1px solid $gray1;
      // background: $white1;
      color: #000;
      border: 1px solid #000;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 5px;
      color: #FFF;
      // box-shadow: 2px 2px 6px -4px #999;
      cursor: default;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      z-index: 12;
      transition: 0.15s ease all;

      .title {
            // background: $gray2;
            border-bottom: 1px solid #ddd;
            background: #000;
            // border: 1px solid #000;
            font-size: 14px;
            margin-bottom: 4px;
            padding: 2px 5px;
      }
      .content {
            padding: 2px 5px;
      }
      .marker {
            width: 12px;
            height: 12px;
            position: relative;
            top: 0px;
            margin-right: 10px;
            border-radius: 50%;
      }
      .text {
            font-size: 12px;
            padding: 0 2px
      }
}

#tooltipOpacity {
      position: absolute;
      opacity: 0;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 3px;
      // box-shadow: -3px 3px 15px #888;
      box-shadow: none;
      color: #FFF !important;
      padding: 6px;
      min-width: 100;
      max-width: 200px ;
      z-index: 999999999;
}

.sunburst-viz .text-contour {
      stroke-width: 0 !important;
}

// .main-arc {
      // fill :#fff !important; //sunburst center part color
// }

.dlpViewSunburstSpacing {
      width: fit-content;
      margin: auto;
}

.dlpTableTopActionText {
      display: table-cell !important;
      width: 300px !important;
      max-width: 400px !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      margin: 0 10px !important;
}

.dlpTopThreats {
      .apexcharts-tooltip {
            // left: 100 !important;
            min-width: 150px;
            max-width: 250px;
            overflow: visible !important;
            // word-break: break-all !important;
            white-space: pre-line !important;;
      }
}

.dlpHeaderMultiSelection {
      color: #000; 
      font-size: 11px !important;
      z-index: 9999;
      position: absolute;
      // max-width: 200px !important;
      // overflow: hidden
      // overflow-x: hidden;

      .css-48ayfv, .css-1r4vtzz {
         background: transparent !important;
         color: #FFF !important;
         box-shadow: none;
         min-width: 130px;
         padding: 0;
      }

      .css-1pcexqc-container {
            min-width: 130px;
            max-width: 300px;
            background-color: #FFF !important;
            border: 1px solid gray;
            position: absolute;
            // margin-top: -8px !important;
            overflow: hidden
      }

      .css-1vr111p-option {
            padding: 5px !important;
            min-width: 130px !important;
            max-width: 300px !important;   
            width: max-content !important;
      }
      
      .css-1jllj6i-control, .css-6y1x9t-control {
            max-width: 290px !important;
            min-width: 170px !important;
            width: auto !important;
            margin-left: 5px !important;
            margin-right: 5px !important;
      }
      .css-6y1x9t-control:hover {
            background-color: none;
      }

      .css-1qprcsu-option {
            max-width: 290px !important;
            min-width: 170px !important;
            margin-left: 0px !important;
            margin-right: 3px !important;
            padding: 8px 7px !important; 
      }
      
      .css-11unzgr {
            min-width: 130px;
            max-height: 300px !important;
            padding-left: 4px;
      }

      // .css-1v99tuv {
            // font-size: 13px !important;
            // padding: 0.375rem 0rem;
            // max-width: 10px !important;
      // }

      // .css-1gpjby2 {
      //       display: none !important;
      // }
      
}

.dlpHeaderMultiSelection>div>div {
      position: relative !important;
      min-width: 130px !important;
      max-width: 200px !important;
      margin-top: 12px !important;      
}

.multiSelection-fullWidth {
      color: #000; 
      font-size: 11px !important;
      z-index: 9999;
      position: absolute;
      // max-width: 200px !important;
      // overflow: hidden
      // overflow-x: hidden;

      .css-48ayfv, .css-1r4vtzz {
         background: transparent !important;
         color: #FFF !important;
         box-shadow: none;
         min-width: 130px;
         padding: 0 0 0 5px;
         border: 1px solid $muted;
      }

      .css-1pcexqc-container {
            min-width: 130px;
            max-width: 500px;
            background-color: #FFF !important;
            border: 1px solid gray;
            position: absolute;
            // margin-top: -8px !important;
            overflow: hidden
      }

      .css-1vr111p-option {
            padding: 5px !important;
            min-width: 130px !important;
            max-width: 500px !important;   
            width: max-content !important;
      }
      
      .css-1jllj6i-control, .css-6y1x9t-control {
            max-width: 490px !important;
            min-width: 130px !important;
            width: auto !important;
            margin-left: 5px !important;
            margin-right: 5px !important;
      }
      .css-6y1x9t-control:hover {
            background-color: none;
      }

      .css-1qprcsu-option {
            max-width: 490px !important;
            min-width: 170px !important;
            margin-left: 0px !important;
            margin-right: 3px !important;
            padding: 8px 7px !important; 
      }
      
      .css-11unzgr {
            min-width: 130px;
            max-height: 500px !important;
            padding-left: 4px;
      }

      // .css-1v99tuv {
            // font-size: 13px !important;
            // padding: 0.375rem 0rem;
            // max-width: 10px !important;
      // }

      // .css-1gpjby2 {
      //       display: none !important;
      // }
      
}

.multiSelection-fullWidth>div>div {
      position: relative !important;
      min-width: 130px !important;
      max-width: 500px !important;
      margin-top: 12px !important;      
}

.multiSelection-minWidth {
      color: #000; 
      font-size: 11px !important;
      z-index: 9999;
      position: absolute;
      // max-width: 200px !important;
      // overflow: hidden
      // overflow-x: hidden;

      .css-48ayfv, .css-1r4vtzz {
         background: transparent !important;
         color: #FFF !important;
         box-shadow: none;
         min-width: 80px;
         padding: 0 0 0 5px;
      }

      .css-1pcexqc-container {
            min-width: 80px;
            max-width: 500px;
            background-color: #FFF !important;
            border: 1px solid gray;
            position: absolute;
            // margin-top: -8px !important;
            overflow: hidden
      }

      .css-1vr111p-option {
            padding: 5px !important;
            min-width: 80px !important;
            max-width: 500px !important;   
            width: max-content !important;
      }
      
      .css-1jllj6i-control, .css-6y1x9t-control {
            max-width: 490px !important;
            min-width: 80px !important;
            width: auto !important;
            margin-left: 5px !important;
            margin-right: 5px !important;
      }
      .css-6y1x9t-control:hover {
            background-color: none;
      }

      .css-1qprcsu-option {
            max-width: 490px !important;
            min-width: 80px !important;
            margin-left: 0px !important;
            margin-right: 3px !important;
            padding: 8px 7px !important; 
      }
      
      .css-11unzgr {
            min-width: 80px;
            max-height: 500px !important;
            padding-left: 4px;
      }

      // .css-1v99tuv {
            // font-size: 13px !important;
            // padding: 0.375rem 0rem;
            // max-width: 10px !important;
      // }

      // .css-1gpjby2 {
      //       display: none !important;
      // }
      
}

.multiSelection-minWidth>div>div {
      position: relative !important;
      min-width: 80px !important;
      max-width: 500px !important;
      margin-top: 12px !important;      
}

.removeMargin>div>div {
      margin-top: 0 !important;      
}

.blackBgReactSelect {
      .css-1g6gooi, .css-b8ldur-Input {
            color: #fff !important
      }
}

.dlpReportSelectStyle { 
      .css-1pahdxg-control {
            background-color: #fff !important;
            border: 1px solid #ced4da !important;
            border-radius: 0.25rem !important;
            box-shadow: none !important;
      }
      // .css-2b097c-container  {
      //       z-index: 1000 !important;     
      // }
      .css-1pahdxg-control:hover {
            border: 1px solid #ced4da !important;
            box-shadow: none !important;
      }
      .css-1okebmr-indicatorSeparator {
            display: none !important;
      }
      .css-6q0nyr-Svg {
            fill: #000;
            stroke: #000;
      }
      svg {
            height: 14px;
      }

}

.dlpBoxSection {
      label {
            color: $purple;
            min-width: 33%;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            background-color: #DEE2E6;
      }
}

.dlpCustomDateDiv {
      right: 10% !important;
}


.arrow_box {
      background-color: #FFFFFF;
      color: #000;
      min-width: 50px;
      max-width: 250px;
      padding: 2px 5px;
      white-space: pre-wrap;
}

.apexDonutChartLibrary { 
      .apexcharts-tooltip {
            color: #000;
            background-color: #fff;
            transform: translateX(10px) translateY(50px);
            overflow: visible !important;
            // white-space: normal !important;
            // border-radius: 5px;
      }
      .arrow_box {
            white-space: unset;
      }
}

.dateSliderInputReszie {
      .form-control {
            // height: calc(1.5em + 0.5rem + 2px);
            padding: 3px 6px;
            font-size: 12px;
            // line-height: 1;
            // border-radius: 5px;
            border: 1px solid #95989E;
            background-color: #fff !important;
            border-radius: 0 !important;
            height: calc(.75em + 0.75rem + 2px)
      }
}

.divTransition {
      // transition: top 0.3s ease-in-out;
      // transition: .5s !important;
      visibility: visible;
      opacity: 1;
      transition: visibility 1s, opacity 1s linear;
      left: 0;
      transition-duration: .5s;
      transition-timing-function: ease-in-out;
}

.radialStackedCirlceChartEmptySlot {
      fill: $gray4;
      // fill: $black5;
}

// #svgUserStackbarChart, #svgStackbarChart {
//       margin-bottom: -20px !important;
// }


.text-circle {
	display: block;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	float: left;
	// margin-top: 5px;
	background-color: #4A397C;
}

.ulBullet {
	display: block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	float: left;
	// margin-top: 5px;
	background-color: $gray5;
}

.hierarchicalChart {
      .breadCrumb {
            /*centering*/
            display: inline-block;
            box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.35);
            overflow: hidden;
            border-radius: 5px;
            /*Lets add the numbers for each link using CSS counters. flag is the name of the counter. to be defined using counter-reset in the parent element of the links*/
            counter-reset: flag; 
      }
      
      .breadCrumb a {
            text-decoration: none;
            outline: none;
            display: block;
            float: left;
            font-size: 12px;
            line-height: 25px;
            color: #000;
            /*need more margin on the left of links to accomodate the numbers*/
            padding: 0 10px 0 30px;
            background: #EDEDED;
            background: linear-gradient(#EDEDED, #666);
            position: relative;
      }
      /*since the first link does not have a triangle before it we can reduce the left padding to make it look consistent with other links*/
      .breadCrumb a:first-child {
            padding-left: 5px;
            border-radius: 5px 0 0 5px; /*to match with the parent's radius*/
      }
      .breadCrumb a:first-child:before {
            left: 14px;
      }
      .breadCrumb a:last-child {
            border-radius: 0 5px 5px 0; /*this was to prevent glitches on hover*/
            padding-right: 5px;
      }
      
      /*hover/active styles*/
      .breadCrumb a.active, .breadCrumb a:hover{
            background: #666;
            background: linear-gradient(#666, #FFF);
      }
      .breadCrumb a.active:after, .breadCrumb a:hover:after {
            background: #666;
            background: linear-gradient(135deg, #666, #FFF);
      }
      
      /*adding the arrows for the breadCrumbs using rotated pseudo elements*/
      .breadCrumb a:after {
            content: '';
            position: absolute;
            top: 0; 
            right: -13px;
            width: 25px;
            height: 25px;
            /*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's: 
            length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
            if diagonal required = 1; length = 1/1.414 = 0.707*/
            transform: scale(0.707) rotate(45deg);
            /*we need to prevent the arrows from getting buried under the next link*/
            z-index: 1;
            /*background same as links but the gradient will be rotated to compensate with the transform applied*/
            background: #EDEDED;
            background: linear-gradient(135deg, #EDEDED, #666);
            /*stylish arrow design using box shadow*/
            box-shadow: 
                  2px -2px 0 2px rgba(0, 0, 0, 0.4), 
                  3px -3px 0 2px rgba(255, 255, 255, 0.1);
            /*
                  5px - for rounded arrows and 
                  50px - to prevent hover glitches on the border created using shadows*/
            border-radius: 0 5px 0 50px;
      }
      /*we dont need an arrow after the last link*/
      .breadCrumb a:last-child:after {
            content: none;
      }
      // /*we will use the :before element to show numbers*/
      // .breadCrumb a:before {
      //       content: counter(flag);
      //       counter-increment: flag;
      //       /*some styles now*/
      //       border-radius: 100%;
      //       width: 20px;
      //       height: 20px;
      //       line-height: 20px;
      //       margin: 8px 0;
      //       position: absolute;
      //       top: 0;
      //       left: 30px;
      //       background: #444;
      //       background: linear-gradient(#444, #222);
      //       font-weight: bold;
      // }
}

.overflowHidden-y-scroll {
      overflow: hidden;
      overflow-y: auto;
}

// #svgFilesStackbarChart {
//       .radialStackedCirlceChartDataSlot {
//       //   display: none;
//         animation-name: draw;
//         animation-duration: 5s;
//         animation-fill-mode: forwards; // Stay on the last frame
//         animation-iteration-count: 1; // Run only once
//         animation-timing-function: linear;
//       }
// }



.reactSelectFilterDrop {
      // .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
      //       // display: none;
      // }
      .css-4ljt47-MenuList {
            padding-bottom: 0px !important;
            padding-top: 0px !important;
            min-width: 150px;
            width: max-content;
      }
      .css-26l3qy-menu {
            min-width: 100%;
            width: auto;
      }
      .css-4ljt47-MenuList>div {
            background-color: #fff;
            color: #000;
            padding: 3px 12px;
      }
      .css-4ljt47-MenuList>div:hover {
            background-color: $hvoerInfo;
            color: #000;
            padding: 3px 12px;
      }
      svg {
            height: 14px;
            color:#fff;
      }

      .css-26l3qy-menu {
            // box-shadow: 0 0 0 2px $muted !important;
            .css-9gakcf-option {
                  background-color: #B2D4FF !important;
                  color: $black1 !important;
            }
      }
}

.reactSelectOptGroupFilterDrop {
      // .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
      //       // display: none;
      // }
      .css-4ljt47-MenuList {
            padding-bottom: 0px !important;
            padding-top: 0px !important;
            // min-width: 150px;
            width: auto;
            .css-syji7d-Group {
                  .css-18ng2q5-group{
                        font-size: 14px;
                        font-weight: bold;
                        color: $gray3;
                  }
            }
      }
      .css-26l3qy-menu {
            min-width: 100%;
            width: auto;
      }
      .css-4ljt47-MenuList>div {
            background-color: #fff;
            color: #000;
            padding: 3px 12px;
      }
      .css-4ljt47-MenuList>.css-syji7d-Group>div>.css-1n7v3ny-option:hover {
            background-color: $hvoerInfo;
            color: #000;
            // padding: 3px 12px;
      }
      svg {
            height: 14px;
            color:#fff;
      }

      .css-9gakcf-option {
            background-color: transparent !important;
            color: $black1 !important;
      }
}

.filterSelectDrop {
      .css-yk16xz-control, .css-1pahdxg-control {
            border-radius: 5px;
            min-width: 150px;
            max-width: fit-content;
            background-color: $muted;
            border-color: $gray5;
            box-shadow: 0 0 0 1px $gray5;
            color: $black1;
            background-color: $black5;
            // border-color: transparent;
            min-height: 28px;
            max-height: 28px;
            border-width: 0px;
            box-shadow: none;
      }
      .css-1uccc91-singleValue,
      .css-b8ldur-Input,
      .css-1hb7zxy-IndicatorsContainer {
            color: #FFF;
            // height: 28px;
            // top: 18px;
      }
      .css-26l3qy-menu {
            box-shadow: 0 0 0 2px $muted !important;
            width: auto;
            .css-9gakcf-option {
                  background-color: #B2D4FF !important;
                  color: $black1 !important;
            }
      }      
      .css-1okebmr-indicatorSeparator {
            display: none;
      }
      // .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
      //       // display: none;
      // }
      .css-4ljt47-MenuList {            
            border-radius: 5px;
            padding-bottom: 0px !important;
            padding-top: 0px !important;
      }
      .css-4ljt47-MenuList>div {
            min-width: 150px;
            max-width: fit-content;
            background-color: #fff;
            color: #000;
            padding: 3px 12px;
      }
      .css-4ljt47-MenuList>div:hover {
            background-color: $hvoerInfo;
            color: #000;
            padding: 3px 12px;
      }
      svg {
            height: 14px;
            color:#fff;
      }
}

.smallFitler  {
      .css-yk16xz-control, .css-1pahdxg-control {
            min-width: 120px;
            min-height: 31px;
            max-height: 31px;
      }
}

.smallFitler2  {
      .css-yk16xz-control, .css-1pahdxg-control {
            min-width: 150px;
            min-height: 31px;
            max-height: 31px;
      }
}

.malwareDateSlider {
      //.svg_select_boundingRect 
      .apexcharts-selection-rect {
            rx: 6;
            ry: 6;
            stroke: #fff;
            stroke-width: 1;
      }
      .svg_select_points{
            display: none;
      }
      .svg_select_points_l {
            rx:5;
            ry:5;
            height:17px;
            y:0;
            x:0;
      }
      .svg_select_points_r {
            rx:5;
            ry:5;
            height:17px;
            y:0;
            // x:0;
      }
}

.apexcharts-bar-area:hover {
      background-color: transparent !important;
}

.transparentTooltip {
      //aiops page      
      .metricsDetailCurrentTooltip {
            font-size: 12px !important;
            border: 1px solid #000;
            background: rgba(0, 0, 0, 0.8);
            color: #FFF;
            .apexcharts-tooltip-title {
                  background: rgba(0, 0, 0, 0.8) !important;
                  color: #FFF;
                  // border-bottom: 1px solid $warning !important;
            }
            .apexcharts-tooltip-y-group {
                  padding: 0 !important
            }
      }
      //aiops page

      //dlp page
      .apexcharts-theme-light, .apexcharts-theme-dark {
            border: 0 !important;
            background: none !important;            
      }
      .arrow_box {
            border: 1px solid #000;
            background: rgba(0, 0, 0, 0.8);
            color: #FFF;
            font-size: 14px;            
            // max-width: 300px !important;
            // position:absolute;
      // z-index: 999999999999999999999999999999999999999999999999;
      }
      //dlp page

      .apexcharts-theme-light {
            .apexcharts-tooltip-title {
                  border: 1px solid #000;
                  background: rgba(0, 0, 0, 0.8) !important;
                  color: #FFF;
                  font-size: 14px;
                  margin-bottom: 2px !important;
            }
            .apexcharts-tooltip-series-group {
                  border: 1px solid #000;
                  background: rgba(0, 0, 0, 0.8) !important;
                  color: #FFF;
                  font-size: 14px;

            }
      }

      // .arrow_box {
      //       word-break: break-all;
      // }

}

// .leftSide { //transparentTooltip avoid flow hiden in left side
//       .apexcharts-theme-light, .apexcharts-theme-dark {
//             border: 0 !important;
//             background: none !important;   
//             left:0 impor !important;
//       }
// }

.dlpSerachControlsPlaceholder {
      ::placeholder {
            color: $gray5
      }
}

.loaderOverlay{
      // background-color: rgba(0, 0, 0, 0.7);
      background-color: rgba(235, 235, 235, 0.4);
      // background-color: $white2;
      // opacity: 0.4;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0px;
      right: 0px;
      z-index: 99999999;
      // z-index: 9;
      display: flex;
      justify-content: flex-end;
}

.showVisibility{
      visibility: visible;
}

.overlayEqualizerLoader {
      --animation-duration: 1000ms;      
      position: relative;
      width: 75px;
      height: 75px;
      top: 50%;
      right: 50%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .spinner-item {
            width: calc(75px / 12);
            height: 80%;
            background: var(--clr-spinner);
            animation: spinner1 var(--animation-duration) ease-in-out infinite;

            @keyframes spinner1 {
                  50% {
                        transform: scaleY(0.25);
                  }
            }
      }

      .spinner-item:nth-child(1) {
            --clr-spinner: #CDAD1B;
            animation-delay: calc(var(--animation-duration) / 10 * -3);
      }

      .spinner-item:nth-child(2) {
            --clr-spinner: #B96422;
            animation-delay: calc(var(--animation-duration) / 10 * -1);
      }

      .spinner-item:nth-child(3) {
            --clr-spinner: #CDAD1B;
            animation-delay: calc(var(--animation-duration) / 10 * -2);
      }

      .spinner-item:nth-child(4) {
            --clr-spinner: #B96422;
            animation-delay: calc(var(--animation-duration) / 10 * -1);
      }

      .spinner-item:nth-child(5) {
            --clr-spinner: #CDAD1B;
            animation-delay: calc(var(--animation-duration) / 10 * -3);
      }
}

.overlayCirlceLoader {
      --animation-duration: 2000ms;
	position: relative;
	width: 75px;
	height: 75px;
      top: 50%;
      right: 50%;

	.spinner-item {
		position: absolute;
		top: calc(50% - 75px / 2);
		left: calc(50% - 75px / 2);
		width: 75px;
		height: 75px;
		background: var(--clr-spinner);
		border-radius: 50%;
		animation: spinner6 var(--animation-duration) ease-in-out infinite;

		@keyframes spinner6 {
			0%,
			100% {
				transform: scale(0.25);
				opacity: 1;
			}

			50% {
				transform: scale(1);
				opacity: 0;
			}
		}
	}

	.spinner-item:nth-of-type(1) {
		--clr-spinner: #bbbb88;
	}

	.spinner-item:nth-of-type(2) {
		--clr-spinner: #eeaa88;
		animation-delay: calc(var(--animation-duration) / -2);
	}
}

.overlayMultiCirlceLoader {
      --animation-duration: 1150ms;
	position: relative;
	width: 75px;
	height: 75px;
      top: 50%;
      right: 50%;
      
      .spinner-item {
            --item-size: calc(75px / 4);
            width: var(--item-size);
            height: var(--item-size);
            display: inline-block;
            margin: 0 3px;
            border-radius: 50%;
            border: 4px solid var(--clr-spinner);
            animation: spinner4 var(--animation-duration) ease-in-out infinite;

            @keyframes spinner4 {
                  0%,
                  100% {
                        transform: translateY(75%);
                  }

                  50% {
                        transform: translateY(-75%);
                  }
            }
      }

      .spinner-item:nth-child(1) {
            --clr-spinner: #eeaa88;
            animation-delay: calc(var(--animation-duration) / 6 * -1);
      }

      .spinner-item:nth-child(2) {
            --clr-spinner: #eedd99;
            animation-delay: calc(var(--animation-duration) / 6 * -2);
      }

      .spinner-item:nth-child(3) {
            --clr-spinner: #bbbb88;
            animation-delay: calc(var(--animation-duration) / 6 * -3);
      }
}

.overlayInnerCircleLoader {
      --animation-duration: 1150ms;
	position: relative;
	width: 65px;
	height: 65px;
      top: 50%;
      right: 50%;

	.spinner-item {
		position: absolute;
		width: var(--item-size);
		height: var(--item-size);
		top: calc(50% - var(--item-size) / 2);
		left: calc(50% - var(--item-size) / 2);
		border: 4px solid transparent;
		border-left: 4px solid var(--clr-spinner);
		border-right: 4px solid var(--clr-spinner);
		border-radius: 50%;
		animation: spinner2 var(--animation-duration) linear infinite;

		@keyframes spinner2 {
			to {
				transform: rotate(360deg);
			}
		}
	}

      // .spinner-item:nth-child(1) {
      //       --clr-spinner: var(--clr5);
      //       animation-delay: calc(var(--animation-duration) / 10 * -1);
      // }

	.spinner-item:nth-of-type(1) {
		--item-size: 65px;
		--clr-spinner: #bbbb88;
		border-top: 4px solid #bbbb88;
	}

	.spinner-item:nth-of-type(2) {
		--item-size: 50px;
		--clr-spinner: #eeaa88;
		border-bottom: 4px solid #eeaa88;
	}

	.spinner-item:nth-of-type(3) {
		--item-size: 35px;
		--clr-spinner: $warning;
		border-top: 4px solid $warning;
	}
}

.risk-critical {
      color: $critical
}
.risk-high {
      color: $high
}
.risk-medium {
      color: $medium
}
.risk-low {
      color: $low
}
.risk-others {
      color: $others;
}

.risk-border-critical {
      border: 1px solid $critical;
}
.risk-border-high {
      border: 1px solid $high;
}
.risk-border-medium {
      border: 1px solid $medium;
}
.risk-border-low {
      border: 1px solid $low;
}
.risk-border-others {
      border: 1px solid $others;
}

.risk-bg-critical {
      background-color: $critical
}
.risk-bg-high {
      background-color: $high
}
.risk-bg-medium {
      background-color: $medium
}
.risk-bg-low {
      background-color: $low
}

.risk-badge-critical {
      background-color: $critical;
      color: #FFFFFF;
}
.risk-badge-high {
      background-color: $high;
      color: #FFFFFF;
}
.risk-badge-medium {
      background-color: $medium;
      color: #FFFFFF;
}
.risk-badge-low {
      background-color: $low;
      color: #FFFFFF;
}
.risk-badge-others {
      background-color: $others;
      color: #FFFFFF;
}

.risk-badge-outline-critical {
      background-color: transparent;
      border: 1px solid $critical
}
.risk-badge-outline-high {
      background-color: transparent;
      border: 1px solid $high
}
.risk-badge-outline-medium {
      background-color: transparent;
      border: 1px solid $medium
}
.risk-badge-outline-low {
      background-color: transparent;
      border: 1px solid $low
}
.risk-badge-outline-others {
      background-color: transparent;
      border: 1px solid $others
}

.timelineCircle {
      $numDots: 20;
      $parentWidthBase: 0.8;
      $parentWidth: 100%;
      $parentMaxWidth: 100%;
      $dotWidth: 25px;
      $dotWidthSm: 17px;
      $active: #2C3E50;
      $inactive: #AEB6BF;

      .flex-parent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
      }
      .input-flex-container {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: $parentWidth;
            height: 100px;
            max-width: $parentMaxWidth;
            position: relative;
            z-index: 0;
      }
      .input {
            width: $dotWidth;
            height: $dotWidth;
            background-color: $active;
            position: relative;
            border-radius: 50%;
            &:hover {
                  cursor: pointer
            }
            &::before, &::after {
                  content: '';
                  display: block;
                  position: absolute;
                  z-index: -1;
                  top: 50%;
                  transform: translateY(-50%);
                  background-color: $active;
                  width: $parentWidth / $numDots;
                  height: 5px;
                  max-width: $parentMaxWidth / $numDots;
            }
            &::before {
                  left: calc(#{-$parentWidth / $numDots} + #{$dotWidth / 2})
            }
            &::after {
                  right: calc(#{-$parentWidth / $numDots} + #{$dotWidth / 2})
            }
            &.active {
                  background-color: $active;
                  &::before {
                        background-color: $active
                  }
                  &::after {
                        background-color: $inactive
                  }
                  span {
                        font-weight: 700;
                        &::before {
                              font-size: 13px
                        }
                        &::after {
                              font-size: 15px
                        }
                  }
            }
            &.active ~ .input {
                  &, &::before, &::after {
                        background-color: $inactive
                  }
            }
            span {
                  width: 1px;
                  height: 1px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  visibility: hidden;
                  &::before, &::after {
                        visibility: visible;
                        position: absolute;
                        left: 50%;
                  }
                  &::after {
                        content: attr(data-year);
                        top: 25px;
                        transform: translateX(-50%);
                        font-size: 14px;
                  }
                  // &::before {
                  //       content: attr(data-info);
                  //       top: -65px;
                  //       width: 70px;
                  //       transform: translateX(-5px) rotateZ(-45deg);
                  //       font-size: 12px;
                  //       text-indent: -10px;
                  // }
            }
      }
      .description-flex-container {
            width: $parentWidth;
            font-weight: 400;
            font-size: 22px;
            margin-top: 100px;
            max-width: $parentMaxWidth;
            p {
                  margin-top: 0;
                  display: none;
                  &.active {
                        display: block;
                  }
            }
      }
}

.timelineNew {
      .rb-container ul.rb li {
            list-style: none;
            margin: auto;
            margin-left: 10em;
            min-height: 50px;
            border-left: 1px dashed #fff;
            padding: 0 0 50px 30px;
            position: relative;
      }
      
      .rb-container ul.rb li:last-child {
            border-left: 0;
      }
      
      .rb-container ul.rb li::before {
            position: absolute;
            left: -18px;
            top: -5px;
            content: " ";
            border: 8px solid rgba(255, 255, 255, 1);
            border-radius: 500%;
            background: #50d890;
            height: 20px;
            width: 20px;
            transition: all 500ms ease-in-out;
      }
      
      .rb-container ul.rb li:hover::before {
            border-color: #232931;
            transition: all 1000ms ease-in-out;
      }
      
      ul.rb li .timestamp {
            color: #50d890;
            position: relative;
            width: 100px;
            font-size: 12px;
      }
      
      .item-title {
            color: #fff;
      }
}

.input-enclose input {
      transition: all .3s;
}

.scroller {
      scrollbar-color: red yellow;
}


.scrollbarDesign {
      ::-webkit-scrollbar {
            height: 5px;
            overflow: visible;
            // width: 8px;
      }

      ::-webkit-scrollbar-track {
            background: $white4; 
      }
      ::-webkit-scrollbar-thumb {
            background: #4d4848; 
      }
}

// .scrollbarDesignDark {
      ::-webkit-scrollbar {
            height: 5px;
            overflow: visible;
            width: 6px;
      }

      ::-webkit-scrollbar-track {
            background: $white4; 
      }
      ::-webkit-scrollbar-thumb {
            background: $black2; 
      }
// }

.select-dark {
      background-color: $muted !important;
      border: 1px solid $muted !important;
      color: $gray3 !important;
      option {
            background: $gray3;
            color: $muted
      }
}

.dark-search {
      ::placeholder {
            color: $gray3
      }
}

.dark-nav-tab {
      background-color: $muted !important;
      .nav-tabs {
            border-bottom: 1px solid $gray5;
            background-color: $muted !important;
            margin-bottom: 0;
      }
      .nav-tabs .nav-link {
            color: $gray3;
            border-width: 3px;
            border: 1px solid transparent;
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
      }
      .nav-tabs .nav-link.active {
            color:$white4;
            border-color: transparent transparent $white4;
            background-color: transparent;
      }
}


//table css start

.dark-table tbody tr:hover {
      background-color: $dark !important;
      color: $gray3 !important;;
}

.distributionTable {
      thead {
            color: $gray3 !important;
      }
      th, td {
            border-top: 1px solid $dark !important;
      }
      tr:last-child {
            border-bottom: 1px solid $dark !important;
      }

      tr {
            display: table-row !important;
      }

      th:first-child,
      td:first-child {
            display: table-cell !important;
            width: 50px !important;
      }
           
      th:nth-child(2),
      td:nth-child(2),
      th:nth-child(3),
      td:nth-child(3) {
            display: table-cell !important;
            width: 150px !important;
            max-width: 150px !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
      }

      th:nth-child(3),
      td:nth-child(3) {
            border-right: 2px solid $dark !important;
      }
}

.eventsDistributionTable {
      // thead {
      //       color: $gray3 !important;
      // }
      // th, td {
      //       border-top: 1px solid $dark !important;
      // }
      // tr:last-child {
      //       border-bottom: 1px solid $dark !important;
      // }
      // tr {
      //       display: table-row !important;
      // }
      td {
            border-top: 1px solid $gray6 !important;
      }

      th:nth-child(2),
      td:nth-child(2) {
            display: table-cell !important;
            width: 50px !important;
      }
           
      th:first-child,
      td:first-child,
      th:nth-child(3),
      td:nth-child(3) {
            display: table-cell !important;
            width: 150px !important;
            max-width: 150px !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
      }

      th:nth-child(3),
      td:nth-child(3) {
            border-right: 2px solid $dark !important;
      }
}

.distributionLabelGraph {
      div{
            min-height: 0px !important;
            max-height: 30px !important;
      }
}


.checkRelatedIssue .table {
      tr {
            display: table-row !important;
      }
      th:first-child, td:first-child {
            width: 5px !important;
            padding-left:0;
            display: table-cell !important;
      }
      // th:nth-child(2), td:nth-child(2) {
      //       display: table-cell !important;
      //       width: 40% !important;
      //       max-width: 40% !important;
      //       overflow: hidden !important;
      //       text-overflow: ellipsis !important;
      //       white-space: nowrap !important;
      // }
      // th:nth-child(3), td:nth-child(3){
      //       width: 100px !important;
      //       display: table-cell !important;
      // }
      // th:nth-child(4), td:nth-child(4), th:nth-child(5), td:nth-child(5) {
      //       width: 50px !important;
      //       display: table-cell !important;
      // }
      
}

.assetDetailsSection .table {
      tr {
            display: table-row !important;
      }
      th:first-child, td:first-child, th:nth-child(4), td:nth-child(4) {
            width:50px !important;
            display: table-cell !important;
      }
      th:nth-child(2), td:nth-child(2), th:nth-child(3), td:nth-child(3), th:nth-child(5), td:nth-child(5) {
            display: table-cell !important;
            width: 280px !important;
            max-width: 280px !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
      }
}

// .assetTable .table {
//       th:first-child {
//             width:15% !important;
//             margin-left: 4% !important;
//       }
//       td:first-child {
//             width:19% !important;
//       }
//       th:nth-child(2), td:nth-child(2) {
//             width:20% !important;
//       }
//       th:nth-child(3), td:nth-child(3) {
//             width:40% !important;
//       }
//       th:nth-child(4), td:nth-child(4) {
//             width:21% !important;
//       }
// }

.assetTable tbody tr {
      display: block !important
}

.checkTable tr, .assetTable tr  { border-top: 1px solid #dee2e6; }
.checkTable td, .assetTable td {border-top: none }

// .checkTable .table { table-layout: auto !important; width: 100% !important; }
// .checkTable .table thead tr{ table-layout: auto !important; width: 100% !important; }


.ComDetailResourceTable .table {
      tr {
            display: table-row !important;
      }
      th:first-child, td:first-child  {
            width: 250px !important;
            // max-width: 250px !important;
            // overflow: hidden !important;
            word-break: break-all;
      }
      th:nth-child(2), td:nth-child(2), th:nth-child(5), td:nth-child(5) {
            width: 150px !important;
            word-break: break-all;
      }
      th:nth-child(4), td:nth-child(4) {
            width: 150px !important;
            max-width: 250px !important;
            display: table-cell !important;
      }
      th:nth-child(3), td:nth-child(3) {
            width: 50px !important;
            display: table-cell !important;
      }
      
}

.ComDetailRoleTable .table {
      tr {
            display: table-row !important;
      }
      th:first-child, td:first-child {
            width: 250px !important;
            // max-width: 250px !important;
            // overflow: hidden !important;
            word-break: break-all;
      }
      th:nth-child(4), td:nth-child(4)  {
            width: 150px !important;
            // max-width: 250px !important;
            // overflow: hidden !important;
            word-break: break-all;
      }
      // th:nth-child(3), td:nth-child(3) {
      //       width: 450px !important;
      //       word-break: break-all;
      // }
      th:nth-child(3), td:nth-child(3) {
            width: 150px !important;
            max-width: 100% !important;
            display: table-cell !important;
      }
      th:nth-child(2), td:nth-child(2) {
            width: 50px !important;
            display: table-cell !important;
      }
      
}

.ComDetailServicesTable .table {
      tr {
            display: table-row !important;
      }
      th:first-child, td:first-child {
            width: 250px !important;
            // max-width: 250px !important;
            // overflow: hidden !important;
            word-break: break-all;
      }
      th:nth-child(2), td:nth-child(2)  {
            width: 200px !important;
            // max-width: 250px !important;
            // overflow: hidden !important;
            word-break: break-all;
      }
      // th:nth-child(3), td:nth-child(3) {
      //       width: 450px !important;
      //       word-break: break-all;
      // }
      th:nth-child(4), td:nth-child(4) {
            width: 200px !important;
            max-width: 100% !important;
            display: table-cell !important;
      }
      th:nth-child(3), td:nth-child(3) {
            width: 50px !important;
            display: table-cell !important;
      }
      
}

.ComDetailEventTable .table {
      tr {
            display: table-row !important;
      }
      th:first-child, td:first-child {
            width: 200px !important;
            // max-width: 250px !important;
            // overflow: hidden !important;
            word-break: break-all;
      }
      th:nth-child(2), td:nth-child(2) {
            width: 50px !important;
            display: table-cell !important;
      }
      th:nth-child(3), td:nth-child(3) {
            width: 150px !important;
            max-width: 100% !important;
            display: table-cell !important;
      }     
}

.savedFilters .table{
      tr {
            display: table-row !important;
      }
      th:first-child, td:first-child {
            width: 200px !important;
            display: table-cell !important;
      }
      th:nth-child(2), td:nth-child(2) {
            width: 50px !important;
            display: table-cell !important;
      }
      th:nth-child(3), td:nth-child(3) {
            width: 80px !important;
            display: table-cell !important;
      }
      th:nth-child(4), td:nth-child(4) { 
            display: table-cell !important;
            width: 100px !important;            
      }
}

.topologyNodeAlertsTable {            
      .react-contextmenu {
            font-family: 'Muli', sans-serif;
            font-size: 13px;
            padding: 8px 0;
            background-color: $black4 !important;
            min-width: fit-content;
      }
      .react-contextmenu-item {
            color: #fff !important;
            padding: 0 8px;
            cursor: pointer;
      }

      .react-contextmenu-item:hover {
            background-color: $gray3;
            font-size: 14px;
      }
}

//table css end


.mask {
      // border-style: solid;
      // border-width: 0 0 3px 3px;
      position: relative;
      float: left;
      clear: none;
      // right: -3px;
      // top: -3px;
      // background-color: white;
      width: 40%;
      height: 30px;
      text-align: center;
}

.multiSelectSquareBox {
      padding: 0.1px 8px !important;
}

.aiopsSre {
      .svg_select_points {
            stroke: #989AA1 !important;
            fill: #989AA1 !important;
            height: 15px;
            y: 20;
            
      }
      .svg_select_points_l{
            // width: 6px;
            // x: -2;
      }
      .svg_select_points_r{
            // width: 6px;
            // x: 15;
      }
}

.table-dark-hover {
      tbody tr:hover {
            background-color: $black6 !important;
      }
}

.dropdown-divider-dark {
      height: 0;
      margin: 0.8rem 0;
      overflow: hidden;
      border-top: 1px solid $black5;
}

.dropdown-divider-sm {
      height: 0;
      margin: 0.6rem 0;
      overflow: hidden;
      border-top: 1px solid $black5;
}

.alert-remediation-dark {
   color: $white1;
   background-color: $primary;
   border-color: $primary;
}

.dark-select-theme {
      border: $gray5;
      color: $white1;
      .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
            display: none;
      }
      .css-yk16xz-control, .css-1pahdxg-control {
            background-color: $muted;
            border-color: $gray5;
            box-shadow: 0 0 0 1px $gray5;
            color: $black1;
      }
      .css-9gakcf-option {
            background-color: $gray3 !important;
            color: $muted !important;
            padding: 3px 12px;
      }
      .css-gg45go-NoOptionsMessage {
            background-color: $gray3 !important;
            color: $muted !important;
      }
      .css-1uccc91-singleValue {
            color: $white1;
      }
      .css-4ljt47-MenuList {
            padding-bottom: 0px !important;
            padding-top: 0px !important;
      }
      .css-4ljt47-MenuList>div {
            background-color: #fff;
            color: #000;
            padding: 3px 12px;
      }
      .css-4ljt47-MenuList>div:hover {
            background-color: $hvoerInfo;
            color: #000;
            padding: 3px 12px;
      }
}

.dark-select-theme-with-dropdown-indicator {
      border: $gray5;
      color: $white1;
      .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
            display: block;
      }
      .css-yk16xz-control, .css-1pahdxg-control {
            background-color: $muted;
            border-color: $gray5;
            box-shadow: 0 0 0 1px $gray5;
            color: $black1;
      }
      .css-9gakcf-option {
            background-color: $gray3 !important;
            color: $muted !important;
            padding: 3px 12px;
      }
      .css-gg45go-NoOptionsMessage {
            background-color: $gray3 !important;
            color: $muted !important;
      }
      .css-1uccc91-singleValue {
            color: $white1;
      }
      .css-4ljt47-MenuList {
            padding-bottom: 0px !important;
            padding-top: 0px !important;
      }
      .css-4ljt47-MenuList>div {
            background-color: #fff;
            color: #000;
            padding: 3px 12px;
      }
      .css-4ljt47-MenuList>div:hover {
            background-color: $hvoerInfo;
            color: #000;
            padding: 3px 12px;
      }
}

.apexcharts-yaxis-annotations {
      text {
            // fill: red !important;
            // padding-left: -10px !important;
            text-anchor: end !important;
      }
      // rect {
      //       width: 50px !important;
      // }
}

.bg-white4 {
      .input-group{
            .form-control{
               background-color: #FFF;
               border: none;
            }
      }
}

.white-search {
      .form-control{
            background-color: $gray1;
            border: 1px solid #ced4da;
      }
}

.w-min-20 {
      min-width: 20% !important
}

.badge-pass {
      background-color: $warning;
      border: 1px solid $warning !important;
      color: #fff;
}

.badge-fail {
      background-color: $dark-theme-orange;
      border: 1px solid $dark-theme-orange !important;
      color: #fff;
}

.badge-outline-pass {
      background-color: transparent;
      border: 1px solid $warning !important;
      color: $warning;
}

.badge-outline-fail {
      background-color: transparent;
      border: 1px solid $dark-theme-orange !important;
      color: $dark-theme-orange;
}

.risk-line-no-position {
      width: 3px;
      display: table;
      float: left;
      height: 60%;
      top: 20%;
      left: 3px;
}

.bg-before-after {
      background-color: #262B38 !important;
}

.after-4,
.before-0 {
      background-color: $black8 !important;
}

.after-3,
.before-1 {
      background-color: #595e69 !important;
}

.after-2,
.before-2 {
      background-color: #6c7079 !important;
}

.after-1,
.before-3 {
   background-color: #7e828a !important;
}

.after-0,
.before-4 {
   background-color: #91949b !important;
}

.dropdownSettingDark {
      .dropdown-item:hover    {
            background-color: $black8;
      }
}

// .alertCaseTimeAnnotation {
//       .apexcharts-xaxis-annotations {
//             rect {
//                   width: 30px
//             }
//       }
// }

.indicatoreBox {
      float: left;
      height: 15px;
      width: 15px;
      clear: both;
}

.resizeTablePadding {
      .table-sm {
            th:first-child {
                  padding: 0.4rem 0.4rem !important
            }
            th {
                  padding: 0.4rem 0rem !important     

            }
            th>span{
                  margin: 0 !important;
            }
      }
      .table-sm td {
            padding: 0.4rem 0.4rem !important
      }
      // .table-xs th, .table-xs td {
      //       padding: 0.2rem 0.1rem !important;
      //       margin-left: 2px 
      // }
}


   
.badge-square-sm {
      width:16px;
      display: inline-block;
      padding: 3px;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-sm {
      border-radius: 2rem;
      padding: 0px 4px;
      font-weight: 700;
}

.dark-theme-popover{
      .popover {
            background: rgba(0, 0, 0, 0.8) !important;
            font-size: 14px !important;
            color: #fff !important;
            overflow-y: hidden !important;
            min-height: 50px !important;
      }
      .popover-header {
            color: #fff;
            background: rgba(0, 0, 0, 0.8) !important;
            font-size: 16px !important;
      }
      .popover-body {
            // color: #fff !important;
      }
      .popover-inner { 
            min-height: 50px !important;
      }
}

.dlpDescriptionForm {
      //rect-select dropdown
      .selectOption {
            font-size:  13px !important;
            min-width: 150px !important;
            color:  $white1 !important;

            .css-yk16xz-control, .css-1pahdxg-control, .css-1fhf3k1-control {
                  border: none !important;
                  background-color: transparent !important;
                  border-radius: 0px;
                  border-bottom: 3px dotted $gray3 !important;
                  box-shadow: 0 0 0 transparent !important;

                  .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        padding: 0 !important;
                  }
                  .css-1wa3eu0-placeholder {
                        color:  $white1 !important;
                  }
                  .css-b8ldur-Input {
                        color:  $white1 !important;
                  }

                  

            }     
            .css-1fhf3k1-control {
                  .placeholder, .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        color:  $gray5 !important;
                  }
            }

            .css-1uccc91-singleValue {
                  color:  $white1 !important;
            }
            .css-26l3qy-menu {
                  background-color: $black5 !important;
                  // margin-top: 0px !important;
                  box-shadow: 0 0 0 2px $muted !important;
                  min-width: 100%;
                  width: max-content;
                  .css-1n7v3ny-option {
                        // background-color: $primary;
                        color: $black1 !important;
                  }
                  .css-9gakcf-option {
                        background-color: $info !important;
                        color: $black1 !important;
                  }
            }
      }
      .dottedDangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  border-bottom: 3px dotted $danger !important;
            }
      }
      .dangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  border-bottom: 1px solid $danger !important;
            }
      }

      // react-muliselect-dropdown
      .multiSelectOption {
            button {
                  font-size:  13px !important;
                  min-width: 200px !important;
                  color:  $white1 !important;
                  padding: 0 !important;
                  border: none !important;
                  background-color: transparent !important;
                  border-bottom: 3px dotted $gray3 !important;
            }
            div>div {
                  background-color: transparent !important;
            }
            .css-1pcexqc-container {
                  background-color: $black5 !important;
                  padding-top: 4px;
                  color: $white1 !important;
                  min-width: 200px !important;
                  width: max-content;
                  // box-shadow: 0 0 0 1px $muted !important;
                  .css-1jllj6i-control, .css-6y1x9t-control, .css-1jllj6i-control:hover, .css-6y1x9t-control:hover {
                        border-color: $muted !important;
                        box-shadow: 0 0 0 1px $muted !important;
                        color: $white1 !important;
                        min-width: 200px !important
                  }
                  .css-ik6y5r {
                        min-width: 200px !important;
                  }
                        // .css-151xaom-placeholder { 
                        //       color: $white1 !important;
                        // }
            }
            .css-1g6gooi, .css-1qprcsu-option, .css-1vr111p-option { 
                  color: $white1 !important;
                  min-width: 200px !important;
            }
      }
      
      .dottedDangerMultiSelectLine {
            button {
                  border-bottom: 3px dotted $danger !important;
            }
      }

      .dangerMultiSelectLine {
            button {
                  border-bottom: 1px solid $danger !important;
            }
      }

      .inputField {
            background-color: transparent !important;
            border: none !important;
            border-bottom: 3px dotted $gray3 !important;
            color: $white1 !important;
      }
      .dottedDangerTextboxLine {
            border-bottom: 3px dotted $danger !important;
      }
}

.dlpDescriptionFormPlain {
      //rect-select dropdown
      .selectOption {
            .css-yk16xz-control, .css-1pahdxg-control {
                  border-bottom: 1px dotted $gray3 !important;
            }

            .css-1fhf3k1-control {
                  border-bottom: 1px dotted $gray5 !important;
            }
      }
      
      .dangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  border-bottom: 1px dotted $danger !important;
            }
      }
      
      .dottedDangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  border-bottom: 1px dotted $danger !important;
            }
      }

      // react-muliselect-dropdown
      .multiSelectOption {
            button {
                  border-bottom: 1px dotted $gray3 !important;
                  .css-1v99tuv {
                        padding-left: 0.5rem;
                  }
            }
            
      }
      
      .dottedDangerMultiSelectLine {
            button {
                  border-bottom: 1px dotted $danger !important;
            }
      }

      .dangerMultiSelectLine {
            button {
                  border-bottom: 1px dotted $danger !important;
            }
      }
      
      .inputField {
            border-bottom: 1px dotted $gray3 !important;
      }
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            padding-left: 5px;
      }

      .dottedDangerTextboxLine {
            border-bottom: 1px dotted $danger !important;
      }

      .selectBoxBottomColor {
            button {
                  border-bottom: 1px dotted $info !important;
                  .css-1v99tuv {
                        color: $info !important;
                  }
                  .css-1gpjby2 {
                        color: $info !important;
                  }
            }

            .css-yk16xz-control, .css-1pahdxg-control, .css-1fhf3k1-control {
                  border-bottom: 1px dotted $info !important;
            }
            .css-tlfecz-indicatorContainer, .css-1uccc91-singleValue {
                  color: $info !important;
            }
            .inputField {
                  border-bottom: 1px dotted $info !important;
                  color: $info !important;
            }
      }

      .selectBoxBottomPurpleColor {
            button {
                  border-bottom: 1px dotted $frolyLight !important;
                  .css-1v99tuv {
                        color: $frolyLight !important;
                  }
                  .css-1gpjby2 {
                        color: $frolyLight !important;
                  }
            }

            .css-yk16xz-control, .css-1pahdxg-control, .css-1fhf3k1-control {
                  border-bottom: 1px dotted $frolyLight !important;
            }
            .css-tlfecz-indicatorContainer, .css-1uccc91-singleValue {
                  color: $frolyLight !important;
            }
            .inputField {
                  border-bottom: 1px dotted $frolyLight !important;
                  color: $frolyLight !important;
            }
      }
}

.dlpDescriptionFormWhite {
      //rect-select dropdown
      .selectOption {
            font-size:  13px !important;
            min-width: 150px !important;
            color:  $black1 !important;

            .css-yk16xz-control, .css-1pahdxg-control {
                  border: none !important;
                  background-color: $white3 !important;
                  // border-bottom: 3px solid $gray2 !important;
                  box-shadow: 0 0 0 transparent !important;

                  .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        padding: 0 !important;
                        .css-6q0nyr-Svg {
                              margin-right: 6px;
                        }
                  }
                  // .css-1wa3eu0-placeholder {
                  //       // color:  $black1 !important;
                  //       color: $gray4 !important;
                  //       font-style: italic !important;
                  // }

            }     
            .css-1uccc91-singleValue {
                  color:  $black1 !important;
                  font-weight: normal !important;
            }
            .css-26l3qy-menu {
                  background-color: $white2 !important;
                  // margin-top: 0px !important;
                  box-shadow: 0 0 0 2px $gray2 !important;;
                  
                  .css-1n7v3ny-option {
                        // background-color: $primary;
                        color: $black1 !important;
                  }
                  .css-9gakcf-option {
                        background-color: #B2D4FF !important;
                        color: $black1 !important;
                  }
            }
      }
      .dottedDangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  // border-bottom: 3px dotted $danger !important;
                  border: 1px solid $danger !important
            }
      }      
      .dangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  border-bottom: 1px solid $danger !important;
            }
      }

      // react-muliselect-dropdown
      .multiSelectOption {
            button {
                  font-size:  13px !important;
                  min-width: 200px !important;
                  color:  $black1 !important;
                  padding: 2px 8px !important;
                  border: none !important;
                  background-color: $white3 !important;
                  min-height: 38px !important;
                  // border-bottom: 3px dotted $gray3 !important;
                  .css-1gpjby2 {
                        svg{
                              color: #CCCCCC !important;
                              width: 32px;
                              height: 32px;
                              // margin-right: -20 !important;
                        }
                  }
            }
            div>div {
                  // background-color: transparent !important;
            }
            .css-1r4vtzz {
                  box-shadow: none !important;
            }
            .css-1pcexqc-container {
                  background-color: $white2 !important;
                  padding-top: 4px;
                  color: $black1 !important;
                  min-width: 200px !important;
                  // box-shadow: 0 0 0 1px $muted !important;
                  .css-1jllj6i-control, .css-6y1x9t-control, .css-1jllj6i-control:hover, .css-6y1x9t-control:hover {
                        border-color: $gray2 !important;
                        box-shadow: 0 0 0 1px $gray2 !important;
                        color: $black1 !important;
                        min-width: 200px !important
                  }
                  .css-ik6y5r {
                        min-width: 200px !important;
                  }
                        // .css-151xaom-placeholder { 
                        //       color: $black1 !important;
                        // }
            }
            .css-1g6gooi, .css-1qprcsu-option, .css-1vr111p-option { 
                  color: $black1 !important;
                  min-width: 200px !important;
            }
            .css-1qprcsu-option{
                  font-weight: normal;
            }
      }

      .dottedDangerMultiSelectLine {
            button {
                  // border: 1px solid $danger !important;
                  border: 1px solid $danger !important
            }
      }

      .dangerMultiSelectLine {
            button {
                  border-bottom: 1px solid $danger !important;
            }
      }

      .inputField {
            background-color: transparent !important;
            border: none !important;
            border-bottom: 3px dotted $gray3 !important;
            color: $black1 !important;            
      }
      ::placeholder {
            color: $gray4 !important;
            font-style: italic !important;
      }
      .dottedDangerTextboxLine {
            border-bottom: 3px dotted $danger !important;
      }

      .serviceTreeDropdown {
            .tag-item .search {
                  background-color: $white3 !important;
                  border: none !important;
                  color: $gray4 !important;
                  font-size: 13px;
                  cursor: pointer;
                  min-width: 200px !important;
                  height: 38px;
                  margin-top: -10px !important;
                  padding: 2px 8px !important;
                  margin-left: -6px;
                  position: absolute;
                  font-family: 'Muli', sans-serif !important;
            }
            .dropdown-content {
                  margin-top: 15px;
                  min-width: 190px !important;
                  max-height: 300px !important;
                  overflow: scroll;
                  z-index: 99999 !important;
            }
      
            .tag-list li {
                  display: none
            }
            
            .tag-list li:last-child {
                  display: inline-block
            }
            
            .react-dropdown-tree-select .dropdown .dropdown-trigger {
                  border: none
            }
            
            .react-dropdown-tree-select .dropdown .dropdown-trigger {
                  padding: 0px !important;
                  display: block !important;
            }
            .react-dropdown-tree-select .dropdown .dropdown-trigger:after {
                content: none !important;
            }
            
            input {
                  // background-color: #434A5B !important;
                  background-color: transparent !important;
                  color: #fff !important;
            }
      
            .dropdown {
                  // background-color: #434A5B !important;      
                  background-color: transparent !important;
            }
            
            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                  // color: $gray4 !important;
                  // font-style: italic !important;
                  opacity: 1; /* Firefox */
                  color:  $black1 !important;
                  font-weight: normal !important;
            }
      }
}

.disableInputColor {
      // .form-control {
      //       background-color: gray !important;
      //       color: #fff
      // }
      // .dlpDescriptionForm {
      //       padding: 5px !important;
      //       background-color: $gray4 !important;
      // }
}

.transparentSelectBorderBottom {
      .form-control {
            background-color: transparent;
            border: 0px;
            color: $gray3;
            padding-left: 0px;
            font-size: 12px;
            border-bottom: 1px solid $gray3 !important;
            border-radius: 0;
            height: calc(1em + 1rem + 0px)
      }
}

.timelineAlertGroup {
      .timeline-space div {
            width: 20px
      }
}

.timeline-space-new {
      display: flex;
      margin-left: -6px;   
      margin-top: 20px; //prakash
   
      span {
         height: 11px;
         width: 11px;
         border: 1px solid #D6D6D6;
         border-radius: 50%;
         display: block;
         background-color: #EDEDED;
         position: relative;
         z-index: 9;
      }
   
      div {
         width: 30px;
         height: 1px;
         background-color: #D6D6D6;
         // align-self: center;
         //align-self: center; // parkash
         // margin-left: -4px;
         margin-top: 5px; //prakash
      }
   }

.categoryBlackTheme {
      .selectOption {
            font-size:  13px !important;
            min-width: 150px !important;
            color:  $white1 !important;

            .css-yk16xz-control, .css-1pahdxg-control {
                  // border: none !important;
                  // border-bottom: 3px dotted $gray3 !important;
                  // background-color: transparent !important;
                  box-shadow: 0 0 0 transparent !important;

                  .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        padding: 0 !important;
                  }
                  .css-1wa3eu0-placeholder {
                        color:  $white1 !important;
                  }

            }     
            .css-1uccc91-singleValue {
                  color:  #495057 !important;
            }
            .css-26l3qy-menu {
                  background-color: $black5 !important;
                  // margin-top: 0px !important;
                  box-shadow: 0 0 0 2px $muted !important;
                  
                  .css-1n7v3ny-option {
                        // background-color: $primary;
                        color: $black1 !important;
                  }
                  .css-9gakcf-option {
                        background-color: $info !important;
                        color: $black1 !important;
                  }
            }
      }
} 

.radialTableSection {
      .pagination span {
            color: white;
            float: left;
            padding: 1px 6px;
            text-decoration: none;
            font-size: 14px;
      }
      .pagination span.active {
            background-color: #039BE5;
            color: white;
            border-radius: 5px;
      }
}

.maxWidth40Percent {
      max-width: 40% !important;
}

.resourceScore {
      .input-range__label--min, .input-range__label--max{
            .input-range__label-container {
                  display: none !important;
            }
      }
}

.headerDateRange {
      .rdrDateRangeWrapper {
            font-size: 10px !important;
            // background-color: $white3;
            // color: $white3;
      }
      .rdrDateDisplayWrapper {
            // background-color: $white3;
            // color: $white3;
      }
      .rdrMonthAndYearPickers select {
            // color: $black5 !important;
      }
      // .rdrDateRangeWrapper {
      //       font-size: 10px !important;
      //       background-color: $black5;
      //       // color: $white3;
      // }
      // .rdrDateDisplayWrapper {
      //       background-color: $black5;
      //       // color: $white3;
      // }
      // .rdrMonthAndYearPickers select {
      //       color: $white1 !important;
      // }

} 

.stepperTab{
      display: flex;
      width: 100%;
      // margin: 0px 15px;
      // justify-content: space-between;
      // font-size: 12px;
      overflow: hidden;
      position: relative;
      > div{
      //    margin: 0px 15px;
         white-space: nowrap;
         position: relative;
      //    z-index: 9;
      }
      // &:before{
      //    content: '';
      //    height: 1px;
      //    width: 100%;
      //    background-color: $black5;
      //    position: absolute;
      //    top: 49%;
      // }
      
}

.dlpQueryTab {
      .ace-tomorrow{
            // background-color: $white1;
            // .ace_active-line{
            //       background-color: pink;
            // }
      }
}

::placeholder, .placeholder, .css-1wa3eu0-placeholder {
      color: $gray4 !important;
      font-style: italic !important;
      font-size: 14px;
}

.removeSvgPoint {
      .apexcharts-selection-rect {
            // ry: 3;
            // rx: 3; 
      }
      // .svg_select_points {
      //       display: none;
      // }
      .svg_select_points {
            stroke: #333 !important;
            fill: $gray3 !important;
            opacity: .75 !important;
      }
}
.rounded-5 {
      border-radius: 5px;
}
.rounded-50 {
      border-radius: 50%;
}

.errorDateTimePicker {
      // .form-control {
      //       // height: calc(1.5em + 0.5rem + 2px);
      //       font-size: 12px;
      //       border-radius: 5px;
      //       border: 1px solid #95989E;
      //       background-color: $black5  !important;
      //       height: calc(1em + 1.7rem);            
      // }
      .form-control {
            height: calc(1.5em + 0.5rem + 2px);
            font-size: 12px;
            border-radius: 5px;
            // border: 1px solid #95989E;
            background-color: $black5  !important;
      }
}

.errorSectionScroll {
      min-height: 530px;
      max-height: 900px;
      // overflow: hidden;
      overflow-y: auto;
}

.tabWithSearchScroll {
      min-height: 473px;
      max-height: 750px;
      overflow-y: auto;
}

.metricsTabScroll {
      min-height: 468px;
      max-height: 750px;
      overflow-y: auto;
}

.form-group.styled-feild {
      .form-control-sm {
            background-color: transparent;
            border: 0px;
            color: $white1;
            height: 22px;
            line-height: 16px;
            padding-left: 0px;
            font-size: 12px;
            width: 100%
      }
}

.errorPagePagination {
      .inputClass {
            width: 16px;
            height: 16px;
            font-size: 12px;
            background-color: #C1C1C1;
            text-align: center;
            border: none;
      }
}

//diagnostics slowQuery
.oneLineEllipses {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      // color: $primary-color;
}

.secondLineEllipses {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // color: $primary-color;
}

.slowQueryExpandBtn{
      font-size: 12px;
      padding: 2px;
      background-color: $warning;
}
//diagnostics slowQuery

.separator {
      width: 5px;
      height: 50px;
      border-radius: 3px;
      background-color: #fff;
      margin-top: 5px;
      margin-bottom: 5px
}

.info-timeline {
      ul {
            list-style: none;
            margin: 0;
            padding: 0;
      }
    
      ul li span.timeline-circle {
            position: relative;
            // border: 2px solid #999;
            border-radius: 100%;
            width: 20px;
            line-height: 20px;
            text-align: center;
            margin-top: 45px;
            background-color: $primary-color;
            z-index: 2;
            display: inline-block;
      }
      
      ul li:not(:first-of-type) span.timeline-circle::before {
            position: absolute;
            border: 1px solid $info;
            width: 0;
            height: 45px;
            display: block;
            content: '';
            left: 50%;
            z-index: 1;
            top: -45px;
            margin-left: -1px;

      }
    
      ul li .text{
            font-size: 15px;
            color: $white1;
            font-weight: bold;
      }    
}

.line {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $orange50;
      left: 0;
      align-self: center;
}
.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;  
  border-right: 5px solid $orange50;
}

.zapDesign {
      min-width: 900px;
      // max-width: 70%;
      width: 50%;
      margin: 0 auto;
      .section {
            opacity: 0.5;
            cursor: pointer;
            border-radius: 10px;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: start;
            justify-content: start;
            background-color: $dark;

            .sectionHeader {
                  padding: 18px;            
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-self: start;
                  border-radius: 10px 10px 0 0;
                  .columnHeading {
                        display: flex;
                        flex-direction: column;
                        text-align: left;
            
                        .title {
                              // width: 100%;
                              font-weight: 700;
                              color: $white3;
                        }
                        .description {
                              color: $white3;
                        }
                  }

                  .moreOptions {
                        background: $white1;
                        border-radius: 10px;
                        border: 1px solid $gray2;
                        // box-shadow: rgb(0 0 0 / 20%) 0px 10px 30px 0px;
                        color: $gray4;
                        font-size: 14px;
                        line-height: 40px;
                        margin: 0px 0px 20px;
                        position: absolute;
                        padding: 5px;
                        right: 0px;
                        text-align: left;
                        top: 20px;
                        width: 160px;
                        z-index: 10;

                        button {
                              border: 2px solid transparent;
                              // color: rgb(102, 102, 102);
                              cursor: pointer;
                              font-weight: 600;
                              padding: 0px 10px;
                              width: 100%;
                              height: 40px;
                              display: flex;
                              -webkit-box-align: center;
                              align-items: center;
                              border-radius: 10px;
                              background: $white1;
                        }

                        button:hover {
                              background-color: $white1;
                              color: $blue2;
                              font-weight: 600;
                        }
                  }
            }

            .sectionBody{
                  min-height:200px;
                  padding:10px 20px;
                  .continueButton {
                        // position: relative;
                        bottom: 0;
                        // right: 20px;
                  }
            }
      }
      :hover {
            opacity: 1
      }

      .active {
            opacity: 1;

            .sectionHeader {
                  background-color: $info;
                  border: 1px solid $dark3;
                  .columnHeading {
                        .title,
                        .description {
                              color: $white1;
                        }
                  }
            }
      }

      .lineDown {
            background-color: $gray5;
            height: 16px;
            left: 50%;
            margin-left: -1px;
            position: relative;
            width: 2px;
            z-index: 0;
      }
      .circleDiv {
            height: 23px;
            text-align: center;
            z-index: 0;
      }
      .lineOnCircle {
            background-color: $gray5;
            left: 50%;
            margin-left: -1px;
            position: absolute;
            width: 2px;
            height: 23px;
            z-index: 0;
      }
      .circle {
            background-color: $white1;
            border-radius: 23px;
            // box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 1px;
            cursor: pointer;
            display: inline-block;
            height: 23px;
            position: relative;
            width: 23px;
            /* transition: box-shadow 100ms ease-out 0s, transform 300ms ease-out 0s; */
            z-index: 2;
            animation: 1000ms ease-out 0s 1 normal none running animation-150ug9m;
            transform-origin: center center;
            z-index: 0;
      }
      .plusIcon {
            padding: 3px 6px;
            color: $info;
            font-size: 16px;
            font-weight: normal;
      }
      .downArrow {
            color: $gray5;
            margin-bottom: -5px;
            margin-top: -9px;
            // padding-left: 1px;
            position: relative;
            z-index: 4;
            line-height: 0;
            font-size: 20px;
            text-align: center;
            z-index: 0;
      }
}

.zapInputDark {
      //react select start
      .css-yk16xz-control, .css-yk16xz-control, .css-1pahdxg-control {
            background-color: $muted;
            border-color: $gray5;
            .css-1uccc91-singleValue {
                  color: $white1;
            }
            .css-1wa3eu0-placeholder {
                  color: $gray4 !important;
                  font-style: italic !important;
            }
      }

      .css-1fhf3k1-control { //when disabled true
            background-color: $gray5;
            border-color: transparent;
            .css-107lb6w-singleValue {
                  color: $white1;
            }
      }

      .css-1pahdxg-control {
            box-shadow: 0 0 0 1px $gray3;
      }
      .css-1pahdxg-control:hover{
            border-color: $gray3;
      }
      .css-26l3qy-menu {
            color: $black1;
      }
      .css-b8ldur-Input{ 
            color: $white1;
      }
      //react select end 

      //input box start
      .inputTextbox {
            background-color: $muted;
            border-color: $gray5;
            color: $white1;
            min-height: 38px;
      }

      .inputTextbox:focus {
            background-color: $muted;
            border-color: $gray3;
      }
      //input box end

      //input box sm start
      .inputTextbox-sm {
            background-color: $muted;
            border-color: $gray5;
            color: $white1;
            min-height: 30px;
            max-height: 30px;
      }

      .inputTextbox-sm:focus {
            background-color: $muted;
            border-color: $gray3;
      }
      //input box sm end
}


.dropdownOptions {
      background: $white1;
      border-radius: 10px;
      border: 1px solid $gray2;
      // box-shadow: rgb(0 0 0 / 20%) 0px 10px 30px 0px;
      color: $black1;
      font-size: 14px;
      line-height: 25px;
      margin: 0px 0px 20px;
      position: absolute;
      padding: 5px;
      right: 0px;
      text-align: left;
      top: 60px;
      width: 100%;
      z-index: 10;
      max-height: 350px;
      overflow-y: auto;

      button {
            border: 2px solid transparent;
            // color: rgb(102, 102, 102);
            cursor: pointer;
            font-weight: 600;
            padding: 0px 10px;
            width: 100%;
            min-height: 30px;
            display: flex;
            -webkit-box-align: center;
            // align-items: center;
            border-radius: 10px;
            background: $white1;

            .description{
                  color: $primary-color
            }
            .lable {
                  color: $dark-theme-purple;
                  font-size: 12px;
            }
      }

      button:hover {
            background-color: $hvoerInfo;
            // color: $black1;
            font-weight: 600;
            // .description, .label {
            //       color: $black1
            // }
      }
}

.sectionBlock {
      padding: 10px;
      font-size: 0.875em;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 10px;
      font-weight: 600;
      transition: all 300ms ease-in-out 0s;
      border: 1px solid transparent;
      position: relative;
      margin-bottom: 5px;
}

.sectionBlock:hover {
      background-color: $muted;
      border: 1px solid $black5
}

.zapLabelInput {
      border: none !important;
      border-bottom: 2px solid $gray3 !important;
      background-color: transparent !important;
      color: $white1 !important;
      padding: 0;
      border-radius: 0;
}

.toggleOnOffSwitch {
      /* The switch - the box around the slider */
      .switch {
            position: relative;
            display: inline-block;
            width: 48px;
            height: 25px;
      }
    
      /* Hide default HTML checkbox */
      .switch .inputdemo {
            opacity: 0;
            width: 0;
            height: 0;
      }
    
      /* The slider */
      .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
      }
    
      .slider:before {
            position: absolute;
            content: "";
            height: 21px;
            width: 21px;
            left: 4px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
      }
    
      input:checked + .slider {
            background-color: #2196F3;
      }
    
      input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
      }
    
      input:checked + .slider:before {
            -webkit-transform: translateX(19px);
            -ms-transform: translateX(19px);
            transform: translateX(19px);
      }
    
      /* Rounded sliders */
      .slider.round {
            border-radius: 20px;
            // color: #000;
            // font-size: 13px;
            // font-weight: bold;
            // // text-align: right;
            // padding-left: 27px;
            // padding-top: 5px;
      }
    
      .slider.round:before {
            border-radius: 50%;
      }
}

.toggleOnOffSwitchTable {
      /* The switch - the box around the slider */
      .switch {
            position: relative;
            display: inline-block;
            width: 28px;
            height: 16px;
      }
    
      /* Hide default HTML checkbox */
      .switch .inputdemo {
            opacity: 0;
            width: 0;
            height: 0;
      }
    
      /* The slider */
      .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
      }
    
      .slider:before {
            position: absolute;
            content: "";
            height: 14px;
            width: 14px;
            left: 2px;
            bottom: 1px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
      }
    
      input:checked + .slider {
            background-color: #2196F3;
      }
    
      input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
      }
    
      input:checked + .slider:before {
            -webkit-transform: translateX(13px);
            -ms-transform: translateX(13px);
            transform: translateX(13px);
      }
    
      /* Rounded sliders */
      .slider.round {
            border-radius: 20px;
            // color: #000;
            // font-size: 13px;
            // font-weight: bold;
            // // text-align: right;
            // padding-left: 27px;
            // padding-top: 5px;
      }
    
      .slider.round:before {
            border-radius: 50%;
      }
}

.inputBatch {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      background-color: rgb(242, 242, 242);
      box-sizing: border-box;
      border-radius: 10px;
      color: rgb(66, 66, 66);
      max-height: 50vh;
      overflow-y: auto;
      padding: 0px;
      transition-duration: 300ms;
      transition-property: background-color, color, box-shadow;
      transition-timing-function: ease;
      display: flex;
      -webkit-box-align: stretch;
      align-items: stretch;
      min-height: 40px;

      .textbox {
            position: relative;
            outline: none;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            padding: 1px 15px;
      }
}

.mt-3px {
      margin-top: 3px;
}

.icon-stack {
      position: relative;
      display: inline-block;
      width: 2em;
      height: 2em;
      line-height: 2em;
      vertical-align: middle;
}
.icon-stack-1x,
.icon-stack-3x {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
}
.icon-stack-1x {
      line-height: inherit;
}
.icon-stack-2x {
      font-size: 25px;
      position: absolute;
      margin-left: -10px;
      margin-top: -7px;
      // left: 0;
      // width: 10%;
      text-align: center;
}
.icon-stack-3x {
      font-size: 2em;
}
    

.helpSection {
      background-color: #d4e5fc;
      border-right: 3px solid #136bf5;
      border-bottom: 3px solid #136bf5;
      border-top: 3px solid #136bf5;
      border-left: 6px solid #136bf5;
}

.topAuto {
      top: auto
}
.margin-8 {
      margin-top: -8px;
}

.opacity50 {
      opacity: 0.5;
}

.categoryBoxSection {
      min-width: 205px;
      max-width: 220px;
}

.ellipseCategoryBoxSection {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      // color: $primary-color;
      word-break: break-all;
}

.transparentSelect{
      .css-yk16xz-control,
      .css-1pahdxg-control {
            background-color: transparent;
      }
      .css-26l3qy-menu {
            width: auto
      }
}

.transparentMultiSelect {      
      button {
            padding: 0px;
            font-size: 12px;
            box-shadow: none;
            width: 100%;
            background-color: transparent;
            color: #fff;
            font-size: 14px;
      }
      .css-1jllj6i-control,
      .css-6y1x9t-control,
      .css-1qprcsu-option {
            color: #000;
            // width: auto !important; 
      }
      .css-1vr111p-option, .css-1qprcsu-option {
            color: #000;
            font-size: 12px;
            font-family: 'Muli', sans-serif !important;
      }
}
 
.curvedDropdown {     
      .dropdown {
            padding-top: 0px //2px
      } 
      .dropdown-toggle::after{
            display:inline-block;
            margin-top:0.65em;
            vertical-align:0.255em;
            content:"";
            border-top:0.3em solid;
            border-right:0.3em solid transparent;
            border-bottom:0;
            border-left:0.3em solid transparent
      }
      .dropdown-menu {
            margin-top: 13px;
            margin-left: -6px;
      }
      .dropdown-menu>li {
            padding: 2px 5px;
            font-size: 14px;
      }
      .dropdown-menu>li:hover {
            background-color: $hvoerInfo;
      }
      .dropdown-menu .selected {
            background-color: #B2D4FF;
            color: $black1;
      }
}

.selectDarkTheme {
      .css-yk16xz-control,
      .css-1pahdxg-control {
         // background-color: #434A5B; //prakash
         background-color: $black5;
         border-color: transparent;
         min-height: 40px;
         max-height: 40px;
         border-width: 0px;
         box-shadow: none;
      }

      .css-1pahdxg-control, .css-yk16xz-control {
            border-color: hsl(0%,0%,80%);
            box-shadow: none;
      }

      .css-1fhf3k1-control { //when disabled true
            background-color: $gray5;
            border-color: transparent;
            .css-107lb6w-singleValue {
                  color: $white1;
            }
      }
   
      .css-1uccc91-singleValue,
      .css-b8ldur-Input,
      .css-1hb7zxy-IndicatorsContainer {
         color: $white1;
      }
   
      .css-1okebmr-indicatorSeparator {
         display: none;
      }
      
      .css-2613qy-menu {
         font-size: 10px;
      }

      //for menu option backgorud black
            // .css-4ljt47-MenuList {
            //       background-color: $muted;
            //       color: $white1;
            // }
            // .css-4ljt47-MenuList>div {
            //       color: $white1;
            // }
            // .css-4ljt47-MenuList>div:hover {
            //       background-color: $hvoerInfo;
            //       color: $black1;
            // }
      //for menu option backgorud black

      .css-1n7v3ny-option, .css-yt9ioa-option {
            // background-color: $primary;
            color: $black1 !important;
            font-size: 12px;
      }
      .css-9gakcf-option {
            background-color: $info !important;
            color: $white1 !important;
            font-size: 12px;
      }
}

.inputDarkTheme, .inputDarkTheme:focus {
      background-color: $black5;
      border-color: transparent;
      color: $white1;
}

.inputDarkThemeDisabled {
      background-color: $gray5;
      border-color: transparent;
      color: $white1;
}

.multiselectDarkTheme {
      font-size: 13px !important;
      max-width: 100% !important;

   
      button {
         padding: 4px 9px;
         font-size: 12px;
         box-shadow: none;
         background-color: $muted;
         color: $white1
         // width: 100%
      }

      .css-1v99tuv {
            font-size: 13px !important;
            padding: 0.375rem 0rem;
            // min-width: 155px !important;
      }
      // div>div {
      // //    position: relative !important;
      //    min-width: 100% !important;
      // }
      .css-1r4vtzz, 
      .css-48ayfv {
            border: 1px solid $gray5 !important;
            border-radius: 5px;
            min-width: 100% !important;      
      }
      
      .css-1pcexqc-container {
            // min-width: 200px !important;
            // max-width: 100% !important;
            // background-color: $gray3 !important;
            // position: absolute !important;
            // border: 1px solid $muted !important;
      }
      //for menu option backgorud black
            .css-11unzgr {
                  max-height: 200px !important;
                  padding-left: 4px;
                  color: $black1;
                  // background-color: $muted;
            }
            // .css-1vr111p-option {
            //       background-color: $muted;
            //       color: $white1
            // }
            // .css-1vr111p-option:hover {
            //       // background-color: $muted;
            //       color: $black1
            // }
      //for menu option backgorud black
      .css-1qprcsu-option {
            background-color: #2684FF;
            color: $white1;
            font-size: 13px;
      }
      //checkbox section
      .css-1vr111p-option input[type="checkbox"],
      .css-1qprcsu-option input[type="checkbox"] {
            vertical-align: middle !important;
      }
      .css-1jllj6i-control, .css-1jllj6i-control:hover {
            // max-width: 100% !important;
            // min-width: 90% !important;
            width: auto !important;
            // margin-left: 5px !important;
            // margin-right: 5px !important;
            border-color: $muted;
            box-shadow: 0 0 0 1px $gray3
      }
      .css-6y1x9t-control, .css-6y1x9t-control:hover {
            border-color: $muted;
            box-shadow: 0 0 0 1px $gray3;
            // margin-left: 5px !important;
            // margin-right: 5px !important;
            // border-color: $muted;
            // box-shadow: 0 0 0 1px $gray3;
      }
      // .css-6y1x9t-control:hover {
      //       border-color: $muted;
      //       box-shadow: 0 0 0 1px $gray3;
      // }  
}

.multiselectDarkTheme > div > div {
      min-width: 100%;
}

.multiselectDarkThemeFilter {
      font-size: 12px !important;
      max-width: 100% !important;
      z-index: 9999;
      position: absolute;
      

   
      button {
         padding: 4px 9px;
         font-size: 12px;
         box-shadow: none;
         background-color: transparent;
         color: $white1;
         // width: 100%
      }

      .css-1v99tuv {
            font-size: 12px !important;
            padding: 0.375rem 0rem;
            // min-width: 155px !important;
      }
      // div>div {
      // //    position: relative !important;
      //    min-width: 100% !important;
      // }
      .css-1r4vtzz, 
      .css-48ayfv {
            border: none !important;
            border-radius: 5px;
            min-width: 150px !important;      
      }
      
      .css-1pcexqc-container {
            // min-width: 200px !important;
            // max-width: 100% !important;
            // background-color: $gray3 !important;
            // position: absolute !important;
            // border: 1px solid $muted !important;
      }
      //for menu option backgorud black
            .css-11unzgr {
                  max-height: 250px !important;
                  padding-left: 4px;
                  color: $black1;
                  // background-color: $muted;
            }
            // .css-1vr111p-option {
            //       background-color: $muted;
            //       color: $white1
            // }
            // .css-1vr111p-option:hover {
            //       // background-color: $muted;
            //       color: $black1
            // }
      //for menu option backgorud black
      .css-1qprcsu-option {
            background-color: #2684FF;
            color: $white1;
            font-size: 13px;
      }
      //checkbox section
      .css-1vr111p-option input[type="checkbox"],
      .css-1qprcsu-option input[type="checkbox"] {
            vertical-align: middle !important;
      }
      .css-1jllj6i-control {
            // max-width: 100% !important;
            // min-width: 90% !important;
            width: auto !important;
            // margin-left: 5px !important;
            // margin-right: 5px !important;
            border-color: $muted;
            box-shadow: 0 0 0 1px $gray3
      }
      .css-6y1x9t-control {
            margin-left: 5px !important;
            margin-right: 5px !important;
            // border-color: $muted;
            // box-shadow: 0 0 0 1px $gray3;
      }
      .css-6y1x9t-control:hover {
            border-color: $muted;
            box-shadow: 0 0 0 1px $gray3;
      }  
}

.multiselectDarkThemeFilter > div > div {
      position: relative !important;
      min-width: 100%;
}

.tag-lg {
	background: $black2;
	border-radius: 3px 0 0 3px;
	color: #999;
	display: inline-block;
	height: 26px;
	line-height: 26px;
	padding: 0 20px 0 23px;
	position: relative;
	margin: 0 10px 10px 0;
	text-decoration: none;
	-webkit-transition: color 0.2s;
}

.tag-lg::before {
	background: #fff;
	border-radius: 10px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
	content: '';
	height: 6px;
	left: 10px;
	position: absolute;
	width: 6px;
	top: 10px;
}

.tag-lg::after {
	background: $black2;
	border-bottom: 13px solid transparent;
	border-left: 10px solid $black2;
	border-top: 13px solid transparent;
	content: '';
	position: absolute;
	right: 0;
	top: 0;
}

.tag-lg:hover {
	background-color: $black1;
	text-decoration: none;
	color: white;
}

.tag-lg:hover::after {
	border-left-color: $black1;
}

.darkThemeTable {
      .table-striped tbody tr:nth-of-type(odd) {
            background-color: $muted;
            color: $white1;
      }
      .table-striped tbody tr:nth-of-type(even) {
            color: $white1;
      }
}

.right {
      // height: 90px;
      width: 30%;
      // border: 10px dashed lightblue;
      // border-radius: 5px;
      // background-color: lightyellow;
      float: right;
      position: fixed;
      // margin-top: 5px;
      right: 0;
}

.contentEditable div, .contentEditable span {
      color: $white1;
      background: transparent !important;
      background-color: transparent !important;
}

[contenteditable=true]:empty:before{
      content: attr(placeholder);
      pointer-events: none;
      display: block; /* For Firefox */

      color: $gray4 !important;
      font-style: italic !important;
      font-size: 14px;
}

.text-decoration-line-through {
      text-decoration: line-through;
      text-decoration-color: $muted;
      text-decoration-thickness: 2px;
}

.list-group, .list-group-item-black {
      background-color: $dark3 !important;
      border-radius: round;
}

//react-datetime picker
.rdtDay {
      color: $black1;
}


//dark theme badge in react-select ismulti true
.darkThemeSelectMulti {
      .css-1rhbuit-multiValue {
            background-color: $dark3 !important;
            .css-12jo7m5, .css-xb97g8 {
                  // background-color: $dark3;
                  color: $white1 !important;
            }
      }
}
//dark theme badge in react-select ismulti true

.box-shadow-none {
      box-shadow: none !important
}

.table-small th, .table-small td {
      padding: 0.4rem 0.75rem !important;
}

.h-90 {
      height: 90%;
}
.h-95 {
      height: 95%;
}

.datetimeDarkInput {
      input {
            background-color: $black5;
            border: 1px solid $gray5 !important;
            color: $white1;
            cursor: pointer
      }
      .form-control:focus {
            color: $white1;
            background-color: $black5;
            border: 1px solid $gray5 !important;
            outline: 0;
            box-shadow: none;
      }
}

.apexCommonDonutChart {
      .apexcharts-legend-text{
            word-break: break-all;
      }
}


//notFount css start
#notfound {
      position: relative;
      height: 100vh;
}
#notfound .notfound {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}
.notfound .notfound-404 {
      position: relative;
      height: 200px;
      margin: 0px auto 20px;
      z-index: -1;
}
.notfound .notfound-404 h1 {
      // font-family: 'Montserrat', sans-serif;
      font-size: 236px;
      font-weight: 200;
      margin: 0px;
      color: $white1;
      text-transform: uppercase;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}
.notfound .notfound-404 h2 {
      // font-family: 'Montserrat', sans-serif;
      font-size: 24px !important;
      font-weight: 400 !important;
      text-transform: uppercase;
      color: $white1;
      background: $muted;
      padding: 10px 5px;
      margin: auto;
      display: inline-block;
      position: absolute;
      
      left: 50%;
      bottom: 0%;
      -webkit-transform: translate(-50%, -10%);
      -ms-transform: translate(-50%, -10%);
      transform: translate(-50%, -10%);
}

.notfound a {
      // font-family: 'Montserrat', sans-serif;
      display: inline-block;
      font-weight: 700;
      text-decoration: none;
      color: #fff;
      text-transform: uppercase;
      padding: 13px 23px;
      background: #ff6300;
      font-size: 18px;
      -webkit-transition: 0.2s all;
      transition: 0.2s all;
}       

//notfound css end

.obsSectionHeight {
      max-height: 790px !important;
      min-height: 790px !important;
}

.obsSectionTableHeight {
      max-height: 730px !important;
      min-height: 10px !important;
}

.obsSectionBodyHeight {
      max-height: 712px !important;
      min-height: 712px !important;
}
.obsMessageSection {
      max-height: 465px !important;
      min-height: 465px !important;
      overflow-y: auto;
}

.obsMessageSectionLg {
      max-height: 570px !important;
      min-height: 570px !important;
      overflow-y: auto;
}

.obsMessageSectionXL {
      max-height: 670px !important;
      min-height: 670px !important;
      overflow-y: auto;
}

.uncontrolledTooltipLeftAlign {
      text-align: left;

}

.obsDateTimePicker       {
      .react-minimal-datetime-range-calendar__table-cel {
            width: 38px !important;
      }
      .react-minimal-datetime-range__range-input-wrapper{
            display: none
      }
}

.RangePickerWithStartEndTime {
      .react-minimal-datetime-range-calendar__table-cel {
            width: 38px !important;
      }
      .react-minimal-datetime-range__range{
            height: 370px !important;
      }
      .react-minimal-datetime-range__range-input-wrapper{
            display: none
      }
      .react-minimal-datetime-range {
            width: 610px;
            // background-color: $muted;
            // color: $white1;
      }
      .react-minimal-datetime-range__col {
            // color: $white1 !important;
      }
      .react-minimal-datetime-range__button--type {
            display: none;
      }
}

.verticalArrowDown {
      .vl {
            border-left: 3px solid $gray3;
      }
      .arrow {
            border: solid $gray3;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            margin-left:-6px;
      }
      .down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
      }
      .lineText {
            // top: 20%;
            // margin-top: -100px;
            // position: absolute;
            color: $gray3;
            font-size: 12px;
            margin-left: 5px;
      }
}

.overflowYAuto {
      overflow-y: auto !important;
}

.overflowXAuto {
      overflow-x: auto !important;
}

.stickyTableHeader {
      .table th{
            // position: fixed;
            // left: 0;
            // background-color: $dark2;
            // border-bottom: none;
            position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
            position: sticky;
            top: 0;
            z-index: 1; // any positive value, layer order is global
            background: $dark2; // any bg-color to overlap
      } 
}

.stickyTable {
      .table {
            cursor: pointer;
      }
      // .table thead {
      //       // position: sticky;
      //       left: 0;
      //       position:relative;
      //       display: block;
            
      // }
      // table th {
      //       position: sticky;
      // }
      .table th:first-child,
      table td:first-child {
            position: sticky;
            left: 0;
            background-color: $dark2;
            border-bottom: none;
            min-width: 300px !important;
            // color: #373737;
            // border-right:2px solid $black4
      }      

      th:nth-child(2),
      td:nth-child(2) {
         position: sticky !important;
         left: 300px !important;
         background-color: $dark3;
         // z-index:1;
      }
      .table td,
      .table th {
            min-width: 150px !important;
            // max-width: 200px;
      }
}

.stickyFirstColumnTable {
      .table {
            cursor: pointer;
      }
      .table th:first-child,
      table td:first-child {
            position: sticky;
            left: 0;
            background-color: $dark2;
            border-bottom: none;
            min-width: 300px !important;
            // color: #373737;
            // border-right:2px solid $black4
      }
      .table td,
      .table th {
            min-width: 150px !important;
            max-width: 150px !important;
            width: 150px;
      }
}

.wordBreakAll {
      word-break: break-all;
}

.resourceDashboardFilter {
      min-width: 500px;
      // padding: 15px !important; 
      right: 20px;
      position: absolute;
      z-index: 9;
}

.resourcePopupFilterSection {
      min-width: 600px;
      // padding: 15px !important; 
      right: 150px;
      top: 65px;
      position: absolute;
      z-index: 9;
}

.displayBlock {
      display: block;
}

.comparisonOption {
      align-items: center;
      display: flex;
      justify-content: center;
      p {
            // margin: 45% auto 0 auto !important;
            text-align: center;
            font-size: 25px !important;
      }
      &:hover {
            background-color: $dark3 !important;
            color: #FFF !important;
            
            p {
                  font-size: 30px !important;
            }
      }
}

.whiteSpaceNoarmal {
      white-space: normal !important;
}

.whiteSpaceUnset {
      white-space: unset !important;
}

.tableLayout {
      table-layout: fixed
}

.darkTableHover tbody tr:hover {
   background-color: $dark3 !important;
}

.readonlyDatePickerInput {
      background-color: $black5 !important;
      border-color: transparent;
      color: $white1;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkboxGrayColor {
      input {
            // position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
            // position: absolute;
            top: 0;
            left: 0;
            height: 13px;
            width: 13px;
            background-color: $gray4;
      }
}

.bg-new {
      // background-color: #5B4FCF;
      // background-color: #A8B6F7;
      background-color: #212946;
}


.observabilityEventDashboardFilter {
      min-width: 500px;
      // padding: 15px !important; 
      left: 200px;
      position: absolute;
      z-index: 9;
}

input.larger {
      width: 15px;
      height: 15px;
}

.containerFilterSection {
      .selectOption {
            font-size:  13px !important;
            min-width: 150px !important;
            color:  $white1 !important;

            .css-yk16xz-control, .css-1pahdxg-control, .css-1fhf3k1-control {
                  border: none !important;
                  background-color: transparent !important;
                  border-radius: 0px;
                  border-bottom: 1px dotted $gray3 !important;
                  box-shadow: 0 0 0 transparent !important;

                  .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        padding: 0 !important;
                  }
                  .css-1wa3eu0-placeholder {
                        color:  $white1 !important;
                  }
                  .css-b8ldur-Input {
                        color:  $white1 !important;
                  }

                  

            }     
            .css-1fhf3k1-control {
                  .placeholder, .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        color:  $gray5 !important;
                  }
            }

            .css-1uccc91-singleValue {
                  color:  $white1 !important;
            }
            .css-26l3qy-menu {
                  background-color: $black5 !important;
                  // margin-top: 0px !important;
                  box-shadow: 0 0 0 2px $muted !important;
                  min-width: 100%;
                  width: max-content;
                  .css-1n7v3ny-option {
                        // background-color: $primary;
                        color: $black1 !important;
                  }
                  .css-9gakcf-option {
                        background-color: $info !important;
                        color: $black1 !important;
                  }
            }
      }
      .selectOptionsm {
            font-size:  13px !important;
            min-width: 120px !important;
            color:  $white1 !important;

            .css-yk16xz-control, .css-1pahdxg-control, .css-1fhf3k1-control {
                  border: none !important;
                  background-color: transparent !important;
                  border-radius: 0px;
                  border-bottom: 1px dotted $gray3 !important;
                  box-shadow: 0 0 0 transparent !important;

                  .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        padding: 0 !important;
                  }
                  .css-1wa3eu0-placeholder {
                        color:  $white1 !important;
                  }
                  .css-b8ldur-Input {
                        color:  $white1 !important;
                  }

                  

            }     
            .css-1fhf3k1-control {
                  .placeholder, .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        color:  $gray5 !important;
                  }
            }

            .css-1uccc91-singleValue {
                  color:  $white1 !important;
            }
            .css-26l3qy-menu {
                  background-color: $black5 !important;
                  // margin-top: 0px !important;
                  box-shadow: 0 0 0 2px $muted !important;
                  min-width: 100%;
                  width: max-content;
                  .css-1n7v3ny-option {
                        // background-color: $primary;
                        color: $black1 !important;
                  }
                  .css-9gakcf-option {
                        background-color: $info !important;
                        color: $black1 !important;
                  }
            }
      }
      .dangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  border-bottom: 1px solid $danger !important;
            }
      }
      .dottedDangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  border-bottom: 1px dotted $danger !important;
            }
      }
            // react-muliselect-dropdown
      .multiSelectOption {
            button {
                  font-size:  13px !important;
                  min-width: 200px !important;
                  color:  $white1 !important;
                  padding: 0 !important;
                  border: none !important;
                  background-color: transparent !important;
                  border-bottom: 1px dotted $gray3 !important;
            }
            div>div {
                  background-color: transparent !important;
            }
            .css-1pcexqc-container {
                  background-color: $black5 !important;
                  padding-top: 4px;
                  color: $white1 !important;
                  min-width: 200px !important;
                  width: max-content;
                  // box-shadow: 0 0 0 1px $muted !important;
                  .css-1jllj6i-control, .css-6y1x9t-control, .css-1jllj6i-control:hover, .css-6y1x9t-control:hover {
                        border-color: $muted !important;
                        box-shadow: 0 0 0 1px $muted !important;
                        color: $white1 !important;
                        min-width: 200px !important
                  }
                  .css-ik6y5r {
                        min-width: 200px !important;
                  }
                        // .css-151xaom-placeholder { 
                        //       color: $white1 !important;
                        // }
            }
            .css-1g6gooi, .css-1qprcsu-option, .css-1vr111p-option { 
                  color: $white1 !important;
                  min-width: 200px !important;
            }
            .css-1v99tuv {
                  font-size: 15px;
                  padding-bottom: 5px;
            }
      }
      .multiSelectOptionSm {
            button {
                  font-size:  13px !important;
                  min-width: 120px !important;
                  color:  $white1 !important;
                  padding: 0 !important;
                  border: none !important;
                  background-color: transparent !important;
                  border-bottom: 1px dotted $gray3 !important;
            }
            div>div {
                  background-color: transparent !important;
            }
            .css-1pcexqc-container {
                  background-color: $black5 !important;
                  padding-top: 4px;
                  color: $white1 !important;
                  min-width: 120px !important;
                  width: max-content;
                  // box-shadow: 0 0 0 1px $muted !important;
                  .css-1jllj6i-control, .css-6y1x9t-control, .css-1jllj6i-control:hover, .css-6y1x9t-control:hover {
                        border-color: $muted !important;
                        box-shadow: 0 0 0 1px $muted !important;
                        color: $white1 !important;
                        min-width: 120px !important
                  }
                  .css-ik6y5r {
                        min-width: 120px !important;
                  }
                        // .css-151xaom-placeholder { 
                        //       color: $white1 !important;
                        // }
            }
            .css-1g6gooi, .css-1qprcsu-option, .css-1vr111p-option { 
                  color: $white1 !important;
                  min-width: 120px !important;
            }
            .css-1v99tuv {
                  font-size: 15px;
                  padding-bottom: 5px;
            }
      }

      .dangerMultiSelectLine {
            button {
                  border-bottom: 1px solid $danger !important;
            }
      }
      
      .dottedDangerMultiSelectLine {
            button {
                  border-bottom: 1px dotted $danger !important;
            }
      }

      .serviceTreeDropdown  {
            width: 150px;
            border-bottom: 1px dotted $gray3 !important;
            .react-dropdown-tree-select {
                  margin-bottom: 0.9rem !important;
            }
      }
      
      .inputField {
            background-color: transparent !important;
            border: none !important;
            border-bottom: 1px dotted $gray3 !important;
            color: $white1 !important;
      }
      .dottedTextboxLine {
            border-bottom: 1px solid $danger !important;
      }
      .dottedDangerTextboxLine {
            border-bottom: 1px dotted $danger !important;
      }
}

.containerBorderFilterSection {
      .selectOption {
            font-size:  13px !important;
            min-width: 150px !important;
            color:  $white1 !important;

            .css-yk16xz-control, .css-1pahdxg-control, .css-1fhf3k1-control {
                  // border: none !important;
                  background-color: $black5 !important;
                  border-radius: 0px;
                  // border-bottom: 1px dotted $gray3 !important;
                  box-shadow: 0 0 0 $muted !important;
                  border-color: $muted;

                  // .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                  //       padding: 0 !important;
                  // }
                  .css-1wa3eu0-placeholder {
                        color:  $white1 !important;
                  }
                  .css-b8ldur-Input {
                        color:  $white1 !important;
                  }

                  

            }     
            .css-1fhf3k1-control {
                  .placeholder, .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        color:  $gray5 !important;
                  }
            }

            .css-1uccc91-singleValue {
                  color:  $white1 !important;
                  min-width: 100%;
            }
            .css-26l3qy-menu {
                  background-color: $black5 !important;
                  // margin-top: 0px !important;
                  box-shadow: 0 0 0 2px $muted !important;
                  min-width: 100%;
                  width: max-content;
                  .css-1n7v3ny-option {
                        // background-color: $primary;
                        color: $black1 !important;
                  }
                  .css-9gakcf-option {
                        background-color: $info !important;
                        color: $black1 !important;
                  }
            }
      }
      .selectOptionsm {
            font-size:  13px !important;
            min-width: 120px !important;
            color:  $white1 !important;

            .css-yk16xz-control, .css-1pahdxg-control, .css-1fhf3k1-control {
                  // border: none !important;
                  background-color: $black5 !important;
                  border-radius: 0px;
                  // border-bottom: 1px dotted $gray3 !important;
                  box-shadow: 0 0 0 $muted !important;

                  .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        padding: 0 !important;
                  }
                  .css-1wa3eu0-placeholder {
                        color:  $white1 !important;
                  }
                  .css-b8ldur-Input {
                        color:  $white1 !important;
                  }

                  

            }     
            .css-1fhf3k1-control {
                  .placeholder, .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        color:  $gray5 !important;
                  }
            }

            .css-1uccc91-singleValue {
                  color:  $white1 !important;
                  min-width: 100%;
            }
            .css-26l3qy-menu {
                  background-color: $black5 !important;
                  // margin-top: 0px !important;
                  box-shadow: 0 0 0 2px $muted !important;
                  min-width: 100%;
                  width: max-content;
                  .css-1n7v3ny-option {
                        // background-color: $primary;
                        color: $black1 !important;
                  }
                  .css-9gakcf-option {
                        background-color: $info !important;
                        color: $black1 !important;
                  }
            }
      }
      .dangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  // border-bottom: 1px solid $danger !important;
            }
      }
      .dottedDangerSelectLine {
            .css-yk16xz-control, .css-1pahdxg-control {
                  // border-bottom: 1px dotted $danger !important;
            }
      }
            // react-muliselect-dropdown
      .multiSelectOption {
            button {
                  font-size:  13px !important;
                  min-width: 300px !important;
                  color: $white1 !important;
                  padding: 2px 8px !important;
                  // background-color: $muted;
                  background-color: $black5;
                  border-color: $muted;
                  border-radius: 4px;
                  border-style: solid;
                  border-width: 1.5px;
                  min-height: 38px !important;
                  // border-bottom: 1px dotted $gray3 !important;
            }
            div>div {
                  // background-color: transparent !important;
            }
            .css-1pcexqc-container, .css-ssi0ig-container {
                  background-color: $black5 !important;
                  padding-top: 4px;
                  color: $white1 !important;
                  min-width: 200px !important;
                  width: max-content;
                  // box-shadow: 0 0 0 1px $muted !important;
                  .css-1jllj6i-control, .css-6y1x9t-control, .css-1jllj6i-control:hover, .css-6y1x9t-control:hover, .css-ssi0ig-container, .css-ssi0ig-container:hover {
                        background-color: transparent !important;
                        border-color: $muted !important;
                        box-shadow: 0 0 0 1px $muted !important;
                        input{
                              display: none;
                        }
                        color: $white1 !important;
                        min-width: 200px !important
                  }
                  .css-ik6y5r {
                        min-width: 200px !important;
                  }
                        // .css-151xaom-placeholder { 
                        //       color: $white1 !important;
                        // }
            }
            .css-1g6gooi, .css-1qprcsu-option, .css-1vr111p-option { 
                  background-color: transparent !important;
                  color: $white1 !important;
                  min-width: 200px !important;
            }
            // .css-1vr111p-option:hover {
            //       color: $black1 !important;
            // }
            .css-ssi0ig-container { //disabled
                  .css-1vr111p-option {
                        color: $gray3 !important;
                        input{
                              display: none;
                        }
                  }
            }
            .css-1v99tuv {
                  font-size: 15px;
                  padding-bottom: 5px;
            }
      }
      .multiSelectOptionSm {
            button {
                  font-size:  13px !important;
                  min-width: 120px !important;
                  color:  $white1 !important;
                  padding: 0 !important;
                  // border: none !important;
                  background-color: $black5 !important;
                  // border-bottom: 1px dotted $gray3 !important;
            }
            div>div {
                  background-color: $black5 !important;
            }
            .css-1pcexqc-container {
                  background-color: $black5 !important;
                  padding-top: 4px;
                  color: $white1 !important;
                  min-width: 120px !important;
                  width: max-content;
                  // box-shadow: 0 0 0 1px $muted !important;
                  .css-1jllj6i-control, .css-6y1x9t-control, .css-1jllj6i-control:hover, .css-6y1x9t-control:hover {
                        background-color: transparent !important;
                        border-color: $muted !important;
                        box-shadow: 0 0 0 1px $muted !important;
                        color: $white1 !important;
                        min-width: 120px !important
                  }
                  .css-ik6y5r {
                        min-width: 120px !important;
                  }
                        // .css-151xaom-placeholder { 
                        //       color: $white1 !important;
                        // }
            }
            .css-1g6gooi, .css-1qprcsu-option, .css-1vr111p-option { 
                  color: $white1 !important;
                  min-width: 120px !important;
            }
            .css-1vr111p-option:hover {
                  color: $black1 !important;
            }
            .css-1v99tuv {
                  font-size: 15px;
                  padding-bottom: 5px;
            }
      }

      .dangerMultiSelectLine {
            button {
                  // border-bottom: 1px solid $danger !important;
            }
      }
      
      .dottedDangerMultiSelectLine {
            button {
                  // border-bottom: 1px dotted $danger !important;
            }
      }

      .serviceTreeDropdown  {
            width: 150px;
            // border-bottom: 1px dotted $gray3 !important;
            .react-dropdown-tree-select {
                  margin-bottom: 0.9rem !important;
            }
      }
      
      .inputTextField {
            background-color: $black5 !important;
            // border: none !important;
            // border-bottom: 1px dotted $gray3 !important;
            border-color: $muted;
            color: $white1 !important;
      }
      .dottedTextboxLine {
            // border-bottom: 1px solid $danger !important;
      }
      .dottedDangerTextboxLine {
            // border-bottom: 1px dotted $danger !important;
      }
}

.ellipsisDropDownBlack {
      .dropdown-menu {
            background-color: $black6 !important;
            .dropdown-item{
                  height: auto !important;
                  color: $white1 !important;
            }
            .fa {
                  color: $black6 !important;
            }
            .dropdown-item:hover, .dropdown-item:focus {
                  background-color: $muted !important;
            }
      }
}

.resize {
      border: 1px solid black; 
      overflow:auto;
}

.resize.horizontal {
      resize: horizontal;
}

.react-resizable-handle {
      position: absolute;
      width: 20px;
      height: 20px;
      //bottom: 0;
      top: 50%;   
      right: 0;
      // left: 430px;
      // background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+);
      background-position: bottom right;
      height: 25px;
      width: 10px;
      background-color: $info;
      padding: 0 3px 3px 0;
      background-repeat: no-repeat;
      background-origin: content-box;
      box-sizing: border-box;
      cursor: se-resize;

}

.accountLeftSectionNormal {
      flex: 0 0 25%;
      max-width: 25%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
}

.accountLeftSectionMinimized {      
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
}

.border-radius-0 {
      border-radius: 0 !important;
}


.featureBox {
   flex: none;
   &:hover {
      text-decoration: none;
   }

   .sectionBox {
      min-width: 240px;
      max-width: 240px;
      // background-color: #E0E0E0;
   }
}


.currentFeatureBox {
      flex: none;
      &:hover {
         text-decoration: none;
      }
   
      .sectionBox {
         min-width: 200px;
         max-width: 200px;
         // background-color: #E0E0E0;
      }
   }



.costPopupTagSection {
      min-width: 600px;
      max-width: 600px;
      // padding: 15px !important; 
      // right: 150px;
      // top: 65px;
      position: absolute;
      z-index: 9;
}

.break {
      flex-basis: 100%;
      height: 0;
}

.darkThemeRangePicketPopup {
      .react-minimal-datetime-range-dropdown .react-minimal-datetime-range-dropdown-calendar__menu, .react-minimal-datetime-range-dropdown-calendar__month-item, .react-minimal-datetime-range-dropdown-calendar__month-item>div {
            background-color: $muted !important;
            color: $white1;
            .active, .active>div {
                  background-color: #339af0 !important;
            }

      }
      .react-minimal-datetime-range-dropdown-calendar__year, .react-minimal-datetime-range-dropdown-calendar__year-item>span {
            background-color: $muted !important;
            .active, .active>span {
                  background-color: #339af0 !important;
            }
      }
      .react-minimal-datetime-range {
            background-color: $muted;
            color: $white1;
      }
      .react-minimal-datetime-range-calendar__table-cel {
            background-color: $muted;
            color: $white1;
      }
      .react-minimal-datetime-range-calendar__table-row .highlight {
            // background-color: #e0aaff;
            color: $black1;
            font-weight: bold;
      }
      .react-minimal-datetime-range-calendar__table-row .active {
            // background-color: #9d4edd !important;
            color: $black1;
            font-weight: bold;
      }
      .react-minimal-datetime-range-calendar__table-cel.react-minimal-datetime-range-calendar__date-item:not(.disabled):hover {
            // background-color: #9d4edd !important;
            color: $black1;
            font-weight: bold;
      }      
}


.compare-cost-header-label-div {
      width: 16.113%;
}
.compare-cost-header-content-div {
      width: 83.887%;
}

.compare-cost-header {
      max-width: 20.7080066666666667%;
      min-width: 20.7080066666666667%;
      // padding: 0px 2.5% 0px 1.5%;
      padding-top: 1.019rem !important;
      padding-bottom: 1.019rem !important;
      margin: 0px 1.3%;
      background-color: $black3;
}

.compare-cost {
      max-width: 23.666666666666667%;
      min-width: 23.666666666666667%;
      // padding: 0px 2.5% 0px 1.5%;
      padding-top: 1.019rem !important;
      padding-bottom: 1.019rem !important;
      margin: 0px 1.5%;
      background-color: $black3;
}

.compare-cost-service {
      // max-width: 23.666666666666667%;
      // min-width: 23.666666666666667%;
      // padding: 0px 2.5% 0px 1.5%;
      padding-top: 1.032rem !important;
      padding-bottom: 1.032rem !important;
      // margin: 0px 1.5%;
      // background-color: $black3;
}

.compare-cost-child {
      max-width: 23.666666666666667%;
      min-width: 23.666666666666667%;
      // padding: 0px 2.5% 0px 1.5%;
      padding-top: 1.019rem !important;
      padding-bottom: 1.019rem !important;
      margin: 0px 1.5%;
      background-color: $muted;
}

.topFilterTags {
      //rect-select dropdown
      .selectOption {
            font-size:  13px !important;
            min-width: 150px !important;
            color: $white1 !important;

            .css-yk16xz-control, .css-1pahdxg-control {
                  // background-color: $muted;
                  background-color: #383E4D;
                  // border-bottom: 3px solid $gray2 !important;
                  border-color: #858B9A;
                  box-shadow: 0 0 0 transparent !important;

                  .css-tlfecz-indicatorContainer, .css-1hb7zxy-IndicatorsContainer, .css-1gtu0rj-indicatorContainer {
                        padding: 0 !important;
                        .css-6q0nyr-Svg {
                              margin-right: 6px;
                        }
                  }
                  // .css-1wa3eu0-placeholder {
                  //       // color:  $white1 !important;
                  //       color: $gray4 !important;
                  //       font-style: italic !important;
                  // }

            }     
            .css-1uccc91-singleValue {
                  background-color: #383E4D;
                  color: $white1 !important;
                  font-weight: normal !important;
            }
            .css-26l3qy-menu {
                  width: fit-content;
                  background-color: $white2 !important;
                  // margin-top: 0px !important;
                  box-shadow: 0 0 0 2px $gray2 !important;;
                  
                  .css-1n7v3ny-option, .css-yt9ioa-option {
                        // background-color: $primary;
                        color: $black1 !important;
                  }
                  .css-9gakcf-option {
                        background-color: #B2D4FF !important;
                        color: $black1 !important;
                  }
            }
      }

      // react-muliselect-dropdown
      .multiSelectOption {
            button {
                  font-size:  13px !important;
                  min-width: 300px !important;
                  color: $white1 !important;
                  padding: 2px 8px !important;
                  // background-color: $muted;
                  background-color: #383E4D;
                  border-color: #858B9A;
                  border-radius: 4px;
                  border-style: solid;
                  border-width: 1.5px;
                  min-height: 38px !important;
                  .css-1gpjby2 {
                        svg{
                              color: #CCCCCC !important;
                              width: 32px;
                              height: 32px;
                              // margin-right: -20 !important;
                        }
                  }
            }
            div>div {
                  // background-color: transparent !important;
            }
            .css-1r4vtzz {
                  box-shadow: none !important;
            }
            .css-1pcexqc-container {
                  background-color: $white2 !important;
                  padding-top: 4px;
                  color: $white1 !important;
                  min-width: 200px !important;
                  // box-shadow: 0 0 0 1px $muted !important;
                  .css-1jllj6i-control, .css-6y1x9t-control, .css-1jllj6i-control:hover, .css-6y1x9t-control:hover {
                        border-color: $gray2 !important;
                        box-shadow: 0 0 0 1px $gray2 !important;
                        color: $white1 !important;
                        min-width: 200px !important
                  }
                  .css-ik6y5r {
                        min-width: 200px !important;
                  }
                        // .css-151xaom-placeholder { 
                        //       color: $white1 !important;
                        // }
            }
            .css-1g6gooi, .css-1qprcsu-option, .css-1vr111p-option { 
                  color: $black1 !important;
                  min-width: 200px !important;
            }
            .css-1qprcsu-option{
                  font-weight: normal;
            }
      }

      .dangerMultiSelectLine {
            button {
                  border-bottom: 1px solid $danger !important;
            }
      }
      ::placeholder {
            color: $gray4 !important;
            font-style: italic !important;
      }
}
.breakWhiteSpace {
      white-space: break-spaces;
}

.longData {
      .popover {
            background: rgba(0, 0, 0, 0.8) !important;
            font-size: 14px !important;
            color: #fff !important;
            overflow-y: hidden !important;
            min-height: 50px !important;
            min-width: 1000px !important;
            max-width: 1000px !important;
      }
      .popover-header {
            color: #fff;
            background: rgba(0, 0, 0, 0.8) !important;
            font-size: 16px !important;
      }
      .popover-body {
            // color: #fff !important;
      }
      .popover-inner { 
            min-height: 50px !important;
            min-width: fit-content;
      }
}