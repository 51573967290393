/* Registration Screen*/
.signup-success-tick-icon {
	font-size: 90px;
}

/* For rectifying design issues*/
.navigation,
.nav-collapsed:hover {
	height: 100vh !important;
}

.form-group.styled-feild .form-control {
	height: auto !important;
}

option {
	color: black;
}
.project-font {
	font-size: 14px;
	font-weight: 400;
	font-family: 'Muli', sans-serif;
}
.Toastify__toast--success {
	background: #09d261 !important;
}
.Toastify__toast--error {
	background: #f44336 !important;
}
/**/
.w-100 {
	width: 100% !important;
}

/* Table column resizer*/
.resizer {
	display: inline-block;
	margin-left: 20px;
	width: 5px;
	height: 100%;
	position: absolute;
	cursor: col-resize !important;
	right: 0;
	top: 0;
	transform: translateX(50%);
	z-index: 1;
	touch-action: none;
}

/*  */
.close-notification:hover {
	background-color: #e2e1e141;
	cursor: pointer;
}

.notification-corner {
	position: fixed;
	top: 0;
	right: 0;
	margin-top: 1%;
	margin-right: 1%;
}

#slide {
	right: -600px;
	-webkit-animation: slide 0.5s ease 0.5s forwards;
	-webkit-animation-delay: 0.5s;
	animation: slide 0.1s ease 0.1s forwards;
	animation-delay: 0.1s;
}

.bg-lightgray {
	background-color: lightgray;
}

.bg-yellow {
	background-color: #ffeb3b;
}

.bg-low {
	background-color: #007bff;
}
.text-blue {
	color: #007bff;
}
.bg-risk {
	background-color: #f44336;
}

.disabled {
	pointer-events: none !important;
}

.close {
	color: rgb(255, 255, 255);
}

.close:hover {
	color: rgb(255, 255, 255);
}

.fs-20 {
	font-size: 20px;
}

.fs-12 {
	font-size: 12px;
}
.fs-8 {
	font-size: 8px;
}

.fs-13 {
	font-size: 13px;
}

.fs-15 {
	font-size: 15px;
}

.cursor-pointer {
	cursor: pointer;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.pagination select {
	margin-left: 5px;
	border: none;
}

.pagination button {
	background-color: transparent;
	border: none;
	margin: 0px 10px;
	font-size: 18px;
	padding: 0px;
}

select:focus,
input:focus,
button:focus,
textarea:focus {
	outline: none !important;
	box-shadow: none !important;
	text-decoration: none !important;
}

.btn-sm.btn-xs {
	padding: 0.1rem 0.5rem;
}

.custom-link.nav-link {
	display: block;
	padding: 0.5rem 14px;
	cursor: pointer;
}

thead .fa-sort {
	font-size: 12px;
	color: #848383;
}

thead .fa-sort-up {
	font-size: 12px;
	color: #848383;
}

thead .fa-sort-down {
	font-size: 12px;
	color: #848383;
}

.status-badge {
	font-size: 90% !important;
}

.detail .detail-content {
	background-color: #f5f5f5;
}

.related small {
	font-weight: 700 !important;
}

.title-section .progress {
	width: auto !important;
	min-width: 100%;
}

.assetPercent {
	background-color: #aadfb8;
	font-weight: bold;
	font-size: 20px !important;
	line-height: 17px !important;
}

.billing-side-table tr:last-child th,
.billing-side-table tr:last-child td {
	border-bottom: none !important;
}

.billing-side-table tr:last-child th,
.billing-side-table tr:last-child td {
	border-top: none !important;
}

.assets-side-table tr:first-child th,
.assets-side-table tr:first-child td {
	border-bottom: none !important;
}

.assets-side-table tr:first-child th,
.assets-side-table tr:first-child td {
	border-top: none !important;
}

.assets-side-table td {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.user-card {
	background-color: #daeaf1 !important;
}

.tags-card {
	background-color: #ffe8d6 !important;
}

.billing-side-table td,
.billing-side-table th {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
	border-bottom: solid lightgray 0.5px !important;
}

.billing-side-table td {
	text-align: center !important;
}

.health-score {
	min-width: 57px;
	height: 57px;
	overflow: hidden;
	font-size: 12px;
	line-height: 57px;
	text-align: center;
	border-radius: 4px;
	background-color: #f8f9fa;
	overflow: hidden;
}

.badge-light {
	background-color: #ededed;
}

.tag-shape {
	background-color: #2d323e;
	border-radius: 0px 4px 4px 0px;
	color: #fff;
	padding: 3px 8px;
	position: relative;
	margin-left: 23px;
}

.tag-shape::before {
	font-family: 'Font Awesome 5 Pro';
	content: '\f0d9';
	font-weight: 900;
	color: #2d323e;
	font-size: 39px;
	position: absolute;
	left: -13px;
	top: -16px;
}

.dashboard-score {
	background-color: #292e3a;
	color: #fff;
	padding: 10px;
	border-radius: 8px;
	position: relative;
}

.water-mark {
	position: absolute;
	font-size: 66px;
	z-index: 0;
	color: #3a4154;
	right: 0px;
	top: 0px;
}

/* .tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
}

.tag {
	background: #dcdcde;
	border-radius: 3px 0 0 3px;
	color: #2d323e;
	display: inline-block;
	height: 20px;
	line-height: 20px;
	padding: 0 13px 0 14px;
	position: relative;
	margin: 0 4px 4px 0;
	text-decoration: none;
	-webkit-transition: color 0.2s;
	font-size: 10px;
}

.tag::before {
	background: #fff;
	border-radius: 10px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
	content: '';
	height: 6px;
	left: 4px;
	position: absolute;
	width: 6px;
	top: 6px;
}

.tag::after {
	background: #f5f5f5;
	border-bottom: 11px solid transparent;
	border-left: 10px solid #dcdcde;
	border-top: 10px solid transparent;
	content: '';
	position: absolute;
	right: 0;
	top: 0;
}

.tag:hover {
	background-color: crimson;
	color: white;
}

.tag:hover::after {
	border-left-color: crimson;
} */

.user-details {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	margin: 0.5rem;
	padding: 0.5rem;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
	text-align: right;
}

.user-details .fa {
	position: absolute;
	font-size: 75px;
	color: #f1f1f1;
	top: 26%;
	left: 0px;
}

.score-card {
	background-color: #007bff;
	padding: 5px 10px;
	margin-right: 5px;
	margin-bottom: 5px;
	color: #fff;
	border-radius: 8px;
}

/* .scorebg-1 {
	background-color: #64b5f6;
}

.scorebg-2 {
	background-color: #2196f3;
}

.scorebg-3 {
	background-color: #1976d2;
}

.scorebg-4 {
	background-color: #0d47a1;
} */

.scorebg-1, .scorebg-2, .scorebg-3, .scorebg-4 {
	background-color: #039BE5;
}


.score-card p {
	margin-bottom: 0px;
}
.tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}

.tags li {
	float: left;
}

.tag-lambda {
	background: #303030;
	border-radius: 3px 0 0 3px;
	color: #999;
	display: inline-block;
	height: 26px;
	line-height: 26px;
	padding: 0 20px 0 23px;
	position: relative;
	margin: 0 10px 10px 0;
	text-decoration: none;
	-webkit-transition: color 0.2s;
}

.tag-lambda::before {
	background: #fff;
	border-radius: 10px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
	content: '';
	height: 6px;
	left: 10px;
	position: absolute;
	width: 6px;
	top: 10px;
}

.tag-lambda::after {
	background: #fff;
	border-bottom: 13px solid transparent;
	border-left: 10px solid #303030;
	border-top: 13px solid transparent;
	content: '';
	position: absolute;
	right: 0;
	top: 0;
}

.tag-lambda:hover {
	background-color: crimson;
	text-decoration: none;
	color: white;
}

.tag-lambda:hover::after {
	border-left-color: crimson;
}
.popover {
	width: 200px;
	max-height: 60% !important;
	overflow-y: auto;
}
.score-visual-asset {
	width: 155px;
}
.left-1 {
	left: 1px !important;
}

.tooltip {
	pointer-events: none;
}
.score-visual-asset {
	width: 225px;
}
.react-timerange-picker__wrapper {
	border: none !important;
}
.react-timerange-picker {
	display: flex !important;
}
optgroup {
	color: black;
}
.fixed-badge {
	width: 21px;
	display: inline-block;
}

.user-details .fa.place-rb {
	right: 0px;
	bottom: 0px;
	top: auto;
}
@-webkit-keyframes slide {
	100% {
		right: 0;
	}
}

@keyframes slide {
	100% {
		right: 0;
	}
}
